import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { getСompletedModelsNullNames } from "../../functionsConfigurator/cabinets/getNodesCabinets";
import {
  ModelsName_NodesT,
  NAME_BASE_CABINET_CORNER_STUB,
  NODES_THREEKIT,
} from "../../utils/constants/nodesNamesThreekit";
import {
  getItemNodeFromNullModel,
  getWorldTransformEvalNode,
} from "../../utils/threekit/general/getFunctions";
import { getSizeModelRelativeTransform } from "../intervals/getIntervalsInfoOnWall";
import * as THREE from "three";
import { moveCoordsByVector } from "../wallsAndFloor/buildWallFromData";
import { isEqualCoordsTolerance } from "./addCornerModelBase";
import { checkIfCornerEmptyCabinet } from "./cabinetsWall/extremePoints";
import { getModelPositionFromName } from "../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";

/**
* Функція для перевірки моделі на кутову модель Lazy Suzan.
@param {ISceneResult} modelItem - Item моделі з Threekit.
@returns {Boolean} true/false - є модель кутовою Lazy Suzan чи ні.
*/
export const checkIfLazySuzanCornerCabinet = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner") && modelItem["name"].includes("Lazy Suzan");
};

/**
* Функція для перевірки моделі на кутову модель-заглушку для напольних шкафів.
* 
@param {ISceneResult} modelItem - Item моделі з Threekit.
@returns {Boolean} true/false - є модель кутовою чи ні.
*/
export const checkIfCornerEmptyCabinetBase = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"] === NAME_BASE_CABINET_CORNER_STUB;
  // return modelItem["name"].includes("Corner") && modelItem["name"].includes("empty") && modelItem["name"].includes("Cabinets Base");
};

/**
 * Функція для перевірки моделі на кутову модель Blind.
 *
 * @param {ISceneResult} modelItem - Item моделі з Threekit.
 * @returns {Boolean} true/false - є модель кутовою Blind чи ні
 */
export const checkIfBlindCornerCabinet = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner") && modelItem["name"].includes("Blind");
};

/**
 * Функція для визначення крайніх точок кутової моделі Lazy Suzan Corner Base Cabinet.
 *
 * @param {NODES_THREEKIT} modelNullName Name для Null моделі.
 * @returns {ObjExtremePointT} Об'ект з інформацією про крайні точки для моделі Lazy Suzan Corner Base Cabinet.
 */
export const getExtremePointsForLazySuzanOrEmptyCornerCabinet = (modelNullName: ModelsName_NodesT): ExtremePointI | undefined => {

  const modelItem = getItemNodeFromNullModel({ name: modelNullName });
  const modelPos = getModelPositionFromName(modelNullName);
  const modelSize = getSizeModelRelativeTransform(modelNullName);
  const worldTransformModel = getWorldTransformEvalNode(modelNullName);

  if (checkIfLazySuzanCornerCabinet(modelItem) || checkIfCornerEmptyCabinetBase(modelItem)) {
    const modelDirLeft = new THREE.Vector3(-1, 0, 0).transformDirection(
      worldTransformModel
    );
    const modelDirRight = new THREE.Vector3(1, 0, 0).transformDirection(
      worldTransformModel
    );
    const modelDirFront = new THREE.Vector3(0, 0, 1).transformDirection(
      worldTransformModel
    );

    // let leftExtremePoint = moveCoordsByVector(
    //   modelPos,
    //   modelDirLeft,
    //   modelSize["x"] / 2
    // );
    // leftExtremePoint = moveCoordsByVector(
    //   leftExtremePoint,
    //   modelDirFront,
    //   modelSize["z"]
    // );
    // const rightExtremePoint = moveCoordsByVector(
    //   modelPos,
    //   modelDirRight,
    //   modelSize["x"] / 2
    // );

    let leftExtremePoint = moveCoordsByVector(
      modelPos,
      modelDirLeft,
      modelSize["x"] / 2
    );
    let rightExtremePoint = moveCoordsByVector(
      modelPos,
      modelDirRight,
      modelSize["x"] / 2
    );
    rightExtremePoint = moveCoordsByVector(
      rightExtremePoint,
      modelDirFront,
      modelSize["z"]
    );

    return {
      leftExtremePoint: leftExtremePoint,
      rightExtremePoint: rightExtremePoint,
    };

  }

  return undefined;

}

/**
 * Функція для визначення крайніх точок кутової моделі Blind Corner Base Cabinet.
 *
 * @param {NODES_THREEKIT} modelNullName Name для Null моделі.
 * @returns {ObjExtremePointT} Об'ект з інформацією про крайні точки для моделі Blind Corner Base Cabinet.
 */
export const getExtremePointsForBlindCornerCabinet = (modelNullName: ModelsName_NodesT): ExtremePointI | undefined => {

  const modelItem = getItemNodeFromNullModel({ name: modelNullName });
  const modelPos = getModelPositionFromName(modelNullName);
  const modelSize = getSizeModelRelativeTransform(modelNullName);
  const worldTransformModel = getWorldTransformEvalNode(modelNullName);

  if (checkIfBlindCornerCabinet(modelItem)) {
    const modelDirLeftBlindCorner = new THREE.Vector3(-1, 0, 0).transformDirection(
      worldTransformModel
    );
    const modelDirRightBlindCorner = new THREE.Vector3(1, 0, 0).transformDirection(
      worldTransformModel
    );
    const modelDirFrontBlindCorner = new THREE.Vector3(0, 0, 1).transformDirection(
      worldTransformModel
    );

    let leftExtremePointBlindCorner = moveCoordsByVector(
      modelPos,
      modelDirLeftBlindCorner,
      modelSize["x"] / 2
    );
    let rightExtremePointBlindCorner = moveCoordsByVector(
      modelPos,
      modelDirRightBlindCorner,
      modelSize["x"] / 2
    );

    if (modelItem["name"].includes("Left")) {
      rightExtremePointBlindCorner = moveCoordsByVector(
        rightExtremePointBlindCorner,
        modelDirFrontBlindCorner,
        modelSize["z"]
      );
    }

    if (modelItem["name"].includes("Right")) {
      leftExtremePointBlindCorner = moveCoordsByVector(
        leftExtremePointBlindCorner,
        modelDirFrontBlindCorner,
        modelSize["z"]
      );
    }

    return {
      leftExtremePoint: leftExtremePointBlindCorner,
      rightExtremePoint: rightExtremePointBlindCorner,
    };
    
  }

  return undefined;

}

/**
 * Функція для визначення крайніх точок стандартної моделі.
 *
 * @param {NODES_THREEKIT} modelNullName Name для Null моделі.
 * @returns {ObjExtremePointT} Об'ект з інформацією про крайні точки для стандартної моделі.
 */
export const getExtremePointsForCabinet = (modelNullName: ModelsName_NodesT): ExtremePointI => {

  const modelPos = getModelPositionFromName(modelNullName);
  const modelSize = getSizeModelRelativeTransform(modelNullName);
  const worldTransformModel = getWorldTransformEvalNode(modelNullName);

  const modelDirLeft = new THREE.Vector3(-1, 0, 0).transformDirection(
    worldTransformModel
  );
  const modelDirRight = new THREE.Vector3(1, 0, 0).transformDirection(
    worldTransformModel
  );

  const leftExtremePoint = moveCoordsByVector(
    modelPos,
    modelDirLeft,
    modelSize["x"] / 2
  );
  const rightExtremePoint = moveCoordsByVector(
    modelPos,
    modelDirRight,
    modelSize["x"] / 2
  );

  return {
    leftExtremePoint: leftExtremePoint,
    rightExtremePoint: rightExtremePoint,
  };

}

export interface ExtremePointI {
  leftExtremePoint: THREE.Vector3 | ICoordinates;
  rightExtremePoint: THREE.Vector3 | ICoordinates;
}
export type ObjExtremePointT = {
  [key in ModelsName_NodesT]: ExtremePointI;
};
/**
 * Функція для визначення крайніх точок моделей.
 * Шукає крайні точки зліва ззаду та справа ззаду для кожної моделі на сцені.
 *
 * @param {NODES_THREEKIT} modelsCabinetsRegExp regexp для Null моделей, для яких шукаємо крайні точки.
 * @returns {ObjExtremePointT} Об'ект з інформацією про крайні точки для кожної моделі на сцені.
 */
export const getExtremePointsForModels = (
  modelsCabinetsRegExp: NODES_THREEKIT
): ObjExtremePointT => {
  const allNullForСabinets =
    getСompletedModelsNullNames(modelsCabinetsRegExp);
  const allNullForAppliances =
    getСompletedModelsNullNames(NODES_THREEKIT.MODEL_APPLIANCES);
  const isModelsWall = modelsCabinetsRegExp === NODES_THREEKIT.MODEL_CABINET_WALL
  const allNullModels = !isModelsWall ? [ ...allNullForСabinets, ...allNullForAppliances ] : allNullForСabinets;

  return allNullModels.reduce(
    (objExtremePointsAcc: ObjExtremePointT, modelName) => {

      const objExtremePointsForLazySuzanCornerCabinet = getExtremePointsForLazySuzanOrEmptyCornerCabinet(modelName)
      if (!!objExtremePointsForLazySuzanCornerCabinet) {
        return {
          ...objExtremePointsAcc,
          [modelName]: objExtremePointsForLazySuzanCornerCabinet,
        };
      }

      const objExtremePointsForBlindCornerCabinet = getExtremePointsForBlindCornerCabinet(modelName)
      if (!!objExtremePointsForBlindCornerCabinet) {
        return {
          ...objExtremePointsAcc,
          [modelName]: objExtremePointsForBlindCornerCabinet,
        };
      }

      const objExtremePointsForCabinet = getExtremePointsForCabinet(modelName);
      return {
        ...objExtremePointsAcc,
        [modelName]: objExtremePointsForCabinet,
      };

    },
    {}
  );
};

export interface ExtremeModelI extends ExtremePointI {
  isLeftPointFree: boolean;
  isRightPointFree: boolean;
  leftNeighbors: ModelsName_NodesT | "";
  rightNeighbors: ModelsName_NodesT | "";
}
export type ObjExtremeModelsT = {
  [key in ModelsName_NodesT]: ExtremeModelI;
};

export const getExtremeModelsForModel = (
  modelNullNameCurrent: ModelsName_NodesT,
  extremePointsModel: ExtremePointI,
  objExtremePointsForModels: ObjExtremePointT
): ExtremeModelI => {
  const leftExtremePointCurrent = extremePointsModel["leftExtremePoint"];
  const rightExtremePointCurrent = extremePointsModel["rightExtremePoint"];

  let isLeftPointFree = true;
  let isRightPointFree = true;
  let leftNeighbors: ModelsName_NodesT | "" = "";
  let rightNeighbors: ModelsName_NodesT | "" = "";

  const extremePointsForModelsKeys = Object.keys(
    objExtremePointsForModels
  ) as Array<keyof typeof objExtremePointsForModels>;

  extremePointsForModelsKeys.forEach((modelNullName) => {
    if (modelNullName !== modelNullNameCurrent) {
      const { leftExtremePoint, rightExtremePoint } =
        objExtremePointsForModels[modelNullName];
      if (
        isEqualCoordsTolerance(
          leftExtremePointCurrent,
          rightExtremePoint,
          0.1
        )
      ) {
        isLeftPointFree = false;
        leftNeighbors = modelNullName;
      }
      if (
        isEqualCoordsTolerance(
          rightExtremePointCurrent,
          leftExtremePoint,
          0.1
        )
      ) {
        isRightPointFree = false;
        leftNeighbors = modelNullName;
      }
    }
  });

  return {
    ...extremePointsModel,
    isLeftPointFree,
    isRightPointFree,
    leftNeighbors,
    rightNeighbors
  }

}

/**
 * Функція для визначення крайніх моделей.
 * Шукає моделі, які не з'єднані з іншими моделями (не з'єднані або справа або зліва або зправа і зліва).
 *
 * @param {NODES_THREEKIT} modelsCabinetsRegExp regexp для Null моделей, серед яких потрібно знайти крайні моделі.
 * @returns {ObjExtremeModelsT} Об'ект з інформацією про крайні точки для кожної моделі на сцені.
 */
export const getExtremeModels = (
  modelsCabinetsRegExp: NODES_THREEKIT
): ObjExtremeModelsT => {
  const objExtremePointsForModels =
    getExtremePointsForModels(modelsCabinetsRegExp);

  const extremePointsForModelsKeys = Object.keys(
    objExtremePointsForModels
  ) as Array<keyof typeof objExtremePointsForModels>;

  const objExtremeModelsInfo = extremePointsForModelsKeys.reduce(
    (objExtremeModelsInfoAcc: ObjExtremeModelsT, modelNullNameCurrent) => {

      const objExtremeModelsForModel = getExtremeModelsForModel(
        modelNullNameCurrent,
        objExtremePointsForModels[modelNullNameCurrent],
        objExtremePointsForModels
      );
      // const leftExtremePointCurrent =
      //   objExtremePointsForModels[modelNullNameCurrent]["leftExtremePoint"];
      // const rightExtremePointCurrent =
      //   objExtremePointsForModels[modelNullNameCurrent]["rightExtremePoint"];

      // let isLeftPointFree = true;
      // let isRightPointFree = true;
      // let leftNeighbors = "";
      // let rightNeighbors = "";

      // extremePointsForModelsKeys.forEach((modelNullName) => {
      //   if (modelNullName !== modelNullNameCurrent) {
      //     const { leftExtremePoint, rightExtremePoint } =
      //       objExtremePointsForModels[modelNullName];
      //     if (
      //       isEqualCoordsTolerance(
      //         leftExtremePointCurrent,
      //         rightExtremePoint,
      //         0.1
      //       )
      //     ) {
      //       isLeftPointFree = false;
      //       leftNeighbors = modelNullName;
      //     }
      //     if (
      //       isEqualCoordsTolerance(
      //         rightExtremePointCurrent,
      //         leftExtremePoint,
      //         0.1
      //       )
      //     ) {
      //       isRightPointFree = false;
      //       leftNeighbors = modelNullName;
      //     }
      //   }
      // });

      if (objExtremeModelsForModel["isLeftPointFree"] || objExtremeModelsForModel["isRightPointFree"]) {
        return {
          ...objExtremeModelsInfoAcc,
          [modelNullNameCurrent]: objExtremeModelsForModel,
        };
      }

      return { ...objExtremeModelsInfoAcc };
    },
    {}
  );

  return objExtremeModelsInfo;
};

/**
 * Функція для визначення крайніх точок всіх моделей на підлозі.
 * Шукає крайні точки зліва ззаду та справа ззаду для кожної напольної моделі на сцені.
 *
 * @returns {ObjExtremePointT} Об'ект з інформацією про крайні точки для кожної напольної моделі на сцені.
 */
export const getExtremePointsForModelsAppliances = (): ObjExtremePointT => {
  const allNullForAppliances = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_APPLIANCES);
  const allNullForСabinetsBase = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_BASE);
  const allNullForСabinetsIsland = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_ISLAND);
  const allNullModels = [
    ...allNullForAppliances,
    ...allNullForСabinetsBase,
    ...allNullForСabinetsIsland,
  ];

  return allNullModels.reduce(
    (objExtremePointsAcc: ObjExtremePointT, modelName) => {

      const objExtremePointsForLazySuzanCornerCabinet = getExtremePointsForLazySuzanOrEmptyCornerCabinet(modelName)
      if (!!objExtremePointsForLazySuzanCornerCabinet) {
        return {
          ...objExtremePointsAcc,
          [modelName]: objExtremePointsForLazySuzanCornerCabinet,
        };
      }

      const objExtremePointsForBlindCornerCabinet = getExtremePointsForBlindCornerCabinet(modelName)
      if (!!objExtremePointsForBlindCornerCabinet) {
        return {
          ...objExtremePointsAcc,
          [modelName]: objExtremePointsForBlindCornerCabinet,
        };
      }

      const objExtremePointsForCabinet = getExtremePointsForCabinet(modelName);
      return {
        ...objExtremePointsAcc,
        [modelName]: objExtremePointsForCabinet,
      };

    },
    {}
  );
};

/**
 * Функція для визначення крайніх моделей Appliances.
 * Шукає моделі Appliances, які не з'єднані з іншими моделями (не з'єднані або справа або зліва або зправа і зліва).
 *
 * @returns {ObjExtremeModelsT} Об'ект з інформацією по моделях Appliances, які є крайніми.
 */
export const getExtremeModelsAppliances = (): ObjExtremeModelsT => {
  const objExtremePointsForModelsAppliances = getExtremePointsForModelsAppliances();

  const extremePointsForModelsAppliancesKeys = Object.keys(
    objExtremePointsForModelsAppliances
  ) as Array<keyof typeof objExtremePointsForModelsAppliances>;

  const objExtremeModelsInfo = extremePointsForModelsAppliancesKeys.reduce(
    (objExtremeModelsInfoAcc: ObjExtremeModelsT, modelNullNameCurrent) => {
      const leftExtremePointCurrent =
        objExtremePointsForModelsAppliances[modelNullNameCurrent]["leftExtremePoint"];
      const rightExtremePointCurrent =
        objExtremePointsForModelsAppliances[modelNullNameCurrent]["rightExtremePoint"];

      let isLeftPointFree = true;
      let isRightPointFree = true;
      let leftNeighbors = "";
      let rightNeighbors = "";

      extremePointsForModelsAppliancesKeys.forEach((modelNullName) => {
        if (modelNullName !== modelNullNameCurrent) {
          const { leftExtremePoint, rightExtremePoint } =
            objExtremePointsForModelsAppliances[modelNullName];
          if (
            isEqualCoordsTolerance(
              leftExtremePointCurrent,
              rightExtremePoint,
              0.1
            )
          ) {
            isLeftPointFree = false;
            leftNeighbors = modelNullName;
          }
          if (
            isEqualCoordsTolerance(
              rightExtremePointCurrent,
              leftExtremePoint,
              0.1
            )
          ) {
            isRightPointFree = false;
            leftNeighbors = modelNullName;
          }
        }
      });

      if (isLeftPointFree || isRightPointFree) {
        return {
          ...objExtremeModelsInfoAcc,
          [modelNullNameCurrent]: {
            ...objExtremePointsForModelsAppliances[modelNullNameCurrent],
            isLeftPointFree,
            isRightPointFree,
            leftNeighbors,
            rightNeighbors
          },
        };
      }

      return { ...objExtremeModelsInfoAcc };
    },
    {}
  );

  let objExtremeModelsAppliancesInfo: ObjExtremeModelsT = {}

  const objExtremeModelsInfoKeys = Object.keys(
    objExtremeModelsInfo
  ) as Array<keyof typeof objExtremeModelsInfo>;

  // відфільровуємо моделі Appliances з обьекта всіх крайніх моделей
  objExtremeModelsInfoKeys.forEach((nullNameModel) => {
    if (nullNameModel.includes(NODES_THREEKIT.MODEL_APPLIANCES)) {
      objExtremeModelsAppliancesInfo = {
        ...objExtremeModelsAppliancesInfo,
        [nullNameModel]: objExtremeModelsInfo[nullNameModel]
      }
    }
  })

  return objExtremeModelsAppliancesInfo;
};