import { ICoordinates, IQuaternion } from "@threekit-tools/treble/dist/types";
import { generateSnapshot } from "../../../../api/apiThreekit/savedConfiguration";
import { getObjAttributeThreekitValues } from "../../../../functionsConfigurator/cabinets/getObjActiveAndNewValuesAttributeThreekit";
import {
  getAllWallsNode,
  moveCoordsByVector,
} from "../../../../functionsConfigurator/wallsAndFloor/buildWallFromData";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../constants/attributesThreekit";
import { getTHREE } from "../../../three/general/getFunctionsTHREE";
import {
  getBoundingBoxEvalNode,
  getRotationThreekit,
  getTranslationThreekit,
} from "../../general/getFunctions";
import { getNodeIdFromName } from "../../general/getFunctions";
import {
  setActiveCamera,
  setRotationThreekit,
  setTranslationThreekit,
  setVisibleWithoutInstance,
} from "../../general/setFunctions";
import { getModelPositionFromName } from "../toolsDragCabinetsIsland/generalFunc";
import {
  getModelsBaseNullOnWall,
  getModelsWallNullOnWall,
} from "../../../../functionsConfigurator/cabinets/getNodesCabinets";

export const getPositionCamera = (): ICoordinates =>
  window["threekit"]["player"]["camera"].getPosition();
export const setPositionCamera = (camTrans: ICoordinates) =>
  window["threekit"]["player"]["camera"].setPosition(camTrans);
export const getPositionQuatCamera = (): IQuaternion =>
  window["threekit"]["player"]["camera"].getQuaternion();
export const setPositionQuatCamera = (camQuat: IQuaternion) =>
  window["threekit"]["player"]["camera"].setQuaternion(camQuat);

/**
 * Повертає координати моделі моделі від якої буде рухатися камера.
 */
export const getCornerPosition = () => {
  const { values, value } = getObjAttributeThreekitValues(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE
  );
  let translation = { x: 0, y: 0, z: 0 };

  const cornerEmpty = value.find((cabinet) =>
    values.find(
      (c) => c.assetId === cabinet.assetId && c.name === "Corner empty"
    )
  );

  if (cornerEmpty) {
    const indexCornerEmpty = value.findIndex(
      (v) => v.assetId === cornerEmpty.assetId
    );

    translation = getModelPositionFromName(
      `Model_Cabinets_Base_${indexCornerEmpty}`
    );

    console.log({ modelPositon: translation });

    return translation;
  }

  if (value.length > 0) {
    console.log({ modelPositon: translation, vi: value.length - 1 });

    let index = value.length - 1;

    translation = getModelPositionFromName(`Model_Cabinets_Base_${index}`);
  }

  return translation;
};

/**
 * Повертає id камер на сцені.
 * @return об'єкт з списком id для початкової, верхньої та бокової камери
 */
const getCameras = () => {
  const topNameCamera = "Snapshot Top Camera";
  const snapshotSideCamera = "Snapshot Side Camera";
  const defaultCamera = "Camera";

  const topNameCameraId = getNodeIdFromName(topNameCamera);
  const snapshotSideCameraId = getNodeIdFromName(snapshotSideCamera);
  const dafaultCameraId = getNodeIdFromName(defaultCamera);

  return {
    top: topNameCameraId,
    side: snapshotSideCameraId,
    defaultCamera: dafaultCameraId,
  };
};

/**
 * Повертає розмір підлоги.
 */
const getSizeFloor = () => {
  const THREE = getTHREE();

  const floorModel = getBoundingBoxEvalNode(`floor Model`);
  const size = floorModel.getSize(new THREE.Vector3());

  return size;
};

/**
 * Визначення вектора для нової позиції камери
 * @param modelPositon - позиція моделі
 * @return вектор
 */
const getVector = (modelPositon: { x: number; y: number; z: number }) => {
  const THREE = getTHREE();

  const angle = (45 * Math.PI) / 180; // Переведення градусів в радіани
  let x = -Math.cos(angle);
  const y = 0;
  let z = -Math.sin(angle);

  if (modelPositon.x < 0 && modelPositon.z < 0) {
    x = Math.cos(angle);
    z = Math.sin(angle);
  }

  if (modelPositon.x > 0 && modelPositon.z < 0) {
    z = Math.sin(angle);
  }

  if (modelPositon.x < 0 && modelPositon.z > 0) {
    x = Math.cos(angle);
  }

  if (modelPositon.x === 0 && modelPositon.y === 0 && modelPositon.z === 0) {
    x = -Math.cos(angle);
    z = Math.sin(angle);
  }

  const vector = new THREE.Vector3(x, y, z);

  return vector;
};
/**
 *
 * @param modelPositon - поточна позиція камери
 * @return нова позиція камери
 */
export const rotateCamera = (modelPositon: {
  x: number;
  y: number;
  z: number;
}) => {
  if (modelPositon.x > 0 && modelPositon.z < 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: -45, z: 0 }
    );
  }

  if (modelPositon.x < 0 && modelPositon.z < 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: 45, z: 0 }
    );
  }
  if (modelPositon.x < 0 && modelPositon.z > 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: -235, z: 0 }
    );
  }
  if (modelPositon.x > 0 && modelPositon.z > 0) {
    window.threekit.player.scene.set(
      {
        name: "Snapshot Top Camera",
        //@ts-ignore
        plug: "Transform",
        //@ts-ignore
        property: "rotation",
      },
      { x: -50, y: 235, z: 0 }
    );
  }
};

/**
 * Переміщення верхньої камери на нову позицію
 */
const translationTopCamera = async ({
  id,
  floorModelBoundingBox,
  cornerPosition,
  boundingBoxWall,
  leftExtremePointС,
}: any) => {

  await setActiveCamera(id);

  let cameraTranslation = {
    ...floorModelBoundingBox.max,
    y: boundingBoxWall.max.y,
  };

  if (cornerPosition) {
    cameraTranslation = {
      ...leftExtremePointС,
      y: boundingBoxWall.max.y,
    };
  }

  setTranslationThreekit({
    name: "Snapshot Top Camera",
    value: cameraTranslation,
  });

  window.threekit.player.camera.frameBoundingSphere();

  const { base64_small } = await generateSnapshot();

  return base64_small;
};

/**
 * Повертає ім'я стіни де найбільше моделів
 */
export const getWallForCamera = () => {
  const walls = getAllWallsNode();
  const arrayWalls: any[] = Object.values(walls);

  let modelCount = 0;
  let wallName = "wall_item_0";

  arrayWalls.forEach((wall) => {
    const modelsBase = getModelsBaseNullOnWall(wall.name);
    const modelsWall = getModelsWallNullOnWall(wall.name);

    const newModelsCount = modelsBase.length + modelsWall.length;

    if (newModelsCount > modelCount) {
      modelCount = newModelsCount;
      wallName = wall.name;
    }
  });

  return wallName;
};

/**
 * Переміщення бокової камери на нову позицію
 */
export const translationSideCamera = async ({ id }: any) => {

  await setActiveCamera(id);

  const wallName = getWallForCamera();
  const wallNode = window.threekit.player.scene.get({ name: wallName });

  let translationWall = getTranslationThreekit({ name: wallName });

  setTranslationThreekit({
    id,
    value: {
      ...translationWall,
      y: translationWall.y,
      z: translationWall.z,
    },
  });

  window.threekit.player.scene.set(
    //@ts-ignore
    { name: "Snapshot Side Camera", plug: "Camera", property: "targetNode" },
    wallNode.id
  );

  window.threekit.player.camera.frameBoundingSphere();

  const { base64_small } = await generateSnapshot();

  return base64_small;
};

/**
 * Вираховування нової позиції для верхньої камери
 * @return координати з новою позицією
 */
const getNewPositionForTopCamera = ({
  cornerPosition,
  vector,
  modelSize,
}: any) => {
  let leftExtremePointС = moveCoordsByVector(
    cornerPosition,
    vector,
    modelSize["x"] / 2
  );
  leftExtremePointС = moveCoordsByVector(
    leftExtremePointС,
    vector,
    modelSize["z"]
  );

  return leftExtremePointС;
};

export interface SnapshotsI {
  snapshotSide: string;
  snapshotTop: string;
}
export type SnapshotsResT = SnapshotsI | null;
/**
 * Початкова функція для переміщення усіх камер
 * @return об'єкт зі base64 снепшотами з камер
 */
export const getSnapshotsCabinetsConfiguration = async (): Promise<SnapshotsResT> => {
  const { top, defaultCamera, side } = getCameras();
  const defaultPosition = getPositionCamera();
  const defaultQuartation = getPositionQuatCamera();

  try {
    let snapshotSide: string = "";
    let snapshotTop: string = "";

    if (top) {
      const floorModelBoundingBox = getBoundingBoxEvalNode("floor Model");
      const boundingBoxWall = getBoundingBoxEvalNode("wall_item_0");
      const cornerPosition = getCornerPosition();
      const modelSize = getSizeFloor();

      const vector = getVector(cornerPosition);

      const topCameraPsostion = getNewPositionForTopCamera({
        cornerPosition,
        vector,
        modelSize,
      });

      snapshotTop = await translationTopCamera({
        id: top,
        floorModelBoundingBox,
        cornerPosition,
        boundingBoxWall,
        topCameraPsostion,
      });
    }

    if (side) {
      snapshotSide = await translationSideCamera({ id: side });
    }

    await setActiveCamera(defaultCamera);
    setPositionCamera(defaultPosition);
    setPositionQuatCamera(defaultQuartation);

    return {
      snapshotSide,
      snapshotTop,
    };

  } catch (error) {

    await setActiveCamera(defaultCamera);
    setPositionCamera(defaultPosition);
    setPositionQuatCamera(defaultQuartation);

    return null;
  }
};
