import React from "react";
import { CheckSvg } from "../../../../assets/images/svg/Check";

// scss
import s from "./RadioOption.module.scss";

export const RadioOption = ({ ...props }) => {
  const { value, label, img, onClick, isActive } = props;
  return (
    <div className={`${s.radioOption} ${isActive && s.active}`} onClick={() => onClick(value)}>
      <div className={s.checkedIndicator}><CheckSvg /></div>
      <div className={s.radioOptionImg}>
        <div className={s.imgWrap}>
          <img src={img} alt={label} />
        </div>
      </div>
      <p className={s.radioOptionLabel}>{label}</p>
    </div>
  );
};