import React, { useState, useEffect, useRef } from "react";
import { useSpaces, useThreekitInitStatus } from '@threekit-tools/treble';

// scss
import s from "./TestPage.module.scss"
import useWindowSize, { IWindowSize } from "../../hooks/useWindowSize";
import { css } from "@emotion/react";
import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";

export const TestPage = () => {
  const hasLoaded = useThreekitInitStatus();
  const dataRoomBuilder = useSpaces({
    config: {
      unit: IUnits.METER,
      zoomDefault: 20,
      translateDefault: [4, 4],
      zoomSensitivity: 0.8,
    },
    attributes: {
      walls: 'Walls',
      windows: 'Windows',
      doors: 'Doors',
      openings: 'Openings',
    },
    //@ts-ignore
    validators: [],
  });
  console.log('dataRoomBuilder --- ==== ',dataRoomBuilder);
  const {
    //  Blueprint canvas
    canvasRef,
    //  Enhanced Attributes
    walls,
    windows,
    doors,
    openings,
    //  State Managers
    selectedElement,
    mode,
    dimensions,
    angleSnapping,
    reset,
    deleteElement,
  } = dataRoomBuilder;

  const windowSize = useWindowSize(); 

  const player2DWrap = useRef<HTMLDivElement>(null);
  const [player2DWrapSize, setPlayer2DWrapSize] = useState<any>({
      width: undefined,
      height: undefined,
  });
  useEffect(() => {

    setPlayer2DWrapSize({
        width: player2DWrap["current"]?.clientWidth,
        height: player2DWrap["current"]?.clientHeight,
    });


    
    // canvasRef["current"]["width"] = player2DWrapSize["width"]
    // canvasRef["current"]["height"] = player2DWrapSize["height"]

    // if (!!canvasRef["current"] && !canvasRef["current"]["width"] && !canvasRef["current"]["height"] && !!player2DWrap["current"]) {
    //   canvasRef["current"]["width"] = player2DWrap["current"]?.clientWidth
    //   canvasRef["current"]["height"] = player2DWrap["current"]?.clientHeight
    // }

  }, [windowSize["width"], windowSize["height"]]);

  return (
    <div className={s.pageTestGrid}>
      <aside className={s.mainTestSidebar}>
        <h3>Sidebar</h3>
      </aside>
      <div className={s.sceneTestContent} ref={player2DWrap}>
        <canvas ref={canvasRef} width={player2DWrapSize["width"]} height={player2DWrapSize["height"]} />
      </div>
    </div>
  );
};
