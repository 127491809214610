import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { ArrWallTemplateT } from "../../utils/constants/presetsRoom";
import { getAttributeThreekit } from "../../utils/threekit/general/getFunctions";

export const buildWalls2DFromTemplate = async (template: ArrWallTemplateT) => {
  const wallAttributeThreekit = getAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.WALLS);
  //@ts-ignore
  const assetIdAttributeNode = wallAttributeThreekit["values"][0]["assetId"];
  const newValueForWalls = template.map((template) => {
    return {
      assetId: assetIdAttributeNode,
      configuration: { ...template }
    }
  })
  //@ts-ignore
  await window.threekit.configurator.setConfiguration({ [ATTRIBUTES_NAMES_THREEKIT.WALLS]: newValueForWalls })
}