import { ModelAppliancesT, ModelCabinetIslandT } from "../../../utils/constants/nodesNamesThreekit";
import { getRotationThreekit } from "../../../utils/threekit/general/getFunctions";
import { setRotationThreekit } from "../../../utils/threekit/general/setFunctions";

export const rotateCabinetsIsland = (modelNullName: ModelCabinetIslandT | ModelAppliancesT) => {
  if (!!!modelNullName) return undefined;

  const currentRotation = getRotationThreekit({name: modelNullName});
  const newRotation = {
    ...currentRotation,
    y: currentRotation["y"] - 90
  }
  setRotationThreekit({
    name: modelNullName,
    value: newRotation
  })
}