import "./App.css";
import { ProductConfiguration } from "./configuration/App";

function AppConfig() {
  return (
    <ProductConfiguration
      initializationThreekit={{
        threekitUrl: "https://preview.threekit.com/",
        // publicToken: "c4f51a87-49c1-4d9b-be8c-bfa00630eac7",
        // publicToken: "20d8b0d7-9a95-4d5d-b602-a196ba5291fe",
        publicToken: "0de3209e-301d-4a10-abdb-7bdddb817f27",
        orgId: "803725a2-a289-48c5-9858-e9044af7233a",
        assetId: "5430b04a-c04b-48b2-999d-f88cc0ad3b37",
      }}
    />
  );
}

export default AppConfig;
