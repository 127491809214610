
export interface AreaRoomI {
  width: number;
  length: number;
}
export const calculateAreaFromTemplate = (arrWalls: any): AreaRoomI => {
  let maxX = 0;
  let maxZ = 0;
  for (let i = 0; i < arrWalls.length; i++) {
    const line = arrWalls[i];
    maxX = Math.max(maxX, line._tkSpace_startX, line._tkSpace_endX);
    maxZ = Math.max(maxZ, line._tkSpace_startZ, line._tkSpace_endZ);
  }
  return { width: maxX, length: maxZ };
}

export const calculateAreaFromThreekit = (arrWalls: any): AreaRoomI => {
  let maxX = 0;
  let maxZ = 0;
  for (let i = 0; i < arrWalls.length; i++) {
    const line = arrWalls[i]["configuration"];
    maxX = Math.max(maxX, line._tkSpace_startX, line._tkSpace_endX);
    maxZ = Math.max(maxZ, line._tkSpace_startZ, line._tkSpace_endZ);
  }
  return { width: maxX, length: maxZ };
}