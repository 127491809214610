import axios from "axios";
import fileDownload from "js-file-download";
//@ts-ignore
import { Liquid } from "liquidjs";
import { template } from "./liquidTemplate";
import { SnapshotsI, SnapshotsResT, getSnapshotsCabinetsConfiguration } from "../../utils/threekit/tools/camera/snapshotCameras";

const THREEKIT_API_ENDPOINT = process.env.REACT_APP_THREEKIT_API;
const PUBLIC_TOKEN = process.env.REACT_APP_THREEKIT_PREVIEW_PUBLIC_TOKEN;
const ORG_ID = process.env.REACT_APP_THREEKIT_PREVIEW_ORG_ID;

const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // reader.onerror = reject;
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Error reading file'));
    };
    reader.onload = () => {
      //@ts-ignore
      resolve(reader.result.split(',')[1]);
    };
    reader.readAsDataURL(blob);
  });
}

const getThreekitPDF = (html: any, dataWithSnapshot: any) => {
  const blob = new Blob([html], { type: "text/html" });
  const file = new File([blob], "template.liquid", {
    type: "text/liquid",
  });

  const bodyFormData = new FormData();
  bodyFormData.append("template", file);
  bodyFormData.append("data", JSON.stringify(dataWithSnapshot));

  return axios({
    method: "post",
    url: `${THREEKIT_API_ENDPOINT}/pdf?bearer_token=${PUBLIC_TOKEN}&orgId=${ORG_ID}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/pdf",
    },
    responseType: "blob",
    data: bodyFormData,
  });
}

export const generatePDF = async (data: any, getPdf?: boolean) => {

  const engine = new Liquid();

  try {

    let snapshots: SnapshotsResT = data["snapshots"];
    if (snapshots === null) {
      snapshots = await getSnapshotsCabinetsConfiguration();
    }

    const dataWithSnapshot = { ...data, snapshots };
    if (snapshots) {
      return engine
        .parseAndRender(template, dataWithSnapshot)
        .then((html) => {
          return getThreekitPDF(html, dataWithSnapshot);
        })
        .then((response) => {
          if (getPdf === undefined || (getPdf !== undefined && !getPdf))
            fileDownload(response.data, `${data["name"]}.pdf`);
          return response.data
        });
    }
  } catch (err) {
    console.log(err);
  }
};
