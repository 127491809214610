import AddingItemsToYourRoom from "./../../assets/images/Modals/Help/Adding items to your room.png";
import Pencil from "./../../assets/images/Modals/Help/icons/pencil.svg";
import ArrowCursor from "./../../assets/images/Modals/Help/icons/arrow_cursor.svg";
import Door from "./../../assets/images/Modals/Help/icons/door.svg";
import Window from "./../../assets/images/Modals/Help/icons/window.svg";
import Opening from "./../../assets/images/Modals/Help/icons/opening.svg";
import ZoomIn from "./../../assets/images/Modals/Help/icons/zoom-in.svg";
import ZoomOut from "./../../assets/images/Modals/Help/icons/zoom-out.svg";
import Angle from "./../../assets/images/Modals/Help/icons/angle.svg";
import Dimensions from "./../../assets/images/Modals/Help/icons/dimensions.svg";
import Remove from "./../../assets/images/Modals/Help/icons/remove.svg";

export interface AccordionHelp2DI {
  id: number;
  label: string;
  content: any;
}

export const helpData2D: AccordionHelp2DI[] = [
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Add/Delete walls",
    content: (
      <>
        <div>
          <p><strong>Add Wall</strong></p>
          <ol>
            <li>Select &nbsp; <img src={Pencil} alt="" /> <b>Draw Mode</b> in the navigation</li>
            <li>Click in the player to drop the start location</li>
            <li>Drag in the player and click again to drop the end location</li>
          </ol>
        </div>
        <div>
          <p><strong>Delete Wall</strong></p>
          <ol>
            <li>Select &nbsp; <img src={ArrowCursor} alt="" /> <b>Select Mode</b> in the navigation</li>
            <li>Select a Wall</li>
            <li>Use the properties panel to Delete the selected Wall</li>
          </ol>
        </div>
        {/* <img src={AddingItemsToYourRoom} alt="Adding items to your room" /> */}
        <p><b>* Make sure to create an enclosed area with walls to form a floor before moving to Room Design to be able to place item in the room.</b></p>
      </>
    ),
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Adjust Walls’ Properties",
    content: (
      <>
        <div>
          <p>Adjust <b>Wall Height</b> and <b>Thickness</b></p>
          <ol>
            <li>Select &nbsp; <img src={ArrowCursor} alt="" /> <b>Select Mode</b> in the navigation</li>
            <li>Select a wall in the layout</li>
            <li>Use the Properties panel to edit <b>Wall Height</b> and <b>Wall Thickness</b></li>
          </ol>
        </div>
        <div>
          <p>Adjust Wall <b>Length</b></p>
          <ol>
            <li>Select &nbsp; <img src={ArrowCursor} alt="" /> <b>Select Mode</b> in the navigation</li>
            <li>Select a vertices of a wall</li>
            <li>Drag to adjust the length of the wall</li>
          </ol>
        </div>
      </>
    ),
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Add/Delete Features (Doors, Windows and Openings)",
    content: (
      <>
        <div>
          <p><b>Add Features</b></p>
          <ol>
            <li>Select <img src={Door} alt="" /> Door, <img src={Window} alt="" /> Window or <img src={Opening} alt="" /> Opening in the navigation</li>
            <li>A Door, Window or Opening will be added to one of the wall</li>
            <li>Drag the feature around to place it at the desired location</li>
          </ol>
        </div>
        <div>
          <p><b>Delete Features</b></p>
          <ol>
            <li>Select &nbsp; <img src={ArrowCursor} alt="" /> <b>Select Mode</b> in the navigation</li>
            <li>Select a Feature</li>
            <li>Use the properties panel to Delete the selected Feature</li>
          </ol>
        </div>
      </>
    ),
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Adjust Features’ properties",
    content: (
      <>
        <ol>
          <li>Select &nbsp; <img src={ArrowCursor} alt="" /> <b>Select Mode</b> in the navigation</li>
          <li>Select a feature in the layout</li>
          <li>Use the Properties panel to edit <b>Feature’s properties</b></li>
        </ol>
        <div>
          <p><strong>Window</strong></p>
          <ul>
            <li>Height</li>
            <li>Width</li>
            <li>Altitude - Distance away from the ground</li>
          </ul>
        </div>
        <div>
          <p><strong>Door</strong></p>
          <ul>
            <li>Hinge Placement</li>
            <li>Opening direction</li>
            <li>Height</li>
          </ul>
        </div>
        <div>
          <p><strong>Opening</strong></p>
          <ul>
            <li>Shape</li>
            <li>Width</li>
            <li>Height</li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    label: "Toolbox",
    content: (
      <>
        <p><img src={ZoomIn} alt="" /> &nbsp; Zoom In</p>
        <p><img src={ZoomOut} alt="" /> &nbsp; Zoom Out</p>
        <p><img src={Angle} alt="" /> &nbsp; Toggle Snapping Angle On/Off - Assistance to make right angle and straight Line </p>
        <p><img src={Dimensions} alt="" /> &nbsp; Toggle Dimension On/Off</p>
        <p><img src={Remove} alt="" /> &nbsp; Remove all elements</p>
      </>
    ),
  },
];