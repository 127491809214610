import { ModalDecorativeEnd } from "../ModalDecorativeEnd/ModalDecorativeEnd"
import { ModalFramedType } from "../ModalFramedType/ModalFramedType"
import { ModalHelp2D } from "../ModalHelp2D/ModalHelp2D"
import { ModalPrice } from "../ModalPrice/ModalPrice"
import { useAppSelector } from "../../../hooks/useStoreHooks"
import { getModalInfo } from "../../../store/selectors/modalsSelectors"
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT"
import { ModalHelp3D } from "../ModalHelp3D/ModalHelp3D"
import { ModalContinue2D } from "../ModalContinue2D/ModalContinue2D"
import { ModalSendEmailSuccess } from "../ModalSendEmailSuccess/ModalSendEmailSuccess"
import { ModalSendEmailError } from "../ModalSendEmailError/ModalSendEmailError"
import { Loader } from "../Loader/Loader"
import { ModalRemoveAllCabinets } from "../ModalRemoveAllCabinets/ModalRemoveAllCabinets"

export const ModalsGlobal = () => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.PRICE));
  return (
    <>
      <ModalDecorativeEnd />
      <ModalFramedType />
      <ModalHelp2D />
      <ModalHelp3D />
      <ModalContinue2D />
      {modalInfo["isVisible"] && <ModalPrice />}
      <ModalSendEmailSuccess />
      <ModalSendEmailError />
      <ModalRemoveAllCabinets />
      <Loader />
    </>
  )
}