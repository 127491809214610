import { ISceneResult } from "@threekit-tools/treble/dist/types";
import { ModelsName_NodesT, NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getVector3FromCoordinates } from "../../utils/three/general/getFunctionsTHREE";
import { getBoxWidthThreekit, getItemNodeFromNullModel, getTranslationThreekit } from "../../utils/threekit/general/getFunctions";
import { ArrWallRangesT, createRangesEmptyAndFilled, getDistanceFromLineToPoint, isPointOnLine, RangeT, WallRangeT } from "./getIntervalsInfoOnWall";
import { getModelsBaseNullOnWall } from "../cabinets/getNodesCabinets";
import { getAllPlanesNode, getStartEndCoordsPlane } from "../wallsAndFloor/getWallPlanesInfo";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinets/cabinetsBase/size";
import { getNumberNodeThreekitFromName } from "../general";
import { getСompletedModelsNullNames } from "../../functionsConfigurator/cabinets/getNodesCabinets";
import { checkIfCornerCabinet } from "../cabinets/cabinetsWall/extremePoints";
import { filteringBaseFeatures, getArrNullNamesWallConnections } from "../features/general";
import { getWallRangesFeaturesFilled } from "../features/intervalsBaseFeatures";

/**
 * Створює массив всіх проміжків(заповнених та пустих) на стіні ДЛЯ ШКАФІВ НА ПІДЛОЗІ.
 *
 * @param {ArrWallRangesT} arrModelsNullNamesOnWall Массив імен для Models Null які розташовані біля стіни wallName.
 * @param {Number} planeName Name Threekit стіни.
 * @return {ArrWallRangesT} Массив всіх проміжків(заповнених та пустих) на стіні ДЛЯ ШКАФІВ НА ПІДЛОЗІ.
 */
export const getIntervalsInfoOnWallForCabinetsBase = (
  arrModelsNullNamesOnWall: ModelsName_NodesT[],
  planeName: PlaneCabinetsWallT,
): ArrWallRangesT => {

  // const [wallCoordsLeft, wallCoordsRight] = getStartEndPointWall(wallName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(planeName);
  // const wallSize = getSizeModelRelativeTransform(wallName);
  const currentPlaneWidth = getBoxWidthThreekit({ name: planeName });
  // const wallWidth = wallSize["x"];
  // const wallDepth = wallSize["z"];

  const fullRangeWall: WallRangeT = {
    empty: true,
    range: [0, currentPlaneWidth],
  }

  // if (arrModelsNullNamesOnWall.length === 0)
  //   return [fullRangeWall]


  let wallRangesFilled: ArrWallRangesT = [];

  // додаємо інтервали для дверей та проемів в загальний список інтервалів на стіні
  const wallNum = getNumberNodeThreekitFromName(planeName);
  const arrNullNamesWallConnections = getArrNullNamesWallConnections(wallNum);
  const arrNullNamesBaseFeatures = filteringBaseFeatures(arrNullNamesWallConnections);
  if (arrNullNamesBaseFeatures.length > 0) {
    const wallRangesFeaturesFilled = getWallRangesFeaturesFilled(
      arrNullNamesBaseFeatures,
      [currentPlaneCoordsLeft, currentPlaneCoordsRight]
    );
    wallRangesFilled = [ ...wallRangesFeaturesFilled ];
  }

  arrModelsNullNamesOnWall.forEach((modelNullName: ModelsName_NodesT) => {
    const modelNullTranslation = getTranslationThreekit({
      name: modelNullName,
    });
    const isModelNullOnPlane = isPointOnLine(
      { ...currentPlaneCoordsLeft, y: 0 },
      { ...currentPlaneCoordsRight, y: 0 },
      { ...modelNullTranslation, y: 0},
      0.005
    );

    if (isModelNullOnPlane) {
      const sizeModel = getSizeModelBoxFromAssetCabinetBase(modelNullName);
      const modelWidth = sizeModel["x"];
      const distanceFromPlaneStartToModelNull = getVector3FromCoordinates({ ...currentPlaneCoordsLeft, y: 0 })
        .distanceTo(getVector3FromCoordinates({ ...modelNullTranslation, y: 0}));
      const rangeModel: RangeT = [
        distanceFromPlaneStartToModelNull - modelWidth / 2,
        distanceFromPlaneStartToModelNull + modelWidth / 2,
      ];
      wallRangesFilled.push({
        empty: false,
        range: rangeModel,
        name: modelNullName,
      });
    }
    
  });

  // check Cabinets Base Corner for the next wall
  const completedModelsNullNamesCabinetsBase = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  const modelsBaseNullNotOnWall = completedModelsNullNamesCabinetsBase.filter((modelNullName) => !arrModelsNullNamesOnWall.includes(modelNullName));
  const cornerCabinetsBaseNotOnWall = modelsBaseNullNotOnWall.filter((modelNullName) => {
    const modelItem = getItemNodeFromNullModel({ name: modelNullName });
    return checkIfCornerCabinet(modelItem);
  })
  cornerCabinetsBaseNotOnWall.forEach((modelNullName) => {
    const sizeCornerCabinet = getSizeModelBoxFromAssetCabinetBase(modelNullName);
    const posCornerCabinet = getTranslationThreekit({
      name: modelNullName,
    });
    const distanceFromPlaneToCornerCabinet = getDistanceFromLineToPoint(
      { ...currentPlaneCoordsLeft, y: 0 },
      { ...currentPlaneCoordsRight, y: 0 },
      { ...posCornerCabinet, y: 0 },
    )
    if (Math.abs(distanceFromPlaneToCornerCabinet - sizeCornerCabinet["x"] / 2) <= 0.05) {
      wallRangesFilled.push({
        empty: false,
        range: [
          // currentPlaneWidth - sizeCornerCabinet["z"],
          // currentPlaneWidth
          0,
          sizeCornerCabinet["z"]
        ],
        name: modelNullName,
      });
    }
  })

  if (wallRangesFilled.length === 0)
    return [fullRangeWall];

  const wallRangesEmptyAndFilled = createRangesEmptyAndFilled(
    wallRangesFilled,
    currentPlaneWidth
  );

  return wallRangesEmptyAndFilled;

};

export type AllWallsIntervalsFloorT = {
  [key in WallItemT]: ArrWallRangesT;
}
/**
 * Створює об'єкт массивів проміжків(заповнених та пустих) ДЛЯ ШКАФІВ НА ПІДЛОЗІ для УСІХ стін.
 *
 * @return {AllWallsIntervalsFloorT} Об'єкт массивів проміжків(заповнених та пустих) ДЛЯ ШКАФІВ НА ПІДЛОЗІ для УСІХ стін.
 */
export const getIntervalsBaseCabinetsForAllWalls = (): AllWallsIntervalsFloorT => {
  // const nodesAllWalls: {[key in string]: ISceneResult} = getAllWallsNode();
  // const objWallsIntervals = Object.values(nodesAllWalls).reduce((accumulator: AllWallsIntervalsFloorT, node: ISceneResult) => {
  //   const wallName = node["name"] as WallItemT;
  //   const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallName);
  //   const intervalsInfoOnWallForCabinetsBase = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, wallName);
  //   return { ...accumulator, [wallName]: intervalsInfoOnWallForCabinetsBase }
  // }, {});
  // return objWallsIntervals;
  const nodesAllPlanes = getAllPlanesNode();
  const objWallsIntervals = Object.values(nodesAllPlanes).reduce((accumulator: AllWallsIntervalsFloorT, node: ISceneResult) => {
    const planeName = node["name"] as PlaneCabinetsWallT;
    const wallNum = getNumberNodeThreekitFromName(planeName);
    const currentWallName: WallItemT = `${NODES_THREEKIT.WALL_ITEM}${wallNum}`;
    const modelsBaseNullOnWall = getModelsBaseNullOnWall(currentWallName);
    const intervalsInfoOnWallForCabinetsBase = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, planeName);
    return { ...accumulator, [currentWallName]: intervalsInfoOnWallForCabinetsBase }
  }, {});
  return objWallsIntervals;
}