import React, { useContext, useEffect, useRef, useState } from "react";
import { MainMenu } from "../../components/MainMenu/MainMenu";
import { PlayerThreeKit } from "../../components/PlayerThreeKit/PlayerThreeKit";
import { CabinetControls } from "../../components/SceneControls/CabinetControls/CabinetControls";
import { SceneControlsBottom2D } from "../../components/SceneControls/SceneControlsBottom2D/SceneControlsBottom2D";
import { SceneControlsGroup2D } from "../../components/SceneControls/SceneControlsGroup2D/SceneControlsGroup2D";
import { SceneControlsTop } from "../../components/SceneControls/SceneControlsTop/SceneControlsTop";
import { SettingsBlock } from "../../components/SettingsBlock/SettingsBlock";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { setModalVisible } from "../../store/slices/modalsSlice/modalsSlice";
import InitializationProvider from "../../utils/initializationProvider/initializationProvider";

// scss
import s from "./RoomBuilder2D.module.scss";
import { MainMenuRoomBuilder } from "../../components/MainMenu/MainMenuRoomBuilder/MainMenuRoomBuilder";
import { SceneControlsTop2D } from "../../components/SceneControls/SceneControlsTop2D/SceneControlsTop2D";
import { buildWalls2DFromTemplate } from "../../functionsConfigurator/roomBuilder/buildWallsFromTemplate";
import { PlayerRoomBuilder2D } from "../../components/PlayerThreeKit/PlayerRoomBuilder2D/PlayerRoomBuilder2D";
import {
  getActiveSettingUI,
  getInitialWalls,
  getMeasurement,
} from "../../store/selectors/settingsUISelectors";
import { MODAL_KEYS } from "../../store/slices/modalsSlice/modalsSliceT";
import {
  AreaRoomI,
  calculateAreaFromTemplate,
  calculateAreaFromThreekit,
} from "../../functionsConfigurator/configurator2D/calculateArea";
import { useSpaces, useThreekitInitStatus } from "@threekit-tools/treble/dist";
import { IModes } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import { setHistory2D } from "../../store/slices/history/historySlice";
import { getPriceDataTableFromThreekit } from "../../api/apiThreekit";
import { setPriceTable } from "../../store/slices/dataTablesSlice/dataTablesSlice";
import { ModalsGlobal } from "../../components/Modals/ModalsGlobal/ModalsGlobal";
import { setAllSettingConfig } from "../../store/slices/settingsUI/settingsUISlice";
import { loadCollisionPhysics } from "../../utils/threekit/initThreekit";
import { handlePointerLeaveInCanvas } from "../../utils/other/handlePointerLeaveInCanvas";

export const RoomBuilder2D = () => {
  const { initializationThreekit } = useContext(InitializationProvider);
  const dispatch = useAppDispatch();
  const hasLoaded = useThreekitInitStatus();
  const measurementValue = useAppSelector(getMeasurement);
  const activeSettingUI = useAppSelector(getActiveSettingUI);
  // const dataRoomBuilder = useRoomBuilder({
  //   config: {
  //     unit: measurementValue,
  //     zoomDefault: 20,
  //     translateDefault: [4, 4],
  //   },
  //   attributes: {
  //     walls: "Walls",
  //     windows: "Windows",
  //     doors: "Doors",
  //     openings: "Openings",
  //   },
  //   //@ts-ignore
  //   validators: [],
  // });
  const dataRoomBuilder = useSpaces({
    config: {
      unit: measurementValue,
      zoomDefault: 20,
      translateDefault: [0, 0],
      zoomSensitivity: 0.02,
    },
    attributes: {
      walls: "Walls",
      windows: "Windows",
      doors: "Doors",
      openings: "Openings",
    },
  });

  const { canvasRef, walls, mode, zoom, reset, dimensions, translate, unit } =
    dataRoomBuilder;
  // console.log("dataRoomBuilder --- ==== ", dataRoomBuilder);
  const initialTemplateWalls = useAppSelector((state) =>
    getInitialWalls(state)
  );
  const sceneContentDiv = useRef<HTMLDivElement>(null);

  const setTranslateRoom2DToCenter = (arrWalls: any) => {
    let areaRoomSize: AreaRoomI = { width: 0, length: 0 };
    if (arrWalls[0].hasOwnProperty("assetId")) {
      areaRoomSize = calculateAreaFromThreekit(arrWalls);
    } else {
      areaRoomSize = calculateAreaFromTemplate(arrWalls);
    }
    if (sceneContentDiv["current"] !== null) {
      const canvasWidth = sceneContentDiv["current"]["clientWidth"];
      const canvasHeight = sceneContentDiv["current"]["clientHeight"];
      translate.handleChange([
        canvasWidth / 2 - (areaRoomSize["width"] * 100) / 2 / 2,
        canvasHeight / 2 - (areaRoomSize["length"] * 100) / 2 / 2,
      ]);
    }
  };

  useEffect(() => {
    if (activeSettingUI.length < 1) {
      // loadCollisionPhysics();
      dispatch(setAllSettingConfig());
    };
  }, [])

  useEffect(() => {
    dispatch(setModalVisible({ modalId: MODAL_KEYS.HELP_2D, value: true }));

    if (
      !!walls &&
      walls["value"].length > 0 &&
      sceneContentDiv["current"] !== null
    )
      setTranslateRoom2DToCenter(walls["value"]);
  }, []);

  useEffect(() => {
    if (
      hasLoaded &&
      initialTemplateWalls.length > 0 &&
      !!walls &&
      walls["value"].length === 0
    ) {
      buildWalls2DFromTemplate(initialTemplateWalls);
      if (sceneContentDiv["current"] !== null)
        setTranslateRoom2DToCenter(initialTemplateWalls);
      mode.handleChange(IModes.SELECT);
    }

    if (initialTemplateWalls.length === 0) mode.handleChange(IModes.DRAW);
  }, [hasLoaded]);

  useEffect(() => {
    if (hasLoaded) {
      if (hasLoaded && initialTemplateWalls.length === 0) {
        mode.handleChange(IModes.DRAW)
      } else if (hasLoaded && initialTemplateWalls.length > 0) {
        mode.handleChange(IModes.SELECT)
      }
    }
  }, [initialTemplateWalls])

  useEffect(() => {
    if (hasLoaded) {
      dispatch(setHistory2D({
        configuration: window.threekit.configurator.getFullConfiguration()
      }));
    }
  }, [dataRoomBuilder]);

  useEffect(() => {
    if (hasLoaded) {
      getPriceDataTableFromThreekit().then((res) => {
        dispatch(setPriceTable(res));
      })
    }
  }, [hasLoaded]);

  return (
    <div className={s.pageGrid}>
      <div className={s.mobHeader}>Mobile Header</div>
      <aside className={s.mainSidebar}>
        <MainMenuRoomBuilder {...dataRoomBuilder} />
      </aside>
      <div className={s.sceneContent} ref={sceneContentDiv}>
        {/* <Player></Player> */}
        <PlayerRoomBuilder2D {...dataRoomBuilder} />
        {!hasLoaded && <div className={s.sceneLoader}>Loading...</div>}
        <SceneControlsTop2D unit={unit} />
        <SceneControlsBottom2D
          zoom={zoom}
          deleteAllWalls={reset}
          handleToggleDimentions={dimensions.handleToggle}
        />
        {/* <SceneControlsGroup2D /> */}
      </div>

      <ModalsGlobal />
      
    </div>
  );
};
