import {
  IConfiguration,
  ISetConfiguration,
} from "@threekit-tools/treble/dist/types";
import { NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import {
  getExtremeModelsAppliances,
} from "../getExtremeModels";
import {
  ATTRIBUTES_DECORATIVE_PANELS,
  getConfiguratorModelFromNullName,
  getSideDecorativeEnd,
} from "./decorativePanel";

/**
 * Функція оновлює декоративні панелі для всіх моделей, відповідно до значення з UI та розташування моделі.
 * Для оновлення після переміщення однієї з моделей
 *
 */
export const updateDecorativeEndAppliances = (
  decorativeEndCabinetsBaseValue: boolean,
  decorativeEndCabinetsIslandValue: boolean
) => {

  const objExtremeModelsAppliances = getExtremeModelsAppliances();

  if (Object.keys(objExtremeModelsAppliances).length > 0) {

    const objExtremeModelsAppliancesKeys = Object.keys(
      objExtremeModelsAppliances
    ) as Array<keyof typeof objExtremeModelsAppliances>;

    objExtremeModelsAppliancesKeys.forEach((nullNameAppliances) => {

      const configuratorModel =
        getConfiguratorModelFromNullName(nullNameAppliances);
      const configurationModel = configuratorModel.getConfiguration();

      if (configurationModel.hasOwnProperty("Decorative")) {

        const sideDecorativeEnd = getSideDecorativeEnd(
          objExtremeModelsAppliances[nullNameAppliances]
        );

        let objDecorativeEndConfiguration: ISetConfiguration | IConfiguration =
          {
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]:
              sideDecorativeEnd,
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
          };

        if (
          objExtremeModelsAppliances[nullNameAppliances][
            "leftNeighbors"
          ].includes(NODES_THREEKIT.MODEL_CABINET_ISLAND) ||
          objExtremeModelsAppliances[nullNameAppliances][
            "rightNeighbors"
          ].includes(NODES_THREEKIT.MODEL_CABINET_ISLAND)
        ) {
          objDecorativeEndConfiguration = {
            ...objDecorativeEndConfiguration,
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: decorativeEndCabinetsIslandValue ? "Yes" : "No",
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: decorativeEndCabinetsIslandValue ? "Yes" : "No",
          };
        } else if (
          objExtremeModelsAppliances[nullNameAppliances][
            "leftNeighbors"
          ].includes(NODES_THREEKIT.MODEL_CABINET_BASE) ||
          objExtremeModelsAppliances[nullNameAppliances][
            "rightNeighbors"
          ].includes(NODES_THREEKIT.MODEL_CABINET_BASE)
        ) {
          objDecorativeEndConfiguration = {
            ...objDecorativeEndConfiguration,
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: decorativeEndCabinetsBaseValue ? "Yes" : "No",
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
          };
        }

        configuratorModel.setConfiguration(objDecorativeEndConfiguration);

      }
    });
  }

};
