// scss
import { useNestedConfigurator } from "@threekit-tools/treble/dist";
import { FlipHorisontal } from "../../../assets/images/svg/FlipHorisontal";
import { FlipVertical } from "../../../assets/images/svg/FlipVertical";
import { ATTRIBUTE_NAMES } from "../../../utils/constants/attributesThreekitRoomBuilder";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { InputNumberSize } from "../../StandartComponents/InputNumberSize/InputNumberSize";
import { RadioOptionsSmall } from "../../StandartComponents/RadioOptionsSmall/RadioOptionsSmall";
import { TwoLineParams } from "../../StandartComponents/TwoLineParams/TwoLineParams";
import { BtnSwitchDoorSide } from "./DoorSideButtons/BtnSwitchDoorSide/BtnSwitchDoorSide";
import { DoorSideButtons } from "./DoorSideButtons/DoorSideButtons";
import s from "./SceneControlsGroup2D.module.scss";

export const SceneControlsGroup2D = ({ ...props }) => {
  const {
    walls,
    windows,
    doors,
    openings,

    selectedElement,
    deleteElement,
  } = props;

  const [attributes, setConfiguration] = useNestedConfigurator(selectedElement.path);
  // console.log('attributes --- ==== ',attributes);

  const selectedElementPath = selectedElement["path"];
  const selectedElementType = selectedElement["type"];

  const wallHeightValue =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallHeight
    ];

  // wallThickness
  const wallThickness =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallThickness
    ];
  const wallLockThicknessLocal =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallThicknessLocal
    ];
  const wallLockThickness =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallLockThickness
    ];
  const wallThicknessValue = wallLockThickness
    ? wallThickness
    : wallLockThicknessLocal;
  const wallStartX =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallStartX
    ];
  const wallStartZ =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallStartZ
    ];
  const wallEndX =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallEndX
    ];
  const wallEndZ =
    walls["value"][selectedElementPath[1]]["configuration"][
      ATTRIBUTE_NAMES.wallEndZ
    ];

  const getWallLength = (x1: number, y1: number, x2: number, y2: number) => {
    const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    if (distance === Math.round(distance)) {
      return Math.round(distance);
    } else {
      return distance;
    }
  };

  return (
    <div
      className={s.sceneControlsGroup2D}
      style={{
        left: `${selectedElement.uiAnchor[0]}px`,
        top: `${selectedElement.uiAnchor[1]}px`,
      }}
    >
      <span className={s.triangle}></span>
      {selectedElementType === "walls" && attributes !== undefined  && (
        <>
          <div className={s.groupFields}>
            <TwoLineParams>
              <InputNumberSize
                id="wallLength"
                defaultValue={getWallLength(
                  attributes[ATTRIBUTE_NAMES.wallStartX]["value"] as number,
                  attributes[ATTRIBUTE_NAMES.wallStartZ]["value"] as number,
                  attributes[ATTRIBUTE_NAMES.wallEndX]["value"] as number,
                  attributes[ATTRIBUTE_NAMES.wallEndZ]["value"] as number
                )}
                label={"Length"}
                step={0.1}
                path={selectedElementPath}
                min={0.5}
                max={30}
              />
              <InputNumberSize
                defaultValue={wallThicknessValue}
                label={"Thickness"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.wallThickness}
                min={0.1}
              />
            </TwoLineParams>
            <TwoLineParams>
              <InputNumberSize
                defaultValue={wallHeightValue}
                label={"Height"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.wallHeight}
                min={1}
              />
            </TwoLineParams>
          </div>
          <div className={s.groupAddBtns}>
            <ButtonMain
              text="Add Window"
              onClick={() => selectedElement.addFeature('windows')}
              color="white"
              size="middle"
              fullWidth={true}
            />
            <ButtonMain
              text="Add Door"
              onClick={() => selectedElement.addFeature('doors')}
              color="white"
              size="middle"
              fullWidth={true}
            />
            <ButtonMain
              text="Add Opening"
              onClick={() => selectedElement.addFeature('openings')}
              color="white"
              size="middle"
              fullWidth={true}
            />
          </div>
        </>
      )}
      {selectedElementType === "windows" && (
        <div className={s.groupFields}>
          <TwoLineParams>
            {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureLength] && (
              <InputNumberSize
                defaultValue={
                  attributes[ATTRIBUTE_NAMES.featureLength]["value"]
                }
                label={"Length"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featureLength}
                min={0.1}
                setAttribute={(val: number) => setConfiguration({ [ATTRIBUTE_NAMES.featureLength]: val }) }
              />
            )}
            {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureHeight] && (
              <InputNumberSize
                defaultValue={
                  attributes[ATTRIBUTE_NAMES.featureHeight]["value"]
                }
                label={"Height"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featureHeight}
                min={0.1}
                setAttribute={(val: number) => setConfiguration({ [ATTRIBUTE_NAMES.featureHeight]: val }) }
              />
            )}
          </TwoLineParams>
          {!!attributes && !!attributes[ATTRIBUTE_NAMES.featurePositionY] && (
            <TwoLineParams>
              <InputNumberSize
                defaultValue={
                  attributes[ATTRIBUTE_NAMES.featurePositionY]["value"]
                }
                label={"Floor offset"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featurePositionY}
                min={0.1}
                setAttribute={(val: number) => setConfiguration({ [ATTRIBUTE_NAMES.featurePositionY]: val }) }
              />
            </TwoLineParams>
          )}
        </div>
      )}
      {selectedElementType === "doors" && (
        <div className={s.groupFields}>
          <TwoLineParams>
            {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureLength] && (
              <InputNumberSize
                defaultValue={
                  attributes[ATTRIBUTE_NAMES.featureLength]["value"]
                }
                label={"Length"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featureLength}
                min={0.1}
                setAttribute={(val: number) => setConfiguration({ [ATTRIBUTE_NAMES.featureLength]: val }) }
              />
            )}
          </TwoLineParams>
          {/* <DoorSideButtons>

            {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureLength] && (
              <BtnSwitchDoorSide
                defaultValue={
                  doors["value"][selectedElementPath[1]]["configuration"][
                    ATTRIBUTE_NAMES.featureDoorInsideOutside
                  ]
                }
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featureDoorInsideOutside}
                label="Door Opening Direction"
                icon={<FlipVertical />}
                attribute={attributes[ATTRIBUTE_NAMES.featureDoorInsideOutside]}
              />
            )}

            {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureLength] && (
              <BtnSwitchDoorSide
                defaultValue={
                  doors["value"][selectedElementPath[1]]["configuration"][
                    ATTRIBUTE_NAMES.featureDoorLeftRight
                  ]
                }
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featureDoorLeftRight}
                label="Door Orientation"
                icon={<FlipHorisontal />}
                attribute={attributes[ATTRIBUTE_NAMES.featureDoorLeftRight]}
              />
            )}
            
          </DoorSideButtons> */}
        </div>
      )}
      {selectedElementType === "openings" && (
        <div className={s.groupFields}>
          <TwoLineParams>
            {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureLength] && (
              <InputNumberSize
                defaultValue={
                  attributes[ATTRIBUTE_NAMES.featureLength]["value"]
                }
                label={"Length"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featureLength}
                min={0.1}
                setAttribute={(val: number) => setConfiguration({ [ATTRIBUTE_NAMES.featureLength]: val }) }
              />
            )}
            {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureHeight] && (
              <InputNumberSize
                defaultValue={
                  attributes[ATTRIBUTE_NAMES.featureHeight]["value"]
                }
                label={"Height"}
                step={0.1}
                path={selectedElementPath}
                attributeNameNode={ATTRIBUTE_NAMES.featureHeight}
                min={0.1}
                setAttribute={(val: number) => setConfiguration({ [ATTRIBUTE_NAMES.featureHeight]: val }) }
              />
            )}
          </TwoLineParams>
          {!!attributes && !!attributes[ATTRIBUTE_NAMES.featureOpeningsModel] && (
            <RadioOptionsSmall
              label={"Type"}
              path={selectedElementPath}
              attributeNameNode={ATTRIBUTE_NAMES.featureOpeningsModel}
              openings={openings}
              attribute={attributes[ATTRIBUTE_NAMES.featureOpeningsModel]}
            />
          )}
        </div>
      )}
      <div className={s.groupDeleteBtns}>
        <ButtonMain
          text={`Delete ${selectedElementPath[0].slice(0, -1)}`}
          onClick={() => {
            deleteElement(...selectedElementPath);
          }}
          color="redLight"
          size="middle"
          fullWidth={true}
        />
      </div>
    </div>
  );
};
