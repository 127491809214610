import MobileDetect from "mobile-detect";
import { buildWallFromData } from "../../functionsConfigurator/wallsAndFloor/buildWallFromData";
import { wallI } from "../../functionsConfigurator/wallsAndFloor/roomWallsJSON";
import { initializationThreeKitDataT } from "../initializationProvider/initializationProvider";
import { runTrackingConfigurationChanges } from "./trackingConfigurationChanges";

export interface IInit3kit {
    playerEl: HTMLDivElement | null;
    initializationThreekitData: initializationThreeKitDataT;
    roomData: wallI[];
    initialConfiguration?: any;
    otherParams?: any;
    dispatch: any;
  }

export const init3kit = ({playerEl, initializationThreekitData, dispatch, roomData, initialConfiguration, otherParams}: IInit3kit) => {
  if (!playerEl) return false;
  if (!window.threekitPlayer) return false;
  if (!initializationThreekitData["assetId"]) return false;

  const minWidth = [390, 428];
  const minHeight = [844, 926];
  const devicePixelRatioList = [3];
  let md = new MobileDetect(window.navigator.userAgent);
  let isIphone = md.mobile() === "iPhone";
  const innerWidth = window.screen.width;
  const innerHeight = window.screen.height;
  const devicePixelRatio = window.devicePixelRatio;

  const isIphone12or13 =
    isIphone &&
    minWidth.includes(innerWidth) &&
    minHeight.includes(innerHeight) &&
    devicePixelRatioList.includes(devicePixelRatio);

  if (!!window.threekitPlayer) {

    let objectInitalTheekit: any = {
      authToken: initializationThreekitData["publicToken"],
      el: playerEl,
      assetId: initializationThreekitData["assetId"],
    //   initialConfiguration: initialConfiguration,
      showAR: true,
      showLoadingThumbnail: true,
      showConfigurator: true,
    };
    if (isIphone12or13) {
      objectInitalTheekit = {
        ...objectInitalTheekit,
        advancedAR: {
          enabled: true,
        //   eighthWallApiKey: initializationThreekitData["eighthWallApiKey"],
        },
      };
    }

    window.threekitPlayer(objectInitalTheekit).then(async (api: any) => {
      // window.apiT = api;
      // window.player = api;
      await api.when("loaded");
      // window.playerThreekitApi = await api.enableApi("player");

      // window.configurator = await api.getConfigurator();

      api.on("rendered", () => {
        // run build walls
        // buildWallFromData(roomData);
        runTrackingConfigurationChanges();
      });

    });
  }
};

export const load3kit = (tkcsid: any, threekitUrl: string, callback: any) => {
  const existingScript = document.getElementById("threekit");

  if (!existingScript && threekitUrl) {
    const script = document.createElement("script");
    script.src = `${threekitUrl}app/js/threekit-player.js`;
    script.id = "threekit";
    document.body.appendChild(script);
    const scriptPhysics = document.createElement("script");
    scriptPhysics.src = `${threekitUrl}app/js/threekit-packs-physics.js`;
    scriptPhysics.id = "threekitPhysics";
    document.body.appendChild(scriptPhysics);
    script.onload = () => {
      if (callback) callback();
    };
  }

  let interval: any = null;
  const callbackIfExist = () => {
    if (!window.threekitPlayer) return false;

    clearInterval(interval);
    callback();
  };

  if (existingScript && callback) {
    // callbackIfExist();
    interval = setInterval(() => {
      callbackIfExist();
    }, 50);
  }
};

export const loadCollisionPhysics = () => {
  const existingScript = document.getElementById("threekitPhysics");
  if (!existingScript) {
    const scriptPhysics = document.createElement("script");
    scriptPhysics.src = `https://preview.threekit.com/app/js/threekit-packs-physics.js`;
    scriptPhysics.id = "threekitPhysics";
    document.body.appendChild(scriptPhysics);
  }
};
