import axios from "axios";

const REACT_APP_ELEMENTALS_API_URL = process.env.REACT_APP_ELEMENTALS_API_URL;

export const getIdGeneratedProject = async (): Promise<any> => {
  return axios({
    method: "get",
    url: `${REACT_APP_ELEMENTALS_API_URL}/getLastPdfId`,
  })
    .then((response: any) => response.data)
    .then((data: any) => {
      return data;
    });
};