import { IElements, ISpacesAttributes } from "@threekit-tools/treble/dist/hooks/useSpaces/types";

export const ATTRIBUTE_NAMES = {
  walls: "_tkSpace_walls",
  windows: "_tkSpace_windows",
  openings: "_tkSpace_doors",
  doors: "_tkSpace_openings",

  featurePositionX: "_tkSpace_offsetX",
  featurePositionY: "_tkSpace_offsetY",
  featurePositionZ: "_tkSpace_offsetZ",
  featureConnectedTo: "_tkSpace_connectedTo",
  featureHeight: "_tkSpace_featureHeight",
  featureLength: "_tkSpace_featureWidth",
  featureRotation: '_tkSpace_featureRotation',
  featureThickness: '_tkSpace_featureThickness',
  featureDoorLeftRight: '_tkSpace_doorOrientation',
  featureDoorInsideOutside: '_tkSpace_doorOpeningDirection',
  featureOpeningsModel: '_tkSpace_openingsModel',

  wallStartX: "_tkSpace_startX",
  wallStartY: "_tkSpace_startY",
  wallStartZ: "_tkSpace_startZ",
  wallEndX: "_tkSpace_endX",
  wallEndY: "_tkSpace_endY",
  wallEndZ: "_tkSpace_endZ",
  wallHeight: "_tkSpace_wallHeight",
  wallHeightLocal: "_tkSpace_wallThicknessLocal",
  wallLockHeight: "_tkSpace_lockThickness",
  wallThickness: "_tkSpace_wallThickness",
  wallThicknessLocal: "_tkSpace_wallThicknessLocal",
  wallLockThickness: "_tkSpace_lockThickness",
  wallConnections: "_tkSpace_connections",
  wallIsVisible: "_tkSpace_isVisibleWall",
};


enum SpacesAttributes {
  WALLS = '_tkSpace_walls',
  WINDOWS = '_tkSpace_windows',
  DOORS = '_tkSpace_doors',
  OPENINGS = '_tkSpace_openings',
}

enum WallAttributes {
  START_X = '_tkSpace_startX',
  START_Y = '_tkSpace_startY',
  START_Z = '_tkSpace_startZ',
  END_X = '_tkSpace_endX',
  END_Y = '_tkSpace_endY',
  END_Z = '_tkSpace_endZ',
  CONNECTIONS = '_tkSpace_connections',
  WALL_HEIGHT = '_tkSpace_wallHeight',
  WALL_THICKNESS = '_tkSpace_wallThickness',
}

enum FeatureAttribute {
  POSITION_X = "_tkSpace_offsetX",
  POSITION_Y = "_tkSpace_offsetY",
  POSITION_Z = "_tkSpace_offsetZ",
  CONNECTED_TO = "_tkSpace_connectedTo",
  FEATURE_HEIGHT = "_tkSpace_featureHeight",
  FEATURE_LENGTH = "_tkSpace_featureWidth",
}

// export const attributeNameDefaults: ISpacesAttributes = {
//   [IElements.WALL]: SpacesAttributes.WALLS,
//   [IElements.WINDOW]: SpacesAttributes.WINDOWS,
//   [IElements.OPENING]: SpacesAttributes.OPENINGS,
//   [IElements.DOOR]: SpacesAttributes.DOORS,

//   featurePositionX: FeatureAttribute.POSITION_X,
//   featurePositionY: FeatureAttribute.POSITION_Y,
//   // featurePositionZ: FeatureAttribute.POSITION_Z,
//   featureConnectedTo: FeatureAttribute.CONNECTED_TO,
//   featureHeight: FeatureAttribute.FEATURE_HEIGHT,
//   featureLength: FeatureAttribute.FEATURE_LENGTH,

//   wallStartX: WallAttributes.START_X,
//   wallStartY: WallAttributes.START_Y,
//   wallStartZ: WallAttributes.START_Z,
//   wallEndX: WallAttributes.END_X,
//   wallEndY: WallAttributes.END_Y,
//   wallEndZ: WallAttributes.END_Z,
//   wallConnections: WallAttributes.CONNECTIONS,
//   // wallThickness: WallAttributes.WALL_THICKNESS,
//   // wallHeight: WallAttributes.WALL_HEIGHT,
// };
