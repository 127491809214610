import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import {
  ModelsName_NodesT,
  NODES_THREEKIT,
} from "../../../utils/constants/nodesNamesThreekit";
import {
  getEvalNode,
  getInstanceIdAssetFromNullModel,
  getNodeIdFromName,
  getRotationThreekit,
  getTranslationThreekit,
  getWorldTransformEvalNode,
} from "../../../utils/threekit/general/getFunctions";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsInfoOnWall";
import { moveCoordsByVector } from "../../wallsAndFloor/buildWallFromData";
import { checkIntersectsModelsBox } from "../IntersectsModelsBox";
import { getSizeModelBoxFromAssetCabinetBase } from "../cabinetsBase/size";
import { getСompletedModelsNullNames } from "../getNodesCabinets";
import * as THREE from "three";
import { getVector3FromCoordinates } from "../../../utils/three/general/getFunctionsTHREE";

const getNodeForCabinetsWallCountertop = (
  modelName: ModelsName_NodesT
): ISceneResult => {
  const instanceId = getInstanceIdAssetFromNullModel({name: modelName});

  const modelBoxEvalNode = getEvalNode({
    from: instanceId,
    name: "counter_*",
  });

  return modelBoxEvalNode;
};

const getSizeModelCountertop = (modelName: ModelsName_NodesT): ICoordinates => {
  const modelBoxEvalNode = getNodeForCabinetsWallCountertop(modelName);
  try {
    //@ts-ignore
    const boundingBox = modelBoxEvalNode.getBoundingBox({
      //@ts-ignore
      relativeTransform: modelBoxEvalNode.worldTransform,
    });
    const sizeModelBoxFromScale = boundingBox.getSize(new THREE.Vector3());
    // const sizeModelBoxOriginal = checkModelBoxScale(sizeModelBoxFromScale, modelBoxEvalNode);
    return sizeModelBoxFromScale;
  } catch (error) {
    console.error(error);
    return getSizeModelRelativeTransform(modelName);
  }
};

interface InfoForCabinetsIslandI {
  rotation: ICoordinates;
  neabor: ModelsName_NodesT[];
}
type ObjInfoForCabinetsIslandT = {
  [key in ModelsName_NodesT]: InfoForCabinetsIslandI;
};
const getObjInfoForCabinetsIsland = (): ObjInfoForCabinetsIslandT => {
  const allNullForСabinetsIsland = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );

  let objInfo: ObjInfoForCabinetsIslandT = {};
  allNullForСabinetsIsland.forEach((name) => {
    const evalNode = getEvalNode({ name: name });
    //@ts-ignore
    const boundingBox = evalNode.getBoundingBox();

    objInfo[name] = {
      ...objInfo[name],
      rotation: getRotationThreekit({ name: name }),
      neabor: [],
    };

    allNullForСabinetsIsland.forEach((nameLast) => {
      if (name !== nameLast) {
        const evalNodeLast = getEvalNode({ name: nameLast });
        //@ts-ignore
        const boundingBoxLast = evalNodeLast.getBoundingBox();
        if (checkIntersectsModelsBox(boundingBox, boundingBoxLast)) {
          objInfo[name] = {
            ...objInfo[name],
            neabor: [...objInfo[name]["neabor"], nameLast],
          };
        }
      }
    });
  });

  return objInfo;
};

const getDepthCountertop = (
  objInfoForCabinetsIsland: ObjInfoForCabinetsIslandT,
  externalСabinets: ModelsName_NodesT[],
  sizeExternalСabinetFirst: ICoordinates
): number => {
  const sizeCountFirstModelFull = getSizeModelCountertop(externalСabinets[0]);
  const sizeCountNeaborModelFull = getSizeModelCountertop(
    objInfoForCabinetsIsland[externalСabinets[0]]["neabor"][0]
  );
  const depthCountertop =
    sizeExternalСabinetFirst["x"] - sizeCountNeaborModelFull["z"];
  return depthCountertop;
};

const getWidthCountertop = (innerCabinets: ModelsName_NodesT[]): number => {
  let widthCountertop = 0;
  innerCabinets.forEach((name) => {
    const sizeInnerModel = getSizeModelBoxFromAssetCabinetBase(
      name as ModelsName_NodesT
    );
    widthCountertop += sizeInnerModel["x"];
  });
  return widthCountertop;
};

const getPositionCountertop = (
  externalСabinets: ModelsName_NodesT[],
  innerCabinets: ModelsName_NodesT[],
  sizeExternalСabinetFirst: ICoordinates,
  depthCountertop: number
): ICoordinates => {
  const posFirstExternalСabinet = getTranslationThreekit({
    name: externalСabinets[0],
  });
  const posLastExternalСabinet = getTranslationThreekit({
    name: externalСabinets[1],
  });
  const posFirstExternalСabinetVector = getVector3FromCoordinates(
    posFirstExternalСabinet
  );
  const posLastExternalСabinetVector = getVector3FromCoordinates(
    posLastExternalСabinet
  );
  var combinedVector = posFirstExternalСabinetVector
    .clone()
    .add(posLastExternalСabinetVector);
  var middlePoint = combinedVector.divideScalar(2);
  const worldTransformCabinetInner = getWorldTransformEvalNode(
    innerCabinets[0]
  );
  const cabinetInnerDirFront = new THREE.Vector3(0, 0, 1).transformDirection(
    worldTransformCabinetInner
  );
  const modelDirBack = cabinetInnerDirFront.negate();
  const positionCountertop = moveCoordsByVector(
    middlePoint,
    modelDirBack,
    sizeExternalСabinetFirst["x"] / 2 - depthCountertop + depthCountertop / 2
  );
  return positionCountertop;
};

const addCountertopBox = (
  widthCountertop: number,
  depthCountertop: number,
  positionCountertop: ICoordinates,
  rotateCountertop: ICoordinates
) => {
  const newNodeParamsWall = {
    name: "test_name_countertop",
    type: "PolyMesh",
    plugs: {
      PolyMesh: [
        {
          type: "Box",
          height: 0.0299999993, // висота столешні,
          width: widthCountertop,
          depth: depthCountertop,
        },
      ],
      Transform: [
        {
          type: "Transform",
          translation: {
            ...positionCountertop,
            y: 0.8617707061767579 + 0.0299999993 / 2,
          },
          rotation: rotateCountertop,
        },
      ],
      Material: [
        {
          type: "Reference",
          asset: { assetId: "da436348-b395-4354-b36c-77988c371482" },
        },
      ],
    },
  };

  const wallsLayoutContainerId = getNodeIdFromName(
    NODES_THREEKIT.LAYOUT_CONTAINER_WALLS_WRAP
  );

  //@ts-ignore
  const assetIdWall = window.threekit.player.scene.addNode(
    newNodeParamsWall,
    wallsLayoutContainerId
  );
};

export const buildCountertopForIsland = () => {
  //@ts-ignore
  const nodeCountertop = window.threekit.player.scene.getAll({
    name: "test_name_countertop",
  });

  Object.keys(nodeCountertop).forEach((idNode) => {
    //@ts-ignore
    window.threekit.player.scene.deleteNode(idNode);
  });

  const objInfoForCabinetsIsland = getObjInfoForCabinetsIsland();

  const keysObjInfoForCabinetsIsland = Object.keys(
    objInfoForCabinetsIsland
  ) as Array<keyof typeof objInfoForCabinetsIsland>;

  if (Object.keys(objInfoForCabinetsIsland).length >= 3) {
    const externalСabinets = keysObjInfoForCabinetsIsland.filter(
      (item) => objInfoForCabinetsIsland[item]["neabor"].length === 1
    );
    const innerCabinets = keysObjInfoForCabinetsIsland.filter(
      (item) => objInfoForCabinetsIsland[item]["neabor"].length === 2
    );

    if (externalСabinets.length === 2) {
      const rotateExternalСabinetFirst =
        objInfoForCabinetsIsland[externalСabinets[0]]["rotation"];
      const rotateNeaborExternalСabinetFirst =
        objInfoForCabinetsIsland[
          objInfoForCabinetsIsland[externalСabinets[0]]["neabor"][0]
        ]["rotation"];
      const rotateExternalСabinetLast =
        objInfoForCabinetsIsland[externalСabinets[1]]["rotation"];
      const rotateNeaborExternalСabinetLast =
        objInfoForCabinetsIsland[
          objInfoForCabinetsIsland[externalСabinets[1]]["neabor"][0]
        ]["rotation"];

      const diffRotationForFirstModel =
        Math.round(rotateExternalСabinetFirst["y"]) -
        Math.round(rotateNeaborExternalСabinetFirst["y"]);
      const diffRotationForLastModel =
        Math.round(rotateExternalСabinetLast["y"]) -
        Math.round(rotateNeaborExternalСabinetLast["y"]);

      const sizeExternalСabinetFirst = getSizeModelRelativeTransform(
        externalСabinets[0]
      );
      const sizeExternalСabinetLast = getSizeModelRelativeTransform(
        externalСabinets[1]
      );

      if (
        Math.abs(diffRotationForFirstModel) === 90 &&
        Math.abs(diffRotationForLastModel) === 90 &&
        sizeExternalСabinetFirst["x"].toFixed(3) ===
          sizeExternalСabinetLast["x"].toFixed(3)
      ) {
        // Знайти глубину столешні
        const depthCountertop = getDepthCountertop(
          objInfoForCabinetsIsland,
          externalСabinets,
          sizeExternalСabinetFirst
        );

        // Знайти ширину столешні
        const widthCountertop = getWidthCountertop(innerCabinets);

        // Знайти позицію столешні
        const positionCountertop = getPositionCountertop(
          externalСabinets,
          innerCabinets,
          sizeExternalСabinetFirst,
          depthCountertop
        );

        // Знайти поворот столешні
        const rotateCountertop = rotateNeaborExternalСabinetFirst;

        // Додати столешню
        addCountertopBox(
          widthCountertop,
          depthCountertop,
          positionCountertop,
          rotateCountertop
        );
      }
    }
  }
};
