import {
  getAppliedConfigurationThreekitAttribute,
  getEvalNodeFromName,
  getInstanceIdFromProxyEvalNode,
  getMeshesLayoutContainer,
  getNodeIdFromName,
  getPositionCamera,
  getSceneInstanceId,
  getTranslationThreekit,
} from "../../utils/threekit/general/getFunctions";
import { buildFloorForRoom } from "./buildFloorForRoom";
import {
  IConfigurationArray,
  IConfigurationAttribute,
  ICoordinates,
} from "@threekit-tools/treble/dist/types";
import { NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getTHREE, getVector3FromCoordinates } from "../../utils/three/general/getFunctionsTHREE";
import {
  CABINETS_WALL_BOTTOM_POSITION,
  CABINETS_WALL_STANDART_HEIGHT,
  WALL_PLANE_BOTTOM_POSITION,
} from "../cabinets/constatns";
import { ATTRIBUTE_NAMES } from "./../../utils/constants/attributesThreekitRoomBuilder";
import { moveWindowsToWalls } from "./moveWindowsToWalls";
import { moveDoorsToWalls } from "./moveDoorsToWalls";
import { moveOpeningsToWalls } from "./moveOpeningsToWalls";
import { getNumberNodeThreekitFromName } from "../general";
import { WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { debouncedCheckLockTranslationCabinetsWall } from "../cabinets/cabinetsWall/checkLockTranslationCabinetsWall";
import { debouncedCheckLockTranslationCabinetsBase } from "../cabinets/cabinetsBase/checkLockTranslationCabinetsBase";
import {
  setMeshesLayoutContainer,
  setMaterialThreekit,
  setPolyMeshHeightThreekit,
  setTranslationThreekit,
  setVisibleAll,
  setVisible,
} from "../../utils/threekit/general/setFunctions";
import { getIndexWallNextToLeft, getIndexWallNextToRight } from "../configurator2D/wallLength";
import { getEndPointFromConfigurationWall, getStartPointFromConfigurationWall } from "../configurator2D/wallsGeneral";
import { movePointInDirection } from "../../utils/three/movePointInDirection";
import * as THREE from "three";
import { addPlaneBoundingCeiling } from "./addNodes/addPlaneBoundingCeiling";
import { addWallInRoom } from "./addNodes/addWallInRoom";
import { addPlaneForCabinetsWall } from "./addNodes/addPlaneForCabinetsWall";
import { addPlanesBoundingForWall } from "./addNodes/addPlanesBoundingForWall";

export const ASSET_ID_OPACITY_MATERIAL = "7f84100f-003a-4739-aea9-af04ca72d0df";
export const HEIGHT_HIDDEN_WALL = 0.025;
export const DEPTH_HIDDEN_WALL = 0.025;
export const WIDTH_BOUNDING_PLANES = 0.025;

export const getMiddleCoords = (
  start: ICoordinates,
  end: ICoordinates
): ICoordinates => {
  const coordX = (start["x"] + end["x"]) / 2;
  const coordY = (start["y"] + end["y"]) / 2;
  const coordZ = (start["z"] + end["z"]) / 2;
  return {
    x: coordX,
    y: coordY,
    z: coordZ,
  };
};

export const createVectorFromPoints = (
  pointStart: ICoordinates,
  pointEnd: ICoordinates
): ICoordinates => {
  //@ts-ignore
  return new window.threekit.player.THREE.Vector3(
    pointEnd["x"] - pointStart["x"],
    pointEnd["y"] - pointStart["y"],
    pointEnd["z"] - pointStart["z"]
  );
};

/**
 * Визначає в яку сторону має бути направлений вектор по осі Y (вверх - "1" чи вниз - "-1").
 * Для того щоб правильно порахувати напрямок векторного добутку векторів за "правилом правої руки"
 * Щов вектор отриманий з векторного добутку був направлений до цетру координат
 *
 * @param {ICoordinates} startWallPoint Координати початку відрізку стіни
 * @param {ICoordinates} endWallPoint Координати кінця відрізку стіни
 * @return {1 | -1} directionVectorY, 1 - значить вектор вверх по Y, -1 - значить вектор вниз по Y.
 */
const getDirectionVectorY = (
  startWallPoint: ICoordinates,
  endWallPoint: ICoordinates
): 1 | -1 => {
  let directionVectorY: 1 | -1 = 1;
  if (startWallPoint["x"] > 0 && endWallPoint["x"] > 0) {
    if (startWallPoint["z"] > endWallPoint["z"]) {
      directionVectorY = -1;
    }
  } else if (startWallPoint["z"] > 0 && endWallPoint["z"] > 0) {
    if (startWallPoint["x"] < endWallPoint["x"]) {
      directionVectorY = -1;
    }
  } else if (startWallPoint["x"] < 0 && endWallPoint["x"] < 0) {
    if (startWallPoint["z"] < endWallPoint["z"]) {
      directionVectorY = -1;
    }
  } else if (startWallPoint["z"] < 0 && endWallPoint["z"] < 0) {
    if (startWallPoint["x"] > endWallPoint["x"]) {
      directionVectorY = -1;
    }
  }
  return directionVectorY;
};

/**
 * Визначає нормілізований вектор (axis <= 1), який показує напрям від стіни до центру координат
 *
 * @param {ICoordinates} startWallPoint Координати початку відрізку стіни
 * @param {ICoordinates} endWallPoint Координати кінця відрізку стіни
 * @return {ICoordinates} crossVectorNorm, нормілізований вектор (axis <= 1), який показує напрям від стіни до центру координат.
 */
export const getCrossVector = (
  startWallPoint: ICoordinates,
  endWallPoint: ICoordinates
): THREE.Vector3 => {

  let directionVectorY = getDirectionVectorY(startWallPoint, endWallPoint);
  const vectorWall = createVectorFromPoints(startWallPoint, endWallPoint);
  const vectorY = createVectorFromPoints(startWallPoint, {
    x: startWallPoint["x"],
    y: directionVectorY,
    z: startWallPoint["z"],
  });

  // повертаємо нормілізований вектор (axis <= 1), який показує напрям від стіни до центру координат
  // crossVectors - повертає вектор, перпендикулярний двум векторам
  // https://ru.wikipedia.org/wiki/%D0%92%D0%B5%D0%BA%D1%82%D0%BE%D1%80%D0%BD%D0%BE%D0%B5_%D0%BF%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5
  //@ts-ignore
  let crossVectorNorm = new window.threekit.player.THREE.Vector3()
    .crossVectors(vectorWall, vectorY)
    .normalize();

  return crossVectorNorm;
};

export const getAssetIdCurrentMaterial = () => {
  const configurationAttribute =
    getAppliedConfigurationThreekitAttribute("Wall material");
  const instanceIdFromProxy = getInstanceIdFromProxyEvalNode(
    configurationAttribute
  );
  return instanceIdFromProxy;
};

export const isEqualCoords = (
  coordsStart: ICoordinates,
  coordsEnd: ICoordinates
): boolean => {
  if (
    coordsStart["x"].toFixed(4) === coordsEnd["x"].toFixed(4) &&
    coordsStart["y"].toFixed(4) === coordsEnd["y"].toFixed(4) &&
    coordsStart["z"].toFixed(4) === coordsEnd["z"].toFixed(4)
  )
    return true;

  return false;
};

type CoordsStartEnd = {
  start: ICoordinates;
  end: ICoordinates;
};
export const getFullCoordsFromWallConfiguration = (
  wallConfiguration: any
): CoordsStartEnd => {
  return {
    start: {
      x: !!wallConfiguration[ATTRIBUTE_NAMES.wallStartX]
        ? wallConfiguration[ATTRIBUTE_NAMES.wallStartX]
        : 0,
      y: !!wallConfiguration[ATTRIBUTE_NAMES.wallStartY]
        ? wallConfiguration[ATTRIBUTE_NAMES.wallStartY]
        : 0,
      z: !!wallConfiguration[ATTRIBUTE_NAMES.wallStartZ]
        ? wallConfiguration[ATTRIBUTE_NAMES.wallStartZ]
        : 0,
    },
    end: {
      x: !!wallConfiguration[ATTRIBUTE_NAMES.wallEndX]
        ? wallConfiguration[ATTRIBUTE_NAMES.wallEndX]
        : 0,
      y: !!wallConfiguration[ATTRIBUTE_NAMES.wallEndY]
        ? wallConfiguration[ATTRIBUTE_NAMES.wallEndY]
        : 0,
      z: !!wallConfiguration[ATTRIBUTE_NAMES.wallEndZ]
        ? wallConfiguration[ATTRIBUTE_NAMES.wallEndZ]
        : 0,
    },
  };
};

const getAngleWall = (
  pointStart: ICoordinates,
  pointEnd: ICoordinates
): number => {
  const an = Math.atan2(
    pointEnd["z"] - pointStart["z"],
    pointEnd["x"] - pointStart["x"]
  );
  const deg_an = an * (180 / Math.PI);
  if (deg_an > 0) return deg_an * -1;
  if (deg_an < 0) return deg_an - 180;
  return deg_an;
};

const getAngleWallFromWallConfiguration = (wallConfiguration: any) => {
  const startPoint = [
    wallConfiguration[ATTRIBUTE_NAMES.wallStartX],
    wallConfiguration[ATTRIBUTE_NAMES.wallStartZ],
  ];
  const endPoint = [
    wallConfiguration[ATTRIBUTE_NAMES.wallEndX],
    wallConfiguration[ATTRIBUTE_NAMES.wallEndZ],
  ];
  const [x1, y1] = startPoint;
  const [x2, y2] = endPoint;
  // Calculate the angle of the line segment using the inverse tangent function
  const angle = Math.atan2(y2 - y1, x2 - x1);
  const angleDegrees = angle * (180 / Math.PI);

  // Обчислення поправки для кута повороту стіни для випадку,
  // коли стіна будувалася проти годинникової стрілки.
  // Додаємо до обчисленого повороту стіни 180 градусів
  // щоб стіна була направлена передньою стороною всередину кімнати
  const { start, end } = getFullCoordsFromWallConfiguration(wallConfiguration);
  const directionVectorY = getDirectionVectorY(start, end);
  const correctionForBuildingWallCounterclockwise = directionVectorY < 0 ? 180 : 0;

  return angleDegrees * -1 + correctionForBuildingWallCounterclockwise;
};

/**
 * Конвертує координати стін таким чином, щоб кімната розташовувалась в центрі координат на 3Д сцені
 *
 * @param {Array} arrValueAttributeWalls Массив стін(value) з атрибуту Threekit, який описує створені в 2Д стіни
 * @return {Array} newArrValueAttributeWalls, Конвертований вхідний массив, зі зміненими координатами.
 * Щоб кімната розташовувалась в центрі координат на 3Д сцені.
 */
const getArrWallsMovedToCenterCoordinates = (
  arrValueAttributeWalls: IConfigurationArray
) => {
  const getMaxMinRoomCoords = (arrValueAttributeWalls: IConfigurationArray) => {
    let arrCoordsX: number[] = [];
    let arrCoordsZ: number[] = [];
    arrValueAttributeWalls.forEach((objWall) => {
      //@ts-ignore
      const StartX = objWall["configuration"][ATTRIBUTE_NAMES.wallStartX];
      //@ts-ignore
      const StartZ = objWall["configuration"][ATTRIBUTE_NAMES.wallStartZ];
      //@ts-ignore
      const EndX = objWall["configuration"][ATTRIBUTE_NAMES.wallEndX];
      //@ts-ignore
      const EndZ = objWall["configuration"][ATTRIBUTE_NAMES.wallEndZ];

      arrCoordsX = [...arrCoordsX, ...[StartX, EndX]];
      arrCoordsZ = [...arrCoordsZ, ...[StartZ, EndZ]];
    });
    return {
      x: [Math.min.apply(null, arrCoordsX), Math.max.apply(null, arrCoordsX)],
      y: [0, 0],
      z: [Math.min.apply(null, arrCoordsZ), Math.max.apply(null, arrCoordsZ)],
    };
  };

  const maxMinRoomCoords = getMaxMinRoomCoords(arrValueAttributeWalls);
  const deltaX = (maxMinRoomCoords["x"][0] + maxMinRoomCoords["x"][1]) / 2;
  const deltaZ = (maxMinRoomCoords["z"][0] + maxMinRoomCoords["z"][1]) / 2;

  const newArrValueAttributeWalls = arrValueAttributeWalls.map((objWall) => {
    if (!!!objWall["configuration"]) return;
    const newStartX =
      //@ts-ignore
      objWall["configuration"][ATTRIBUTE_NAMES.wallStartX] - deltaX;

    const newStartZ =
      //@ts-ignore
      objWall["configuration"][ATTRIBUTE_NAMES.wallStartZ] - deltaZ;
    //@ts-ignore
    const newEndX = objWall["configuration"][ATTRIBUTE_NAMES.wallEndX] - deltaX;
    //@ts-ignore
    const newEndZ = objWall["configuration"][ATTRIBUTE_NAMES.wallEndZ] - deltaZ;
    const newConfiguration = {
      //@ts-ignore
      ...objWall["configuration"],
      [ATTRIBUTE_NAMES.wallStartX]: Number(newStartX.toFixed(2)),
      [ATTRIBUTE_NAMES.wallStartZ]: Number(newStartZ.toFixed(2)),
      [ATTRIBUTE_NAMES.wallEndX]: Number(newEndX.toFixed(2)),
      [ATTRIBUTE_NAMES.wallEndZ]: Number(newEndZ.toFixed(2)),
    };
    return {
      ...objWall,
      configuration: newConfiguration,
    };
  });

  return newArrValueAttributeWalls;
};

const addHeightAndThicknessToArrWalls = (
  arrWallsMovedToCenterCoordinates: IConfigurationArray
) => {
  return arrWallsMovedToCenterCoordinates.map((objWall) => {
    return {
      ...objWall,
      configuration: {
        //@ts-ignore
        ...objWall["configuration"],
        [ATTRIBUTE_NAMES.wallHeight]: 2.75,
        [ATTRIBUTE_NAMES.wallThickness]: 0.2,
      },
    };
  });
};

/**
 * Переміщає координати точки по заданому напрямку на задану відстань
 *
 * @param {ICoordinates} coords Координати точки які потрібно перемістити
 * @param {ICoordinates} directionVector Вектор, який вказує напрямок, в якому потрібно переміщати точку
 * @param {Number} offset Відстань, на яку потрібно перемістити точку
 * @return {ICoordinates} newCoords, Нові координати точки. Точка "coords" зміщена в напрямку "directionVector" на відстань "offset"
 */
export const moveCoordsByVector = (
  coords: ICoordinates,
  directionVector: ICoordinates,
  offset: number
): ICoordinates => {
  const THREE = getTHREE();
  const newCoords = new THREE.Vector3(
    coords["x"],
    coords["y"],
    coords["z"]
  ).addScaledVector(directionVector, offset);
  return newCoords;
};

export const getIndexWallAdjacentAtStart = (arrWalls: any, objWall: any, indxCurrentWall: number): number => {
  const startPointWall = getStartPointFromConfigurationWall(objWall);
  const indxWallNextToLeft = getIndexWallNextToLeft(
    arrWalls,
    indxCurrentWall,
    startPointWall
  );
  return indxWallNextToLeft;
}

export const getIndexWallAdjacentAtEnd = (arrWalls: any, objWall: any, indxCurrentWall: number): number => {
  const endPointWall = getEndPointFromConfigurationWall(objWall);
  const indxWallNextToRight = getIndexWallNextToRight(
    arrWalls,
    indxCurrentWall,
    endPointWall
  );
  return indxWallNextToRight;
}

export const getDepthWallAdjacentAtStart = (arrWalls: any, objWall: any, indxCurrentWall: number): number => {
  const indxWallNextToLeft = getIndexWallAdjacentAtStart(
    arrWalls,
    objWall,
    indxCurrentWall
  );
  let depthWallLeft = 0;
  if (indxWallNextToLeft !== -1) {
    const wallLeftConfiguration = arrWalls[indxWallNextToLeft]["configuration"];
    depthWallLeft = getDepthWallFromConfiguration(wallLeftConfiguration);
  }
  return depthWallLeft;
}

export const getDepthWallAdjacentAtEnd = (arrWalls: any, objWall: any, indxCurrentWall: number): number => {
  const indxWallNextToRight = getIndexWallAdjacentAtEnd(
    arrWalls,
    objWall,
    indxCurrentWall
  );
  let depthWallRight = 0;
  if (indxWallNextToRight !== -1) {
    const wallRightConfiguration = arrWalls[indxWallNextToRight]["configuration"];
    depthWallRight = getDepthWallFromConfiguration(wallRightConfiguration);
  }
  return depthWallRight;
}

const getIsVisibleWallFromConfiguration = (wallConfiguration: any): boolean => {
  return wallConfiguration[ATTRIBUTE_NAMES.wallIsVisible] === undefined
    ? true
    : (wallConfiguration[ATTRIBUTE_NAMES.wallIsVisible]);
};

export const getHeightWallFromConfiguration = (wallConfiguration: any): number => {
  const isVisibleWall = getIsVisibleWallFromConfiguration(wallConfiguration);
  if (!isVisibleWall) return HEIGHT_HIDDEN_WALL;
  return wallConfiguration[ATTRIBUTE_NAMES.wallHeight];
};

export const getDepthWallFromConfiguration = (wallConfiguration: any): number => {
  const isVisibleWall = getIsVisibleWallFromConfiguration(wallConfiguration);
  if (!isVisibleWall) return DEPTH_HIDDEN_WALL;
  return wallConfiguration[ATTRIBUTE_NAMES.wallLockThickness]
    ? wallConfiguration[ATTRIBUTE_NAMES.wallThickness]
    : wallConfiguration[ATTRIBUTE_NAMES.wallThicknessLocal];
};

const getWidthOriginal = (wallConfiguration: any): number => {
  const width = Math.sqrt(
    Math.pow(
      wallConfiguration[ATTRIBUTE_NAMES.wallStartX] -
        wallConfiguration[ATTRIBUTE_NAMES.wallEndX],
      2
    ) +
      Math.pow(
        wallConfiguration[ATTRIBUTE_NAMES.wallStartZ] -
          wallConfiguration[ATTRIBUTE_NAMES.wallEndZ],
        2
      )
  );
  return width;
};

const getWidthWallFromWallConfiguration = (wallObj: any, arrValueAttributeWalls: any, indxSelectedWall: number): number => {

  const width = getWidthOriginal(wallObj["configuration"]);

  const depthWallLeft = getDepthWallAdjacentAtStart(
    arrValueAttributeWalls,
    wallObj,
    indxSelectedWall,
  )
  const depthWallRight = getDepthWallAdjacentAtEnd(
    arrValueAttributeWalls,
    wallObj,
    indxSelectedWall,
  )

  return width + depthWallLeft/2 + depthWallRight/2;
};

const getMovedMiddleCoordsForWall = (arrWallsMovedToCenterCoordinates: any, objWall: any, indxCurrentWall: number) => {
  const wallConfiguration = objWall["configuration"];
  const height = getHeightWallFromConfiguration(wallConfiguration);
  const middleCoordsY = height / 2;
  const startPointWall = getStartPointFromConfigurationWall(objWall);
  const endPointWall = getEndPointFromConfigurationWall(objWall);

  const depthWallLeft = getDepthWallAdjacentAtStart(
    arrWallsMovedToCenterCoordinates,
    objWall,
    indxCurrentWall,
  )
  const depthWallRight = getDepthWallAdjacentAtEnd(
    arrWallsMovedToCenterCoordinates,
    objWall,
    indxCurrentWall,
  )

  let middleCoords = new THREE.Vector3(
    (startPointWall["x"] + endPointWall["x"]) / 2,
    middleCoordsY,
    (startPointWall["y"] + endPointWall["y"]) / 2
  );

  if (depthWallRight > depthWallLeft) {
    const endVector = new THREE.Vector3(endPointWall["x"], middleCoordsY, endPointWall["y"]);
    const directionVector = endVector.clone().sub(middleCoords);
    middleCoords = movePointInDirection(
      middleCoords,
      directionVector,
      (depthWallRight/2 - depthWallLeft/2) / 2
    )
  }
  if (depthWallLeft > depthWallRight) {
    const startVector = new THREE.Vector3(startPointWall["x"], middleCoordsY, startPointWall["y"]);
    const directionVector = startVector.clone().sub(middleCoords);
    middleCoords = movePointInDirection(
      middleCoords,
      directionVector,
      (depthWallLeft/2 - depthWallRight/2) / 2
    )
  }

  return middleCoords;
}

interface BoundingPlanesInfoInHiddenWallI {
  coords: ICoordinates;
  height?: number;
}
const getBoundingPlanesInfoInWall = (arrWalls: any, wallObj: any, indxCurrentWall: number): BoundingPlanesInfoInHiddenWallI[] => {
  const wallConfiguration = wallObj["configuration"];
  const isVisibleWall = getIsVisibleWallFromConfiguration(wallConfiguration);

  if (!isVisibleWall) return [];

  const { start, end } = getFullCoordsFromWallConfiguration(wallConfiguration);

  const indxWallNextToStart = getIndexWallAdjacentAtStart(
    arrWalls,
    wallObj,
    indxCurrentWall
  );
  const indxWallNextToEnd = getIndexWallAdjacentAtEnd(
    arrWalls,
    wallObj,
    indxCurrentWall
  );

  let isVisibleWallNextToStart = true;
  let isVisibleWallNextToEnd = true;
  // let heightWallNextToStart = 0;
  // let heightWallNextToEnd = 0;

  if (indxWallNextToStart !== -1) {
    const wallNextToStartConfiguration = arrWalls[indxWallNextToStart]["configuration"];
    isVisibleWallNextToStart = getIsVisibleWallFromConfiguration(wallNextToStartConfiguration);
    // heightWallNextToStart = getHeightWallFromConfiguration(wallNextToStartConfiguration);
  }

  if (indxWallNextToEnd !== -1) {
    const wallNextToEndConfiguration = arrWalls[indxWallNextToEnd]["configuration"];
    isVisibleWallNextToEnd = getIsVisibleWallFromConfiguration(wallNextToEndConfiguration);
    // heightWallNextToEnd = getHeightWallFromConfiguration(wallNextToEndConfiguration);
  }

  if (!isVisibleWallNextToStart && !isVisibleWallNextToEnd) {
    return [{
      coords: start,
      // height: heightWallNextToStart,
    },{
      coords: end,
      // height: heightWallNextToEnd,
    }]
  } else if (!isVisibleWallNextToStart && isVisibleWallNextToEnd) {
    return [{
      coords: start,
      // height: heightWallNextToStart,
    }]
  } else if (isVisibleWallNextToStart && !isVisibleWallNextToEnd) {
    return [{
      coords: end,
      // height: heightWallNextToEnd,
    }]
  } else {
    return [];
  }

}

// обєкт стін для show/hidden стін, які знаходяться перед камерою
type WallsFromCrossVectorT = { [key: WallItemT]: any };
let objWallsFromCrossVector: WallsFromCrossVectorT = {};

/**
 * Розміщує стіни на сцені в точці "Walls Wrap" відповідно до вхідного обьекта.
 *
 * @param {Array} arrValueAttributeWalls Массив стін(value) з атрибуту Threekit, який описує створені в 2Д стіни
 */
export const buildWallFromData = (
  arrValueAttributeWalls: IConfigurationAttribute,
  arrValueAttributeWindows: IConfigurationAttribute,
  arrValueAttributeDoors: IConfigurationAttribute,
  arrValueAttributeOpenings: IConfigurationAttribute
) => {
  console.log("arrValueAttributeWalls --- ==== ", arrValueAttributeWalls);
  console.log("arrValueAttributeWindows --- ==== ", arrValueAttributeWindows);
  console.log("arrValueAttributeDoors --- ==== ", arrValueAttributeDoors);
  console.log("arrValueAttributeOpenings --- ==== ", arrValueAttributeOpenings);

  objWallsFromCrossVector = {};

  // const assetIdCurrentMaterial = getAssetIdCurrentMaterial();
  const wallsLayoutContainerId = getNodeIdFromName(
    NODES_THREEKIT.LAYOUT_CONTAINER_WALLS_WRAP
  );

  if (
    !!!arrValueAttributeWalls ||
    !Array.isArray(arrValueAttributeWalls) ||
    arrValueAttributeWalls.length === 0
  )
    return;

  const arrWallsMovedToCenterCoordinates = getArrWallsMovedToCenterCoordinates(
    arrValueAttributeWalls
  ) as IConfigurationArray;

  // додаємо захардкожені висоту і товщину для масиву стін
  // const testArrWallsMovedToCenterCoordinates = addHeightAndThicknessToArrWalls(
  //   arrWallsMovedToCenterCoordinates
  // );

  Array.from(arrWallsMovedToCenterCoordinates).forEach(
    (wall, index: number) => {
      const wallName = `${NODES_THREEKIT.WALL_ITEM}${index}`;
      // console.log('00000000000000000000000000000000000000 --- ==== ',wallName);
      const planeCabinetsBaseName = `${NODES_THREEKIT.PLANE_CABINETS_WALL}${index}`;
      const wallConfiguration = wall["configuration"] as any;
      const { start, end } = getFullCoordsFromWallConfiguration(wallConfiguration);
      const isVisibleWall = getIsVisibleWallFromConfiguration(wallConfiguration); 
      const height = getHeightWallFromConfiguration(wallConfiguration);
      const depth = getDepthWallFromConfiguration(wallConfiguration);
      const widthOriginal = getWidthOriginal(wallConfiguration);
      const widthFromDepth = getWidthWallFromWallConfiguration(wall, arrWallsMovedToCenterCoordinates, index);
      const angle = getAngleWallFromWallConfiguration(wallConfiguration);
      let middleCoordsOriginal = getMiddleCoords(start, end);
      let middleCoordsForWallMoved = getMovedMiddleCoordsForWall(arrWallsMovedToCenterCoordinates, wall, index)
      const directionFrontVector = getCrossVector(start, end);
      // const directionBackVector = directionFrontVector.clone().multiplyScalar(-1);
      // middleCoordsForWallMoved = movePointInDirection(
      //   middleCoordsForWallMoved,
      //   directionBackVector,
      //   depth / 2
      // )

      const middleCoordsOriginalVector3 = getVector3FromCoordinates(middleCoordsOriginal)
      const coordsPlaneCenter = movePointInDirection(
        middleCoordsOriginalVector3,
        directionFrontVector,
        (depth / 2) + 0.0005 // 0.0005 - відступ від стіни в півміліметра, щоб тумби не стояли в стіні
      )

      const assetIdWall = addWallInRoom({
        index,
        wallHeight: height,
        wallWidthOriginal: widthOriginal,
        wallWidthFromDepth: widthFromDepth,
        wallDepth: depth,
        coordsPosition: middleCoordsForWallMoved,
        wallAngle: angle,
        isVisible: isVisibleWall,
      })

      const assetIdPlaneForCabinetsWall = addPlaneForCabinetsWall({
        index,
        wallHeight: height,
        wallWidthOriginal: widthOriginal,
        wallWidthFromDepth: widthFromDepth,
        wallDepth: depth,
        coordsPosition: coordsPlaneCenter,
        wallAngle: angle,
      })

      // Adding walls in "Container Meshes" for "Layout_Container_Cabinet_Base"
      const arrMeshesCabinetBase = getMeshesLayoutContainer(
        NODES_THREEKIT.LAYOUT_CONTAINER_CABINET_BASE
      );
      setMeshesLayoutContainer(NODES_THREEKIT.LAYOUT_CONTAINER_CABINET_BASE, [
        ...arrMeshesCabinetBase,
        assetIdPlaneForCabinetsWall,
      ]);

      // Adding walls planes in "Container Meshes" for "Layout_Container_Cabinet_Wall"
      const arrMeshesCabinetWall = getMeshesLayoutContainer(
        NODES_THREEKIT.LAYOUT_CONTAINER_CABINET_WALL
      );
      setMeshesLayoutContainer(NODES_THREEKIT.LAYOUT_CONTAINER_CABINET_WALL, [
        ...arrMeshesCabinetWall,
        assetIdPlaneForCabinetsWall,
      ]);


      // якщо стіна невидима (відсутня) додаємо обмежуючі Plane
      // щоб тумби не можна було зсунути за межі стіни
      if (isVisibleWall) {

        const assetIdPlaneBoundingCeiling = addPlaneBoundingCeiling({
          index,
          wallHeight: height,
          wallWidthOriginal: widthOriginal,
          wallWidthFromDepth: widthFromDepth,
          wallDepth: depth,
          coordsPosition: middleCoordsForWallMoved,
          wallAngle: angle,
          isVisible: isVisibleWall
        })

        const boundingPlanesInfo = getBoundingPlanesInfoInWall(arrWallsMovedToCenterCoordinates, wall, index);
        boundingPlanesInfo.forEach((objBoundingPlanes, indxBoundingPlane) => {
          const assetIdBoundingPlane = addPlanesBoundingForWall({
            indexWall: index,
            indexPlane: indxBoundingPlane,
            wallHeight: height,
            wallDepth: depth,
            coordsPosition: objBoundingPlanes["coords"],
            wallAngle: angle,
          })
        })
      }

      // array walls names from CrossVector
      if (isVisibleWall) {
        objWallsFromCrossVector = {
          ...objWallsFromCrossVector,
          [wallName]: directionFrontVector,
        };
      }
      
    }
  );

  buildFloorForRoom(arrWallsMovedToCenterCoordinates);
  // setTimeout(() => {
  //   moveWindowsToWalls(
  //     arrWallsMovedToCenterCoordinates,
  //     arrValueAttributeWindows as IConfigurationArray
  //   );
  // }, 1000);
  // setTimeout(() => {
  //   moveDoorsToWalls(
  //     arrWallsMovedToCenterCoordinates,
  //     arrValueAttributeDoors as IConfigurationArray
  //   );
  // }, 1000);
  // setTimeout(() => {
    // moveOpeningsToWalls(
    //   arrWallsMovedToCenterCoordinates,
    //   arrValueAttributeOpenings as IConfigurationArray
    // );
  // }, 1000);

  updateVisibilityWallsAnimationFrame(arrWallsMovedToCenterCoordinates);
  
};

export const getAllWallsNode = () => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({
    name: `${NODES_THREEKIT.WALL_ITEM}*`,
  });
};
export const getAllWallsEvalNode = () => {
  //@ts-ignore
  return window.threekit.player.scene.getAll({
    name: `${NODES_THREEKIT.WALL_ITEM}*`,
    evalNode: true,
  });
};

const updateWall = (
  wallName: string,
  instanceIdMaterial: string,
  heightWall: number,
  translationWall: ICoordinates
) => {
  // setVisible(window.player.instanceId, wallName, false);
  setMaterialThreekit({
    name: wallName,
    value: { assetId: instanceIdMaterial },
  });
  setPolyMeshHeightThreekit({
    name: wallName,
    value: heightWall,
  });
  translationWall["y"] = heightWall / 2;
  setTranslationThreekit({
    name: wallName,
    //@ts-ignore
    value: translationWall,
  });
};

const updateFeatures = (
  objAttributeWall: any,
  isVisible: boolean
) => {
  const wallConfiguration = objAttributeWall["configuration"];
  const wallConnections = JSON.parse(String(wallConfiguration[ATTRIBUTE_NAMES.wallConnections]))
  wallConnections.forEach((featureName: string) => {
    const featureArrFromName = featureName.split(".");
    setVisible({name: `${featureArrFromName[0]}_${featureArrFromName[1]}`, value: isVisible});
  })
}

const updateBoundingBoxPlanes = (indxWall: number, isVisible: boolean) => {
  const regExpBoundingBoxPlanesNames = `plane_bounding_${indxWall}_*`;
  setVisibleAll({name: regExpBoundingBoxPlanesNames, value: isVisible});
}

const updateBoundingCeilingPlanes = (indxWall: number, isVisible: boolean) => {
  const regExpBoundingBoxPlanesNames = `${NODES_THREEKIT.PLANE_BOUNDING_CEILING}${indxWall}`;
  setVisible({name: regExpBoundingBoxPlanesNames, value: isVisible});
}

const updateVisibilityWalls = (objWallsFromCrossVector: WallsFromCrossVectorT, arrValueAttributeWalls: any) => {
  let idsActivePlanes: string[] = [];
  let arrNamesVisibleWalls: WallItemT[] = [];
  let arrNamesHiddenWalls: WallItemT[] = [];

  const arrKeysObjWallsFromCrossVector = Object.keys(
    objWallsFromCrossVector
  ) as Array<keyof typeof objWallsFromCrossVector>;

  arrKeysObjWallsFromCrossVector.forEach((wallName, indx: number) => {
    const camWorldPos = getPositionCamera();
    const nodeWall = getEvalNodeFromName(wallName);
    const wallIndex = getNumberNodeThreekitFromName(wallName);
    //@ts-ignore
    const height = arrValueAttributeWalls[wallIndex]["configuration"][
      ATTRIBUTE_NAMES.wallHeight
    ] as number;
    if (!!!nodeWall) return;
    const wallWorldPos =
      //@ts-ignore
      new window.threekit.player.THREE.Vector3().setFromMatrixPosition(
        //@ts-ignore
        nodeWall.worldTransform
      );

    const visibility = camWorldPos
      //@ts-ignore
      .sub(wallWorldPos)
      .normalize()
      .dot(objWallsFromCrossVector[wallName]);

    let currentTranslationWall = getTranslationThreekit({
      name: wallName,
    });

    if (visibility < 0) {
      updateWall(
        wallName,
        ASSET_ID_OPACITY_MATERIAL,
        HEIGHT_HIDDEN_WALL,
        currentTranslationWall
      );
      updateFeatures(
        arrValueAttributeWalls[wallIndex],
        false
      )
      updateBoundingBoxPlanes(wallIndex, false);
      updateBoundingCeilingPlanes(wallIndex, false);
      arrNamesHiddenWalls.push(wallName);
    } else {
      const instanceIdCurrentMaterial = getAssetIdCurrentMaterial();
      updateWall(
        wallName,
        instanceIdCurrentMaterial,
        height,
        currentTranslationWall
      );
      updateFeatures(
        arrValueAttributeWalls[wallIndex],
        true
      )
      updateBoundingBoxPlanes(wallIndex, true);
      updateBoundingCeilingPlanes(wallIndex, true);

      const wallNameNum = getNumberNodeThreekitFromName(wallName);
      const planeId = getNodeIdFromName(
        `${NODES_THREEKIT.PLANE_CABINETS_WALL}${wallNameNum}`
      );
      idsActivePlanes.push(planeId);
      arrNamesVisibleWalls.push(wallName);
    }
  });

  //@ts-ignore
  setMeshesLayoutContainer(NODES_THREEKIT.LAYOUT_CONTAINER_CABINET_WALL, idsActivePlanes);
  //@ts-ignore
  setMeshesLayoutContainer(NODES_THREEKIT.LAYOUT_CONTAINER_CABINET_BASE, idsActivePlanes);
  debouncedCheckLockTranslationCabinetsWall(arrNamesVisibleWalls, arrNamesHiddenWalls);
  debouncedCheckLockTranslationCabinetsBase(arrNamesVisibleWalls, arrNamesHiddenWalls);
}

/**
 * Приховує або показує стіни, що знаходяться перед камерою.
 * Запускається через requestAnimationFrame.
 * Перевіряє зміну позиції камери. Якщо позиції різні - виконується.
 *
 */
const updateVisibilityWallsAnimationFrame = (arrValueAttributeWalls: IConfigurationArray) => {
  let startPositionCamera = getPositionCamera();

  // ПРАВИЛЬНО працюючий JSON для правильної квадратної кімнати в центрі координат
  // const objWallsFromCrossVector2: { [key: string]: any } = {
  //   "wall_item_0": {
  //       "x": 0,
  //       "y": 0,
  //       "z": 1
  //   },
  //   "wall_item_1": {
  //       "x": -1,
  //       "y": 0,
  //       "z": 0
  //   },
  //   "wall_item_2": {
  //       "x": 0,
  //       "y": 0,
  //       "z": -1
  //   },
  //   "wall_item_3": {
  //       "x": 1,
  //       "y": 0,
  //       "z": 0
  //   }
  // }

  setTimeout(() => {
    updateVisibilityWalls(objWallsFromCrossVector, arrValueAttributeWalls);
  }, 300);

  let animationId = requestAnimationFrame(stepAnimation);
  function stepAnimation() {

    animationId = requestAnimationFrame(stepAnimation);

    const newPositionCamera = getPositionCamera();
    const isEqualCoordsCamera = isEqualCoords(
      startPositionCamera,
      newPositionCamera
    );
    if (isEqualCoordsCamera) return;

    updateVisibilityWalls(objWallsFromCrossVector, arrValueAttributeWalls);

    startPositionCamera = { ...newPositionCamera };
    
  };

};