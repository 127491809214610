import { ModelsName_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { getIdNodeThreekit, getInstanceIdAssetFromNullModel } from "../../utils/threekit/general/getFunctions";

export enum CONNECTORS_CABINET_ISLAND {
  LEFT = "connector_island_left",
  RIGHT = "connector_island_right",
  BACK_LEFT = "connector_island_back_left",
  BACK_RIGHT = "connector_island_back_right",
  LEFT_FRONT = "connector_island_left_front",
  RIGHT_FRONT = "connector_island_right_front",
}

export const getConnectorIdForCabinet = (
  nullName: ModelsName_NodesT,
  connectorName: CONNECTORS_CABINET_ISLAND
) => {
  const instanceId = getInstanceIdAssetFromNullModel({
    name: nullName
  });
  const nodeIdConnector = getIdNodeThreekit({
    from: instanceId,
    name: connectorName
  })
  return nodeIdConnector;
}

export const getConnectorConnected = (
  nullName: ModelsName_NodesT,
  connectorName: CONNECTORS_CABINET_ISLAND
): string | null => {
  const connectorId = getConnectorIdForCabinet(nullName, connectorName);
  //@ts-ignore
  return window.threekit.player.connectors.getConnected(connectorId)
}