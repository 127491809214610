// images
import Cabinet01 from "./../../assets/images/Cabinets/Cabinet_01.png"
import Cabinet02 from "./../../assets/images/Cabinets/Cabinet_02.png"
import Cabinet03 from "./../../assets/images/Cabinets/Cabinet_03.png"
import Cabinet04 from "./../../assets/images/Cabinets/Cabinet_04.png"
import Cabinet05 from "./../../assets/images/Cabinets/Cabinet_05.png"
import NotImage from "./../../assets/images/Cabinets/not_image.png";
// Cabinets Base Images
import Cabinet1001 from "./../../assets/images/Cabinets/Base_Cabinet/1001_12in_Single_Door_Base_Cabinet.png";
import Cabinet1002 from "./../../assets/images/Cabinets/Base_Cabinet/1002_18in_Single_Door_Base_Cabinet.png";
import Cabinet1003 from "./../../assets/images/Cabinets/Base_Cabinet/1003_18in_Single_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1004 from "./../../assets/images/Cabinets/Base_Cabinet/1004_18in_Three_Drawer_Base_Cabinet.png";
import Cabinet1005 from "./../../assets/images/Cabinets/Base_Cabinet/1005_24in_Two_Door_Base_Cabinet.png";
import Cabinet1006 from "./../../assets/images/Cabinets/Base_Cabinet/1006_24in_Two_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1007 from "./../../assets/images/Cabinets/Base_Cabinet/1007_24in_Three_Drawer_Base_Cabinet.png";
import Cabinet1008 from "./../../assets/images/Cabinets/Base_Cabinet/1008_30in_Two_Door_Base_Cabinet.png";
import Cabinet1009 from "./../../assets/images/Cabinets/Base_Cabinet/1009_30in_Two_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1010 from "./../../assets/images/Cabinets/Base_Cabinet/1010_30in_Three_Drawer_Base_Cabinet.png";
import Cabinet1011 from "./../../assets/images/Cabinets/Base_Cabinet/1011_30in_Two_Door_Sink_Base_Cabinet.png";
import Cabinet1012 from "./../../assets/images/Cabinets/Base_Cabinet/1012_36in_Two_Door_Base_Cabinet.png";
import Cabinet1013 from "./../../assets/images/Cabinets/Base_Cabinet/1013_36in_Two_Door_One_Drawer_Base_Cabinet.png";
import Cabinet1014 from "./../../assets/images/Cabinets/Base_Cabinet/1014_36in_Three_Drawer_Base_Cabinet.png";
import Cabinet1015 from "./../../assets/images/Cabinets/Base_Cabinet/1015_36in_Two_Door_Sink_Base_Cabinet.png";
import Cabinet1016_Left from "./../../assets/images/Cabinets/Base_Cabinet/1016_36in_Blind_Corner_Base_Cabinet_Left.png";
import Cabinet1016_Right from "./../../assets/images/Cabinets/Base_Cabinet/1016_36in_Blind_Corner_Base_Cabinet_Right.png";
import Cabinet1017 from "./../../assets/images/Cabinets/Base_Cabinet/1017_36in_Lazy_Suzan_Corner_Base_cabinet.png";

// Cabinets Wall Images
import Cabinet1018 from "./../../assets/images/Cabinets/Wall_Cabinet/1018_12in_Single_Door_Wall_Cabinet.png";
import Cabinet1019 from "./../../assets/images/Cabinets/Wall_Cabinet/1019_18in_Single_Door_Wall_Cabinet.png";
import Cabinet1020 from "./../../assets/images/Cabinets/Wall_Cabinet/1020_24in_Two_Door_Wall_Cabinet.png";
import Cabinet1021 from "./../../assets/images/Cabinets/Wall_Cabinet/1021_24in_Two_Door_Wall_Cabinet_36in_H.png";
import Cabinet1022 from "./../../assets/images/Cabinets/Wall_Cabinet/1022_24in_Corner_Wall_Cabinet.png";
import Cabinet1023 from "./../../assets/images/Cabinets/Wall_Cabinet/1023_24in_Corner_Wall_Cabinet_36in_H.png";
import Cabinet1024 from "./../../assets/images/Cabinets/Wall_Cabinet/1024_30in_Two_Door_Wall_Cabinet.png";
import Cabinet1025 from "./../../assets/images/Cabinets/Wall_Cabinet/1025_30in_Two_Door_Wall_Cabinet_36in_H.png";
import Cabinet1026 from "./../../assets/images/Cabinets/Wall_Cabinet/1026_30in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet.png";
import Cabinet1027 from "./../../assets/images/Cabinets/Wall_Cabinet/1027_30in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet_24in_D.png";
import Cabinet1028 from "./../../assets/images/Cabinets/Wall_Cabinet/1028_36in_Two_Door_Wall_Cabinet.png";
import Cabinet1029 from "./../../assets/images/Cabinets/Wall_Cabinet/1029__36in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet.png";
import Cabinet1030 from "./../../assets/images/Cabinets/Wall_Cabinet/1030_36in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet_24in_D.png";
import Cabinet1031 from "./../../assets/images/Cabinets/Wall_Cabinet/1031_18in_Single_Door_Upper_Pantry_Cabinet.png";
import Cabinet1032 from "./../../assets/images/Cabinets/Wall_Cabinet/1032_24in_Two_Door_Upper_Pantry_Cabinet.png";
import Cabinet1033 from "./../../assets/images/Cabinets/Wall_Cabinet/1033_30in_Two_Door_Above_the_Fridge_or_OTR_Wall_Cabinet_18in_H.png";

// Appliances
import Sink from "./../../assets/images/Appliances/Group/Sink.png"
import Refrigerator from "./../../assets/images/Appliances/Group/Refrigerator.png"
import Oven from "./../../assets/images/Appliances/Group/Oven.png"
import Dishwasher from "./../../assets/images/Appliances/Group/Dishwasher.png"

export type CategoryOptionImagesT = {
  framedWhite: string;
  framedGrey: string;
  framelessWhite: string;
  framelessGrey: string;
}

export interface CategoryOption {
  id: string,
  value: PRODUCT_GROUP_ALL_T;
  label: string;
  images: CategoryOptionImagesT;
}
export type GroupCabinetsOptionsPositionsT = {
  [key in PRODUCT_SECTION_GROUP_T]?: CategoryOption[]
}
export type GroupCabinetsOptionsT = {
  [key in PRODUCT_POSITIONS_KEYS]: GroupCabinetsOptionsPositionsT
}

export interface CabinetSizeI {
  width: number;
  depth: number;
  height: number;
}

export const enum PRODUCT_POSITIONS_KEYS {
  BASE_CABINET = "Cabinets Base",
  WALL_CABINET = "Cabinets Wall",
  ISLAND_CABINET = "Cabinets Island",
  APPLIANCES = "Appliances",
}

export enum PRODUCT_SECTION_GROUP_BASE {
  DOORS_ONLY = "Doors Only",
  DRAWERS_ONLY = "Drawers Only",
  DRAWER_AND_DOOR = "Drawer and Door",
  CORNER_UNITS = "Corner Units",
  SINK_UNITS = "Sink Units",
}
export enum PRODUCT_SECTION_GROUP_WALL {
  DOORS_ONLY = "Doors Only",
  CORNER_UNITS = "Corner Units",
  UPPER_PANTRY_UNIT = "Upper Pantry Unit",
  ABOVE_THE_FRIDGE_RANGE = "Above the Fridge/Range",
}
export enum PRODUCT_SECTION_GROUP_APPLIANCES {
  // SINK = "Sink",
  REFRIGERATOR = "Refrigerator",
  RANGE = "Range",
  DISHWASHER = "Dishwasher",
}
export const PRODUCT_SECTION_GROUP_ALL = { ...PRODUCT_SECTION_GROUP_BASE, ...PRODUCT_SECTION_GROUP_WALL }
export type PRODUCT_SECTION_GROUP_T = PRODUCT_SECTION_GROUP_BASE | PRODUCT_SECTION_GROUP_WALL | PRODUCT_SECTION_GROUP_APPLIANCES;
export type PRODUCT_SECTION_GROUP_ALL_T = PRODUCT_SECTION_GROUP_BASE | PRODUCT_SECTION_GROUP_WALL | PRODUCT_SECTION_GROUP_APPLIANCES | "All";



export enum PRODUCT_GROUP_BASE {
  ONE_DOOR = "1 Door",
  TWO_DOOR = "2 Door",
  ONE_DRIWER = "1 Drawer",
  TWO_DRIWER = "2 Drawer",
  THREE_DRIWER = "3 Drawer",
  ONE_DRIWER_ONE_DOOR = "1 Drawer, 1 Door",
  ONE_DRIWER_TWO_DOOR = "1 Drawer, 2 Door",
  TWO_DRIWER_TWO_DOOR = "2 Drawer, 2 Door",
  BLIND_CORNER = "Blind Corner",
  LAZY_SUSAN_CORNER = "Lazy Susan Corner",
  SINK_UNITS = "Sink Units",
}
export enum PRODUCT_GROUP_WALL {
  ONE_DOOR = "1 Door",
  TWO_DOOR = "2 Door",
  CORNER_UNITS = "Corner Units",
  UPPER_PANTRY_ONE_DOOR = "Upper Pantry 1 Door",
  UPPER_PANTRY_TWO_DOOR = "Upper Pantry 2 Door",
  ABOVE_THE_FRIDGE_RANGE = "Above the Fridge/Range",
}
export enum PRODUCT_GROUP_APPLIANCES {
  // SINK = "Sink",
  REFRIGERATOR = "Refrigerator",
  RANGE = "Range",
  DISHWASHER = "Dishwasher",
}
export const PRODUCT_GROUP_ALL = { ...PRODUCT_GROUP_BASE, ...PRODUCT_GROUP_WALL }
export type PRODUCT_GROUP_ALL_T = PRODUCT_GROUP_BASE | PRODUCT_GROUP_WALL | PRODUCT_GROUP_APPLIANCES;



export interface ProductI {
  id: string;
  position: PRODUCT_POSITIONS_KEYS;
  group: PRODUCT_GROUP_ALL_T;
  value: string;
  label: string;
  img: string;
  doorDirection?: string;
  size: CabinetSizeI;
  shelfs?: number;
  adjustableFeet?: number;
  sink?: boolean;
  disabled?: boolean;
}
export type ProductArrT = ProductI[];

export const groupCabinetsOptions: GroupCabinetsOptionsT = {
  [PRODUCT_POSITIONS_KEYS.BASE_CABINET]: {
    [PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DOOR,
        label: "1 Door",
        images: {
          // Cabinet1001,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-0366b29719ce092d6edceae6b0af293dd8c45d6890869b0a16720362791fe351",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-acd2c2a1796cd1abecdf0412046f9cf4acaf232cf06fe7cbcf277a831afaac4c",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-22644f1be30f14e18d5d057d46115666067f2c65b330bab6c94c06b107037621",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-ee66bd889fb93f004538ab8a3e2681eff3740ebe912c3637887db6be08471902",
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.TWO_DOOR,
        label: "2 Door",
        images: {
          // Cabinet1008,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-444be4e01bde9888988bafef7e489b66a3c0ae61c618897d9780b2fc7e925a1e",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-977981699e13b68ab10ab365777e38fa19b741d18277f47436e74ed4f2fc93dc",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-a6695c33991b01307498b699d5f18f7abcbba55ab9b459c5c73dbe543c43aa65",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-b37b17d55397bb502790dae2853cda00b60888e97007a0570b6ac273a22079b0",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY]: [
      // {
      //   id: Math.floor(100000 + Math.random() * 900000),
      //   value: PRODUCT_GROUP_BASE.ONE_DRIWER,
      //   label: "1 Drawer",
      //   img: Cabinet1004,
      // },
      // {
      //   id: Math.floor(100000 + Math.random() * 900000),
      //   value: PRODUCT_GROUP_BASE.TWO_DRIWER,
      //   label: "2 Drawer",
      //   img: Cabinet1004,
      // },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.THREE_DRIWER,
        label: "3 Drawer",
        images: {
          // Cabinet1004,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-abd76005f340516da01df033282f690e1db5f8a42bed1a951db411a17926b3f9",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d913ebba6b93c805ebcf521a1e69b834a131e54556128f48ccc21a0e827a35f0",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2b2b0b5d6bd0593dd793d49048741bc8f8175de3e0986660276fd8c1ed9f502c",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-000fa5287899fef28aa7e8295b7bd87ad54b25bb0fab5d78a431c968efb27341",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_ONE_DOOR,
        label: "1 Drawer, 1 Door",
        images: {
          // Cabinet1003,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-ab2a7d5d5d41727271ccc29c1afd1caf373433198f6b1813867f23c58a89326b",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-60365842fedb964916221ef7bf5ca588380488a08a7d1c4fd1f67e66d657c772",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-f0f2132bbfab956298a9fa010a25588ac0eea4f69b045838c7f5dec7453aae87",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d493740f83d419c4edcc99cbfde7962b6b1c89ff8a4acd9ac094169dac603c9d",
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
        label: "1 Drawer, 2 Doors",
        images: {
          // Cabinet1006,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d07ba4289bfe186ca9c10617dc1166746248397d1a7374e629367006cda0f843",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-c45b265d809ec50bfa3a381b295f82a530d1c5dbb88ff90b17398afa835a7adb",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-f7aa32bbb5e467f82aa4e0f8b5dc4351fda9b7080dd02ca86bff8e85f699ba7f",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d1a5a71b5661e04e7d31626744ebf7759a39ebe784a7438c4ab1d74c359bc26e",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.BLIND_CORNER,
        label: "Blind Corner",
        images: {
          // Cabinet1016_Left,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-df7c85ad56da8b198ebf0901687e834d11f438cdc14065ce45150937d8480b99",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-1e8a3233e200324f66ab904612b48ddffac8a1025f481820272304e0ffcacc62",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-799a3048362c87c3db3fcfcc6c2f9603f69ad028f777b4af430ecf1127dd4469",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2ff84c0bfea75ef3f8b43322c7f3e9ea3c571fc9e7bde7fdaa0d006a79a521b7",
        },
      },
      {
        id:String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.LAZY_SUSAN_CORNER,
        label: "Lazy Susan",
        images: {
          // Cabinet1017,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-673c5335415122d3fe31ab8a7ed6a9393a438dcb7da90aae203c3fb0aa04bf17",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-5e736834dada45278d8be40d2142d002b8aa07f874ffd9e044964da7d5889ef1",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-1c89a19278e987a67617ef851c224a42140b0bd12dcc2a1d13f5e4168e1e2754",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-5aecca5444d575893af0e6bdcc499aa82094a406e9f6903b7255768b0cd2cfa9",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.SINK_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.SINK_UNITS,
        label: "Sink Units",
        images: {
          // Cabinet1011,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-38913a9267d049849baa8b77bfd2776726edaee3de6362de94b1ca963128e792",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-13bea9555e0315fef119070b4d06c7454fb6e9bc4edd71fdf8af36de0a161acc",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-f19a5c7eff1f7dd0c1f320016e5d1d7308c4c37bd05280ccbe4f6533c371eaff",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-1eaebc78b5c8f3f024fb6d9baf7787aaabdefe0aa1b661010773f967aec0e2e6",
        },
      },
    ]
  },
  [PRODUCT_POSITIONS_KEYS.WALL_CABINET]: {
    [PRODUCT_SECTION_GROUP_WALL.DOORS_ONLY]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.ONE_DOOR,
        label: "1 Door",
        images: {
          // Cabinet1019,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2e97f267caa2181c16c9b8cc74bf053eb17fc05bb498206efff52f9298ad6b8d",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-59cc2b5c6667d5f14514f4b672fc9f79fd12e6b42128d68988f80946363542b3",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-a18c14d5277764053f5d93f433505a610adb7dcd4c8491ddc2019cbfe5f9c1f2",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-953582648b09ac7595bb6194d6065d9ca359e41d37e7370a438ddbf0fbe032e7",
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.TWO_DOOR,
        label: "2 Door",
        images: {
          // Cabinet1020,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-b640c2d625f179944ee7fe3588b4b6b54d9ffdbbd21a7ddef56cbcad3740bb7f",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-e3620fe0c4cc0d355f3c0b366688b6d6e236c8221fb226d6ef6d48c6dbc80b8a",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-bf5baabcbe9fb48ca7725728d68383cfebe693e5fa029eef3b009610521c8f26",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-6dec4d0f138e85315c34284ce4e5f6aaeb9ece5888a1c046f95354535dbd70fb",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_WALL.CORNER_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.CORNER_UNITS,
        label: "Corner Units",
        images: {
          // Cabinet1022,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-838f3e3130841de0e598545d70b09a5068bdc3c12ad83ba1d4639c45e84643da",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-45454117287932a1b5b8e2a7cd9bffb1455a91728bcd4ea9442eeacb0b3c8284",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-4626e913189f01fdabcbf6aa63015e977b39c89bc13792535c8d9653560b8518",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3419f6cd50a6dfbd44f3639a58315224d60a751b03ab5a8fd696284740431ab7",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_WALL.UPPER_PANTRY_UNIT]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.UPPER_PANTRY_ONE_DOOR,
        label: "Upper Pantry 1 Door",
        images: {
          // Cabinet1031,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-e03fe8d2ffa49ffe9c946cbf4c3b4e275cb2774f32f8c166e4f9e1cada256ddf",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-6d6fb2637ccf5c02a6c3ab03da0a351ec710ca868cc68361320c31c1c323597b",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-db2574dabdd368d0bb3905b183a181c75cc3771d9671a135fcad77c166c3c2e6",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-6e3e18ccfc13abc17a6327306e69b4ad4d488828175bd338fc4aee70e75d4263",
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.UPPER_PANTRY_TWO_DOOR,
        label: "Upper Pantry 2 Door",
        images: {
          // Cabinet1032,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-c6ee8c41a5e2ae3a9689a0e616359db56df1a704908446bacecadf0bdec66c7b",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-0d7b9863dc7efb83e98c4564995ca743920249cecda50b22276ffe9b99ea255a",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-679a89756b83c19eb5d1d7efc24df4a83ffe339ed9e603cc280b654f1a53d4c9",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-cfc462013886b0fcbfeada97b295d514a8252a24e51f412e32116251d3406a6f",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
        label: "Above the Fridge/Range",
        images: {
          // Cabinet1026,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-46028a90fcc4ffc0dfc077000f0e68a14a6f2dc70948d93387743716492e525b",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-abc0a298e589e21f30aaa0d2c13a8a56d3cb52794b8d1df12a3dd962e8ace0af",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-107d2f5bcb118e028ed2d3d274535ffb91099cd48582f46b9a969e32f755e19d",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-049c6b842b4858baccb4352ba24a00c40a992c8628aaa33899c61b54188bb654",
        },
      },
    ]
  },
  [PRODUCT_POSITIONS_KEYS.ISLAND_CABINET]: {
    [PRODUCT_SECTION_GROUP_BASE.DOORS_ONLY]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DOOR,
        label: "1 Door",
        images: {
          // Cabinet1001,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-0366b29719ce092d6edceae6b0af293dd8c45d6890869b0a16720362791fe351",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-acd2c2a1796cd1abecdf0412046f9cf4acaf232cf06fe7cbcf277a831afaac4c",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-22644f1be30f14e18d5d057d46115666067f2c65b330bab6c94c06b107037621",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-ee66bd889fb93f004538ab8a3e2681eff3740ebe912c3637887db6be08471902",
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.TWO_DOOR,
        label: "2 Door",
        images: {
          // Cabinet1008,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-444be4e01bde9888988bafef7e489b66a3c0ae61c618897d9780b2fc7e925a1e",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-977981699e13b68ab10ab365777e38fa19b741d18277f47436e74ed4f2fc93dc",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-a6695c33991b01307498b699d5f18f7abcbba55ab9b459c5c73dbe543c43aa65",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-b37b17d55397bb502790dae2853cda00b60888e97007a0570b6ac273a22079b0",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWERS_ONLY]: [
      // {
      //   id: Math.floor(100000 + Math.random() * 900000),
      //   value: PRODUCT_GROUP_BASE.ONE_DRIWER,
      //   label: "1 Drawer",
      //   img: Cabinet03,
      // },
      // {
      //   id: Math.floor(100000 + Math.random() * 900000),
      //   value: PRODUCT_GROUP_BASE.TWO_DRIWER,
      //   label: "2 Drawer",
      //   img: Cabinet03,
      // },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.THREE_DRIWER,
        label: "3 Drawer",
        images: {
          // Cabinet1004,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-abd76005f340516da01df033282f690e1db5f8a42bed1a951db411a17926b3f9",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d913ebba6b93c805ebcf521a1e69b834a131e54556128f48ccc21a0e827a35f0",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2b2b0b5d6bd0593dd793d49048741bc8f8175de3e0986660276fd8c1ed9f502c",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-000fa5287899fef28aa7e8295b7bd87ad54b25bb0fab5d78a431c968efb27341",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.DRAWER_AND_DOOR]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_ONE_DOOR,
        label: "1 Drawer, 1 Door",
        images: {
          // Cabinet1003,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-ab2a7d5d5d41727271ccc29c1afd1caf373433198f6b1813867f23c58a89326b",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-60365842fedb964916221ef7bf5ca588380488a08a7d1c4fd1f67e66d657c772",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-f0f2132bbfab956298a9fa010a25588ac0eea4f69b045838c7f5dec7453aae87",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d493740f83d419c4edcc99cbfde7962b6b1c89ff8a4acd9ac094169dac603c9d",
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
        label: "1 Drawer, 2 Doors",
        images: {
          // Cabinet1006,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d07ba4289bfe186ca9c10617dc1166746248397d1a7374e629367006cda0f843",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-c45b265d809ec50bfa3a381b295f82a530d1c5dbb88ff90b17398afa835a7adb",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-f7aa32bbb5e467f82aa4e0f8b5dc4351fda9b7080dd02ca86bff8e85f699ba7f",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-d1a5a71b5661e04e7d31626744ebf7759a39ebe784a7438c4ab1d74c359bc26e",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.CORNER_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.BLIND_CORNER,
        label: "Blind Corner",
        images: {
          // Cabinet1016_Left,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-df7c85ad56da8b198ebf0901687e834d11f438cdc14065ce45150937d8480b99",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-1e8a3233e200324f66ab904612b48ddffac8a1025f481820272304e0ffcacc62",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-799a3048362c87c3db3fcfcc6c2f9603f69ad028f777b4af430ecf1127dd4469",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2ff84c0bfea75ef3f8b43322c7f3e9ea3c571fc9e7bde7fdaa0d006a79a521b7",
        },
      },
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.LAZY_SUSAN_CORNER,
        label: "Lazy Susan",
        images: {
          // Cabinet1017,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-673c5335415122d3fe31ab8a7ed6a9393a438dcb7da90aae203c3fb0aa04bf17",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-5e736834dada45278d8be40d2142d002b8aa07f874ffd9e044964da7d5889ef1",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-1c89a19278e987a67617ef851c224a42140b0bd12dcc2a1d13f5e4168e1e2754",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-5aecca5444d575893af0e6bdcc499aa82094a406e9f6903b7255768b0cd2cfa9",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_BASE.SINK_UNITS]: [
      {
        id: String(Math.floor(100000 + Math.random() * 900000)),
        value: PRODUCT_GROUP_BASE.SINK_UNITS,
        label: "Sink Units",
        images: {
          // Cabinet1011,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-38913a9267d049849baa8b77bfd2776726edaee3de6362de94b1ca963128e792",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-13bea9555e0315fef119070b4d06c7454fb6e9bc4edd71fdf8af36de0a161acc",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-f19a5c7eff1f7dd0c1f320016e5d1d7308c4c37bd05280ccbe4f6533c371eaff",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-1eaebc78b5c8f3f024fb6d9baf7787aaabdefe0aa1b661010773f967aec0e2e6",
        },
      },
    ]
  },
  [PRODUCT_POSITIONS_KEYS.APPLIANCES]: {
    // [PRODUCT_SECTION_GROUP_APPLIANCES.SINK]: [
    //   {
    //     // id: 4011,
    //     id: "sink 25",
    //     value: PRODUCT_GROUP_APPLIANCES.SINK,
    //     label: "25\"",
    //     img: Sink,
          // images: {
          //   // Sink,
          //   framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
          //   framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
          //   framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
          //   framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-2e55d49a95ab54b0c927837118b2f65edc07bb2a29ef381b522593a714af2487",
          // },
    //   },
    //   {
    //     // id: 4012,
    //     id: "sink 33",
    //     value: PRODUCT_GROUP_APPLIANCES.SINK,
    //     label: "33\"",
          // images: {
          //   // Sink,
          //   framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
          //   framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
          //   framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
          //   framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3fb17d467c708b939dcaf141b73ef54dc613c6751282fd826a3f04f11cc54317",
          // },
    //   },
    // ],
    [PRODUCT_SECTION_GROUP_APPLIANCES.REFRIGERATOR]: [
      {
        id: String(4021),
        value: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
        label: "30\"",
        images: {
          // Refrigerator,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-7957c47b1d3c506794ba373f8b2184fc9c8facb9608602bb6c82ab3b062f4f82",
        },
      },
      {
        id: String(4022),
        value: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
        label: "36\"",
        images: {
          // Refrigerator,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-4ed8584d9b0fcd6c08611e7760e11e6299a0baf822dbbe3476155a1087815d96",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_APPLIANCES.DISHWASHER]: [
      {
        id: String(4031),
        value: PRODUCT_GROUP_APPLIANCES.DISHWASHER,
        label: "24\"",
        images: {
          // Dishwasher,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-145eb067882b1f8450c470f714ebb30b47ca062dbdcd452906f26bcd9a1fb91a",
        },
      },
    ],
    [PRODUCT_SECTION_GROUP_APPLIANCES.RANGE]: [
      {
        id: String(4041),
        value: PRODUCT_GROUP_APPLIANCES.RANGE,
        label: "30\"",
        images: {
          // Oven,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-64e58cb579cbeb97524f553c94162ddd89921e527183b6f2ec04f5f79604befd",
        },
      },
      {
        id: String(4042),
        value: PRODUCT_GROUP_APPLIANCES.RANGE,
        label: "36\"",
        images: {
          // Oven,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-92e9cf8111b7f6a671da7c1bb7422989f06c410cb6f63c0219f09f760e577347",
        },
      },
      {
        id: String(4043),
        value: PRODUCT_GROUP_APPLIANCES.RANGE,
        label: "48\"",
        images: {
          // Oven,
          framedWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8",
          framedGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8",
          framelessWhite: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8",
          framelessGrey: "https://preview.threekit.com/api/images/webp/power2-4096/preview.threekit.com/api/files/hash/sha256-3f1336b3f6ef7a63d79f3fee64bcb7d2571b62fb9ddae9d64441804a2d0ca6c8",
        },
      },
    ],
  }
}

export const ProductsBaseCabinet: ProductArrT = [
  {
    id: "1001",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DOOR,
    value: '12" Single Door Base Cabinet',
    label: '12" Single Door Base Cabinet',
    img: Cabinet1001,
    doorDirection: "Open Left",
    size: {
      width: 12,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1002",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DOOR,
    value: '18" Single Door Base Cabinet',
    label: '18" Single Door Base Cabinet',
    img: Cabinet1002,
    doorDirection: "Open Left",
    size: {
      width: 18,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1003",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_ONE_DOOR,
    value: '18" Single Door, One Drawer Base Cabinet',
    label: '18" Single Door, One Drawer Base Cabinet',
    img: Cabinet1003,
    doorDirection: "Open Left",
    size: {
      width: 18,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1004",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '18" Three Drawer Base Cabinet',
    label: '18" Three Drawer Base Cabinet',
    img: Cabinet1004,
    size: {
      width: 18,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },
  {
    id: "1005",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DOOR,
    value: '24" Two Door Base Cabinet',
    label: '24" Two Door Base Cabinet',
    img: Cabinet1005,
    size: {
      width: 24,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1006",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
    value: '24" Two Door, One Drawer Base Cabinet',
    label: '24" Two Door, One Drawer Base Cabinet',
    img: Cabinet1006,
    size: {
      width: 24,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1007",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '24" Three Drawer Base Cabinet',
    label: '24" Three Drawer Base Cabinet',
    img: Cabinet1007,
    size: {
      width: 24,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },
  {
    id: "1008",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DOOR,
    value: '30" Two Door Base Cabinet',
    label: '30" Two Door Base Cabinet',
    img: Cabinet1008,
    size: {
      width: 30,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1009",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
    value: '30" Two Door, One Drawer Base Cabinet',
    label: '30" Two Door, One Drawer Base Cabinet',
    img: Cabinet1009,
    size: {
      width: 30,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1010",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '30" Three Drawer Base Cabinet',
    label: '30" Three Drawer Base Cabinet',
    img: Cabinet1010,
    size: {
      width: 30,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },
  {
    id: "1011",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.SINK_UNITS,
    value: '30" Two Door Sink Base Cabinet',
    label: '30" Two Door Sink Base Cabinet',
    img: Cabinet1011,
    size: {
      width: 30,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
    sink: true,
  },
  {
    id: "1012",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.TWO_DOOR,
    value: '36" Two Door Base Cabinet',
    label: '36" Two Door Base Cabinet',
    img: Cabinet1012,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1013",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.ONE_DRIWER_TWO_DOOR,
    value: '36" Two Door, One Drawer Base Cabinet',
    label: '36" Two Door, One Drawer Base Cabinet',
    img: Cabinet1013,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1014",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.THREE_DRIWER,
    value: '36" Three Drawer Base Cabinet',
    label: '36" Three Drawer Base Cabinet',
    img: Cabinet1014,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
  },
  {
    id: "1015",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.SINK_UNITS,
    value: '36" Two Door Sink Base Cabinet',
    label: '36" Two Door Sink Base Cabinet',
    img: Cabinet1015,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 0,
    adjustableFeet: 4,
    sink: true,
  },
  {
    id: "1016_1",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.BLIND_CORNER,
    value: '36" Blind Corner Base Cabinet Left',
    label: '36" Blind Corner Base Cabinet Left',
    img: Cabinet1016_Left,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1016_2",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.BLIND_CORNER,
    value: '36" Blind Corner Base Cabinet Right',
    label: '36" Blind Corner Base Cabinet Right',
    img: Cabinet1016_Right,
    size: {
      width: 36,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
  {
    id: "1017",
    position: PRODUCT_POSITIONS_KEYS.BASE_CABINET,
    group: PRODUCT_GROUP_BASE.LAZY_SUSAN_CORNER,
    value: '36" Lazy Suzan Corner Base Cabinet',
    label: '36" Lazy Suzan Corner Base Cabinet',
    img: Cabinet1017,
    size: {
      width: 36,
      depth: 36,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 4,
  },
]

export const ProductsWallCabinet: ProductArrT = [
  {
    id: "1018",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '12" Single Door Wall Cabinet',
    label: '12" Single Door Wall Cabinet',
    img: Cabinet1018,
    doorDirection: "Open Left",
    size: {
      width: 12,
      depth: 13,
      height: 30,
    },
    shelfs: 1,
  },
  {
    id: "1019",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ONE_DOOR,
    value: '18" Single Door Wall Cabinet',
    label: '18" Single Door Wall Cabinet',
    img: Cabinet1019,
    doorDirection: "Open Left",
    size: {
      width: 18,
      depth: 13,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "1020",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '24" Two Door Wall Cabinet',
    label: '24" Two Door Wall Cabinet',
    img: Cabinet1020,
    size: {
      width: 24,
      depth: 13,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "1021",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '24" Two Door Wall Cabinet (36" H)',
    label: '24" Two Door Wall Cabinet (36" H)',
    img: Cabinet1021,
    size: {
      width: 24,
      depth: 13,
      height: 36,
    },
    shelfs: 2,
    adjustableFeet: 0,
  },
  {
    id: "1022",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.CORNER_UNITS,
    value: '24" Corner Wall Cabinet',
    label: '24" Corner Wall Cabinet',
    img: Cabinet1022,
    size: {
      width: 24,
      depth: 24,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "1023",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.CORNER_UNITS,
    value: '24" Corner Wall Cabinet (36" H)',
    label: '24" Corner Wall Cabinet (36" H)',
    img: Cabinet1023,
    size: {
      width: 24,
      depth: 24,
      height: 36,
    },
    shelfs: 2,
    adjustableFeet: 0,
  },
  {
    id: "1024",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '30" Two Door Wall Cabinet',
    label: '30" Two Door Wall Cabinet',
    img: Cabinet1024,
    size: {
      width: 30,
      depth: 13,
      height: 30,
    },
    shelfs: 1,
    adjustableFeet: 0,
  },
  {
    id: "1025",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '30" Two Door Wall Cabinet (36" H)',
    label: '30" Two Door Wall Cabinet (36" H)',
    img: Cabinet1025,
    size: {
      width: 30,
      depth: 13,
      height: 36,
    },
    shelfs: 2,
    adjustableFeet: 0,
  },
  {
    id: "1026",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Above the Fridge or OTR Wall Cabinet',
    label: '30" Two Door Above the Fridge or OTR Wall Cabinet',
    img: Cabinet1026,
    size: {
      width: 30,
      depth: 13,
      height: 15,
    },
    shelfs: 0,
  },
  {
    id: "1027",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Above the Fridge or OTR Wall Cabinet (24"D)',
    label: '30" Two Door Above the Fridge or OTR Wall Cabinet (24"D)',
    img: Cabinet1027,
    size: {
      width: 30,
      depth: 25,
      height: 15,
    },
    shelfs: 0,
  },
  {
    id: "1033",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '30" Two Door Above the Fridge or OTR Wall Cabinet (18"H)',
    label: '30" Two Door Above the Fridge or OTR Wall Cabinet (18"H)',
    img: Cabinet1033,
    size: {
      width: 30,
      depth: 25,
      height: 18,
    },
    shelfs: 0,
  },
  {
    id: "1028",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.TWO_DOOR,
    value: '36" Two Door Wall Cabinet',
    label: '36" Two Door Wall Cabinet',
    img: Cabinet1028,
    size: {
      width: 36,
      depth: 13,
      height: 30,
    },
    shelfs: 2,
  },
  {
    id: "1029",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Above the Fridge or OTR Wall Cabinet',
    label: '36" Two Door Above the Fridge or OTR Wall Cabinet',
    img: Cabinet1029,
    size: {
      width: 36,
      depth: 13,
      height: 15,
    },
    shelfs: 0,
  },
  {
    id: "1030",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Above the Fridge or OTR Wall Cabinet (24"D)',
    label: '36" Two Door Above the Fridge or OTR Wall Cabinet (24"D)',
    img: Cabinet1030,
    size: {
      width: 36,
      depth: 25,
      height: 15,
    },
    shelfs: 0,
  },
  {
    id: "1034",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.ABOVE_THE_FRIDGE_RANGE,
    value: '36" Two Door Above the Fridge or OTR Wall Cabinet (18"H)',
    label: '36" Two Door Above the Fridge or OTR Wall Cabinet (18"H)',
    img: NotImage,
    size: {
      width: 36,
      depth: 25,
      height: 18,
    },
    shelfs: 0,
  },
  {
    id: "1031",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.UPPER_PANTRY_ONE_DOOR,
    value: '18" Single Door Upper Pantry Cabinet',
    label: '18" Single Door Upper Pantry Cabinet',
    img: Cabinet1031,
    size: {
      width: 18,
      depth: 24,
      height: 55.5,
    },
    shelfs: 3,
  },
  {
    id: "1032",
    position: PRODUCT_POSITIONS_KEYS.WALL_CABINET,
    group: PRODUCT_GROUP_WALL.UPPER_PANTRY_TWO_DOOR,
    value: '24" Two Door Upper Pantry Cabinet',
    label: '24" Two Door Upper Pantry Cabinet',
    img: Cabinet1032,
    size: {
      width: 24,
      depth: 24,
      height: 55.5,
    },
    shelfs: 3,
  },
]

export interface ProductOtherI {
  id: string;
  position: string;
  group: string;
  value: string;
  label: string;
  img: string;
  size: CabinetSizeI;
}
export type ProductOtherT = ProductOtherI[];
export const ProductsOther: ProductOtherT = [
  {
    id: "1101",
    position: "Island Back Panel",
    group: "",
    value: '12" Island Back / Deco Side Panel',
    label: '12" Island Back / Deco Side Panel',
    img: Cabinet01,
    size: {
      width: 12,
      depth: 0.75,
      height: 30,
    },
  },
  {
    id: "1102",
    position: "Island Back Panel",
    group: "",
    value: '18" Island Back / Deco Side Panel',
    label: '18" Island Back / Deco Side Panel',
    img: Cabinet01,
    size: {
      width: 18,
      depth: 0.75,
      height: 30,
    },
  },
  {
    id: "1103",
    position: "Island Back Panel",
    group: "",
    value: '24" Island Back / Deco Side Panel',
    label: '24" Island Back / Deco Side Panel',
    img: Cabinet01,
    size: {
      width: 24,
      depth: 0.75,
      height: 30,
    },
  },
  {
    id: "1105",
    position: "Pantry / Fridge Cabinet End Panel",
    group: "",
    value: '24"D x 43"H Pantry/Fridge Side Panels (2pk)',
    label: '24"D x 43"H Pantry/Fridge Side Panels (2pk)',
    img: Cabinet01,
    size: {
      width: 24,
      depth: 0.75,
      height: 42.75,
    },
  },
  {
    id: "1106",
    position: "Fridge End Panel",
    group: "",
    value: '25" Fridge Side Panel',
    label: '25" Fridge Side Panel',
    img: Cabinet01,
    size: {
      width: 0.75,
      depth: 25,
      height: 90,
    },
  },
  {
    id: "1107",
    position: "Base Cabinet and Island End Panel",
    group: "",
    value: '24"D x 34"H Base Cabinet / Dishwasher / Island End Panel (Flat Panel)',
    label: '24"D x 34"H Base Cabinet / Dishwasher / Island End Panel (Flat Panel)',
    img: Cabinet01,
    size: {
      width: 0.75,
      depth: 25,
      height: 35.5,
    },
  },
  {
    id: "1108",
    position: "Panels & Access",
    group: "",
    value: '3" Crown Moulding',
    label: '3" Crown Moulding',
    img: Cabinet01,
    size: {
      width: 3,
      depth: 1.5,
      height: 96,
    },
  },
  {
    id: "1112",
    position: "Panels & Access",
    group: "",
    value: '6" Crown Moulding',
    label: '6" Crown Moulding',
    img: Cabinet01,
    size: {
      width: 6,
      depth: 1.5,
      height: 96,
    },
  },
  {
    id: "1109",
    position: "Panels & Access",
    group: "",
    value: '3/4" Under Cabinet Moulding',
    label: '3/4" Under Cabinet Moulding',
    img: Cabinet01,
    size: {
      width: 0.75,
      depth: 1.5,
      height: 96,
    },
  },
  {
    id: "1110",
    position: "Panels & Access",
    group: "",
    value: '3" Filler Strip',
    label: '3" Filler Strip',
    img: Cabinet01,
    size: {
      width: 3,
      depth: 0.75,
      height: 48,
    },
  },
  {
    id: "1111",
    position: "Panels & Access",
    group: "",
    value: '4.5" Toe Kick',
    label: '4.5" Toe Kick',
    img: Cabinet01,
    size: {
      width: 4.5,
      depth: 0.625,
      height: 96,
    },
  },
]

export const ProductsAppliances: ProductArrT = [
  // {
  //   id: "4011",
  //   position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
  //   group: PRODUCT_GROUP_APPLIANCES.SINK,
  //   value: '25" Stainless Single Bowl Sink',
  //   label: '25" Stainless Single Bowl Sink',
  //   img: Sink,
  //   size: {
  //     width: 25,
  //     depth: 22,
  //     height: 0,
  //   },
  // },
  // {
  //   id: "4012",
  //   position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
  //   group: PRODUCT_GROUP_APPLIANCES.SINK,
  //   value: '33" Stainless Single Bowl Sink',
  //   label: '33" Stainless Single Bowl Sink',
  //   img: Sink,
  //   size: {
  //     width: 33,
  //     depth: 22,
  //     height: 0,
  //   },
  // },

  {
    id: "4021",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
    value: '30" Fridge',
    label: '30" Fridge',
    img: Refrigerator,
    size: {
      width: 30,
      depth: 31,
      height: 70,
    },
  },
  {
    id: "4022",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.REFRIGERATOR,
    value: '36" Fridge',
    label: '36" Fridge',
    img: Refrigerator,
    size: {
      width: 36,
      depth: 35,
      height: 70,
    },
  },

  {
    id: "4031",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.DISHWASHER,
    value: '24" Dichwasher',
    label: '24" Dichwasher',
    img: Dishwasher,
    size: {
      width: 24,
      depth: 24,
      height: 34,
    },
  },

  {
    id: "4041",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.RANGE,
    value: '30" Range',
    label: '30" Range',
    img: Oven,
    size: {
      width: 30,
      depth: 24,
      height: 36,
    },
  },
  {
    id: "4042",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.RANGE,
    value: '36" Range',
    label: '36" Range',
    img: Oven,
    size: {
      width: 36,
      depth: 24,
      height: 36,
    },
  },
  {
    id: "4043",
    position: PRODUCT_POSITIONS_KEYS.APPLIANCES,
    group: PRODUCT_GROUP_APPLIANCES.RANGE,
    value: '48" Range',
    label: '48" Range',
    img: Oven,
    size: {
      width: 48,
      depth: 24,
      height: 36,
    },
  },
]

export const ProductsList: ProductArrT = [
  ...ProductsBaseCabinet,
  ...ProductsWallCabinet,
  ...ProductsAppliances,
]