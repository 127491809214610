import { ICoordinates, IMetadata } from "@threekit-tools/treble/dist/types";
import {
  ModelCabinetWallT,
  ModelsName_NodesT,
  NODES_THREEKIT,
} from "../../../utils/constants/nodesNamesThreekit";
import { getBoxDepthThreekit, getItemNodeFromNullModel, getValuesMetadataItemFromNullModel } from "../../../utils/threekit/general/getFunctions";
import {
  getWallNameFromMaxDistanceInCorner,
  getCornerDistanceSorted,
} from "../../../utils/threekit/tools/toolsDragCabinetsBase/generalFunc";
import { isEqualCoordsTolerance } from "../addCornerModelBase";
import { checkIfCornerCabinet, getExtremePointsForAllModelsWall, getExtremePointsForModelWall, ObjExtremePointsWallT } from "./extremePoints";
import { getSizeBoxForAllCabinetsWall, getSizeFullForAllCabinetsWall, ObjSizeCabinetsWallT } from "./size";
import { ArrWallRangesT, WallRangeT, getIntervalsWallCabinetsForAllWalls, getSizeModelRelativeTransform } from "../../intervals/getIntervalsInfoOnWall";
import { checkModelPositionInCorner, getModelPositionFromName } from "../../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";
import { checkIntersectModelFromModel } from "../IntersectsModelsBox";

/**
 * Функція визначає тип коннектингу для лівої сторони.
 *
 * @param {number} diffDepthSize Різниця в розмірі Depth для сусідніх шкафів зліва.
 * @returns {TypeConnectingTopMouldingT} Тип коннектингу для лівої сторони.
 */
const getTypeConnectingLeft = (diffDepthSize: number, isCornerCabinet: boolean): TypeConnectingTopMouldingT => {
  if (isCornerCabinet) {
    return "left";
  }

  if (diffDepthSize < -0.1) {
    return "partialBigLeft";
  } else if (diffDepthSize > 0.1) {
    return "partialSmallLeft";
  } else {
    return "left";
  }
}

/**
 * Функція визначає тип коннектингу для правої сторони.
 *
 * @param {number} diffDepthSize Різниця в розмірі Depth для сусідніх шкафів справа.
 * @returns {TypeConnectingTopMouldingT} Тип коннектингу для правої сторони.
 */
const getTypeConnectingRight = (diffDepthSize: number, isCornerCabinet: boolean): TypeConnectingTopMouldingT => {
  if (isCornerCabinet) {
    return "right"
  }

  if (diffDepthSize < -0.1) {
    return "partialBigRight";
  } else if (diffDepthSize > 0.1) {
    return "partialSmallRight";
  } else {
    return "right";
  }
}

type TypeConnectingInCornerT = "left" | "right" | undefined;
/**
 * Функція визначає чи знаходиться модель в куті.
 * Та повертає тип коннектингу для сторони в куті.
 *
 * @param {ModelCabinetWallT} modelNullNameCurrent Null Name для настінної моделі.
 * @param {ICoordinates} sizeCurrentModel Розмір моделі.
 * @returns {TypeConnectingInCornerT} Тип коннектингу для сторони в куті.
 */
export const checkTypeConnectingInCorner = (
  modelNullNameCurrent: ModelCabinetWallT,
  sizeCurrentModel: ICoordinates
): TypeConnectingInCornerT => {
  // Перевіряємо чи не є модель в куті.
  // Чи не притиснута вона боковою стінкою до сусідньої кутової стіни.
  let corner: TypeConnectingInCornerT = undefined;
  const cornerDistance = checkModelPositionInCorner(modelNullNameCurrent);
  if (!!cornerDistance) {
    const maxDistanceWallName =
      getWallNameFromMaxDistanceInCorner(cornerDistance);
    const cornerWallsSortedLeftRight = getCornerDistanceSorted(cornerDistance);
    const wallDepth = getBoxDepthThreekit({ name: maxDistanceWallName });

    if (
      cornerDistance[maxDistanceWallName]["distanceFront"] <
      sizeCurrentModel["x"] / 2 + wallDepth / 2 + 0.05
    ) {
      if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
        corner = "left";
      }

      if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
        corner = "right";
      }
    }
  }
  return corner;
};

/**
 * Функція визначає для однієї настінної моделі(шкафа) тип коннектинга з сусідніми шкафами.
 * Шукає тип коннектингу по верхньому краю шкафів.
 *
 * @param {ModelCabinetWallT} modelNullNameCurrent Null Name для настінної моделі(шкафа).
 * @param {ObjExtremePointsWallT} objExtremePointsForModels Об'ект з інформацією про крайні точки для всіх настінних моделей на сцені.
 * @param {ObjSizeCabinetsWallT} objSizeForAllModelsWall Об'ект з інформацією про розміри всіх встановлених на сцені настінних моделей.
 * @returns {ObjTypeConnectingTopMouldingT} Массив, що описує типи коннектинга обраної моделі з сусідніми шкафами.
 */
const getTypeConnectingTopForModel = (
  modelNullNameCurrent: ModelCabinetWallT,
  objExtremePointsForModels: ObjExtremePointsWallT,
  objSizeForAllModelsWall: ObjSizeCabinetsWallT,
): ArrTypeConnectingTopMouldingT => {

  const objExtremePointsForModel = getExtremePointsForModelWall(modelNullNameCurrent);
  const modelSizeCurrent = getSizeModelRelativeTransform(modelNullNameCurrent);
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: modelNullNameCurrent});
  const isCornerCabinet = checkIfCornerCabinet(itemNodeFromNullModel);
  const extremePointsForModelsKeys = Object.keys(
    objExtremePointsForModels
  ) as Array<keyof typeof objExtremePointsForModels>;

  let arrTypeConnecting: ArrTypeConnectingTopMouldingT = [];
  extremePointsForModelsKeys.forEach((modelNullName) => {
    if (modelNullName !== modelNullNameCurrent) {
      const { leftExtremePoint, rightExtremePoint } =
        objExtremePointsForModels[modelNullName];
      const size = objSizeForAllModelsWall[modelNullName];
      const diffDepthSize = modelSizeCurrent["z"] - size["z"];
      const isEqualCoordsLeft = isEqualCoordsTolerance(
        { ...objExtremePointsForModel["leftExtremePoint"], y: 0 },
        { ...rightExtremePoint, y: 0 },
        0.1
      );
      const isEqualCoordsRight = isEqualCoordsTolerance(
        { ...objExtremePointsForModel["rightExtremePoint"], y: 0 },
        { ...leftExtremePoint, y: 0 },
        0.1
      );

      if (isEqualCoordsLeft) {
        arrTypeConnecting.push(getTypeConnectingLeft(diffDepthSize, isCornerCabinet));
      }

      if (isEqualCoordsRight) {
        arrTypeConnecting.push(getTypeConnectingRight(diffDepthSize, isCornerCabinet));
      }
    }
  });

  // check Corner
  const cornerTypeConnecting = checkTypeConnectingInCorner(
    modelNullNameCurrent,
    modelSizeCurrent
  );
  if (
    !!cornerTypeConnecting &&
    !arrTypeConnecting.includes(cornerTypeConnecting) &&
    !isCornerCabinet
  ) {
    arrTypeConnecting.push(cornerTypeConnecting);
  }

  return arrTypeConnecting;
};

/**
 * Функція яка шукає всі проміжки які дотикаються або перетинаються з обраною моделлю.
 *
 * @param {ArrWallRangesT} intervals массив всіх проміжків(заповнених та пустих) на стіні.
 * @param {WallRangeT} objRangeModelCurrent об'єкт, що описує проміжок моделі, для якої шукаємо перетини з іншими проміжками.
 * @returns {ArrWallRangesT} Массив проміжків, які дотикаються або перетинаються з обраною моделлю.
 */
export function findMatchingIntervals(intervals: ArrWallRangesT, objRangeModelCurrent: WallRangeT): ArrWallRangesT {
  const matchingIntervals: ArrWallRangesT = [];

  intervals.forEach((objWallRange: WallRangeT) => {
    const { empty, range, name } = objWallRange;
    const currentRange = objRangeModelCurrent["range"];

    if (empty || name === undefined || name === objRangeModelCurrent["name"]) return;

    if (
      (currentRange[0] <= range[1] && currentRange[1] >= range[0]) ||
      (Math.abs(currentRange[0] - range[1]) <= 0.01) ||
      (Math.abs(currentRange[1] - range[0]) <= 0.01)
    ) {
      matchingIntervals.push(objWallRange);
    }
    
  })

  return matchingIntervals;
}

/**
 * Функція визначає для однієї настінної моделі(шкафа) тип коннектинга з сусідніми шкафами.
 * Шукає тип коннектингу по верхньому краю шкафів.
 * Тип коннектингу шукається за допомогою інформації про інтервали на стінах, які отримуються з функції getIntervalsWallCabinetsForAllWalls.
 *
 * @param {ModelCabinetWallT} modelNullNameCurrent Null Name для настінної моделі(шкафа).
 * @returns {ArrTypeConnectingTopMouldingT} Массив, що описує типи коннектинга обраної моделі з сусідніми шкафами.
 */
const getTypeConnectingTopForModelFromIntervals = (
  modelNullNameCurrent: ModelCabinetWallT,
): ArrTypeConnectingTopMouldingT => {

  let arrTypeConnecting: ArrTypeConnectingTopMouldingT = [];
  const intervalsWallCabinetsForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: modelNullNameCurrent});
  const isCornerCabinet = checkIfCornerCabinet(itemNodeFromNullModel);
  const positionModelCurrent = getTranslationThreekit({name: modelNullNameCurrent});
  const sizeModelCurrent = getSizeModelBoxFromAssetCabinetWall(modelNullNameCurrent);

  // для моделі modelNullNameCurrent знайти массив проміжків які знаходяться на стіні разом з моделлю
  // Знайти об'єкт для проміжоку обраної моделі modelNullNameCurrent
  Object.values(intervalsWallCabinetsForAllWalls).forEach((arrWallRanges: ArrWallRangesT) => {
    let indicatorIncludesModel: boolean = false;
    let objRangeModelCurrent: WallRangeT | undefined;
    arrWallRanges.forEach((objWallRange: WallRangeT) => {
      if (objWallRange.hasOwnProperty("name") && objWallRange["name"] === modelNullNameCurrent) {
        indicatorIncludesModel = true;
        objRangeModelCurrent = objWallRange;
      }
    })

    if (!indicatorIncludesModel || objRangeModelCurrent === undefined) return;

    const arrIntervalsForWall: ArrWallRangesT = arrWallRanges;

    // Знайти всі проміжки, які дотикаються або перетинаються з обраною моделлю modelNullNameCurrent
    const  matchingIntervals = findMatchingIntervals(arrIntervalsForWall, objRangeModelCurrent);

    // знаходимо позицію по Y для обраної моделі
    // шукаємо верхню та нижню точки по Y
    const posYBottomModelCurrent = positionModelCurrent["y"];
    const posYTopModelCurrent = positionModelCurrent["y"] + sizeModelCurrent["y"];

    // позиція обраної моделі вздовж стіни, на якій вона розташована
    const posXLeftModelCurrent = objRangeModelCurrent["range"][0];
    const posXRightModelCurrent = objRangeModelCurrent["range"][1];

    // шукаємо тип з'єднання обраної моделі з іншими моделями
    let left: boolean = false;
    let center: boolean = false;
    let right: boolean = false;
    matchingIntervals.forEach((objWallRange: WallRangeT) => {
      const { empty, range, name } = objWallRange;
      if (!!name) {
        
        const positionNeaborModel = getTranslationThreekit({name: name});
        const sizeNeaborModel = getSizeModelBoxFromAssetCabinetWall(name);
        const posXLeftNeaborModel = range[0];
        const posXRightNeaborModel = range[1];
        const posYBottomNeaborModel = positionNeaborModel["y"];
        const posYTopNeaborModel = positionNeaborModel["y"] + sizeNeaborModel["y"];
        const diffDepthSize = sizeModelCurrent["z"] - sizeNeaborModel["z"];

        // перевіряємо типи конектингу по верху обраної моделі
        // сусідня модель має розташовуватись в межах верзнього краю обраної моделі
        if (
          posYTopNeaborModel - posYTopModelCurrent >= -0.05 &&
          posYBottomNeaborModel - posYTopModelCurrent <= 0.05
        ) {

          // сусідня модель дотикається з правої сторони в куті,
          // або розташована з правої сторони на рівні обраної моделі по верху
          // для цього випадку вмикається молдинг спереду та зліва
          if (
            Math.abs(posXRightModelCurrent - posXLeftNeaborModel) <= 0.05 &&
            posXRightNeaborModel > posXRightModelCurrent
          ) {
            const typeConnectingLeft = getTypeConnectingRight(diffDepthSize, isCornerCabinet);
            if (!arrTypeConnecting.includes(typeConnectingLeft)) {
              arrTypeConnecting.push(getTypeConnectingRight(diffDepthSize, isCornerCabinet));
            }
            right = true;
            return;
          }


          // сусідня модель дотикається з лівої сторони в куті,
          // або розташована з лівої сторони на рівні обраної моделі по верху
          // для цього випадку вмикається молдинг спереду та справа
          if (
            Math.abs(posXLeftModelCurrent - posXRightNeaborModel) <= 0.05 &&
            posXLeftNeaborModel < posXLeftModelCurrent
          ) {
            const typeConnectingLeft = getTypeConnectingLeft(diffDepthSize, isCornerCabinet);
            if (!arrTypeConnecting.includes(typeConnectingLeft)) {
              arrTypeConnecting.push(getTypeConnectingLeft(diffDepthSize, isCornerCabinet));
            }
            left = true;
            return;
          }


          // сусідня модель розташована над обраною моделлю
          // для цього випадку молдинг в моделі не вмикається
          if (
            (Math.abs(posXLeftModelCurrent - posXLeftNeaborModel) <= 0.05 &&
            Number(posXRightNeaborModel.toFixed(2)) >= Number(posXRightModelCurrent.toFixed(2))) ||
            (Math.abs(posXRightModelCurrent - posXRightNeaborModel) <= 0.05 &&
            Number(posXLeftNeaborModel.toFixed(2)) <= Number(posXLeftModelCurrent.toFixed(2))) ||
            (Number(posXLeftNeaborModel.toFixed(2)) < Number(posXLeftModelCurrent.toFixed(2)) &&
            Number(posXRightNeaborModel.toFixed(2)) > Number(posXRightModelCurrent.toFixed(2)))
          ) {
            if (!arrTypeConnecting.includes("front")) {
              arrTypeConnecting.push("front");
            }
            left = true;
            center = true;
            right = true;
            return;
          }

        }
      }
    })

  })

  return arrTypeConnecting;

};

export type TypeConnectingTopMouldingT =
  | "right"
  | "left"
  | "partialSmallLeft"
  | "partialBigLeft"
  | "partialSmallRight"
  | "partialBigRight"
  | "front";
export type ArrTypeConnectingTopMouldingT = TypeConnectingTopMouldingT[];
export type ObjTypeConnectingTopMouldingT = {
  [key in ModelCabinetWallT]: ArrTypeConnectingTopMouldingT;
};
/**
 * Функція визначає для кожної настінної моделі(шкафа) тип коннектинга(по верхньому краю) з сусідніми шкафами.
 * Шукає тип коннектингу по верхньому краю шкафів.
 * Цей об'єкт необхідний для побудови верхнього молдингу.
 *
 * @returns {ObjTypeConnectingTopMouldingT} Об'ект з інформацією про тип коннектингу шкафів з сусідніми шкафами.
 */
export const getTypeConnectingTopCabinetsWall =
  (): ObjTypeConnectingTopMouldingT => {
    const objExtremePointsForModels = getExtremePointsForAllModelsWall(
      NODES_THREEKIT.MODEL_CABINET_WALL
    );
    const objSizeForAllModelsWall = getSizeBoxForAllCabinetsWall();
    const extremePointsForModelsKeys = Object.keys(
      objExtremePointsForModels
    ) as Array<keyof typeof objExtremePointsForModels>;

    const typeConnectingTopMoulding = extremePointsForModelsKeys.reduce(
      (
        objExtremeModelsInfoAcc: ObjTypeConnectingTopMouldingT,
        modelNullNameCurrent
      ) => {

        // let arrTypeConnecting: ArrTypeConnectingTopMouldingT =
        //   getTypeConnectingTopForModel(
        //     modelNullNameCurrent,
        //     objExtremePointsForModels,
        //     objSizeForAllModelsWall,
        //   );

        let arrTypeConnecting: ArrTypeConnectingTopMouldingT =
          getTypeConnectingTopForModelFromIntervals(
            modelNullNameCurrent
          );

        return {
          ...objExtremeModelsInfoAcc,
          [modelNullNameCurrent]: arrTypeConnecting,
        };
      },
      {}
    );

    return typeConnectingTopMoulding;
  };

/**
 * Функція визначає для однієї настінної моделі(шкафа) тип коннектинга(по нижньому краю) з сусідніми шкафами.
 * Шукає тип коннектингу по нижньому краю шкафів.
 *
 * @param {ModelCabinetWallT} modelNullNameCurrent Null Name для настінної моделі(шкафа).
 * @param {ObjExtremePointsWallT} objExtremePointsForModels Об'ект з інформацією про крайні точки для всіх настінних моделей на сцені.
 * @param {ObjSizeCabinetsWallT} objSizeForAllModelsWall Об'ект з інформацією про розміри всіх встановлених на сцені настінних моделей.
 * @returns {ObjTypeConnectingTopMouldingT} Массив, що описує типи коннектинга обраної моделі з сусідніми шкафами по нижньому краю шкафів.
 */
const getTypeConnectingBottomForModel = (
  modelNullNameCurrent: ModelCabinetWallT,
  objExtremePointsForModels: ObjExtremePointsWallT,
  objSizeForAllModelsWall: ObjSizeCabinetsWallT,
): ArrTypeConnectingTopMouldingT => {

  const objExtremePointsForModel = getExtremePointsForModelWall(modelNullNameCurrent);
  const modelSizeCurrent = getSizeModelRelativeTransform(modelNullNameCurrent);
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: modelNullNameCurrent});
  const isCornerCabinet = checkIfCornerCabinet(itemNodeFromNullModel);
  const extremePointsForModelsKeys = Object.keys(
    objExtremePointsForModels
  ) as Array<keyof typeof objExtremePointsForModels>;

  let arrTypeConnecting: ArrTypeConnectingTopMouldingT = [];
  extremePointsForModelsKeys.forEach((modelNullName) => {
    if (modelNullName !== modelNullNameCurrent) {
      const { leftExtremePoint, rightExtremePoint } =
        objExtremePointsForModels[modelNullName];
      const size = objSizeForAllModelsWall[modelNullName];
      const diffDepthSize = modelSizeCurrent["z"] - size["z"];
      const diffHeightSize = modelSizeCurrent["y"] - size["y"];
      const isEqualCoordsLeft = isEqualCoordsTolerance(
        { ...objExtremePointsForModel["leftExtremePoint"], y: 0 },
        { ...rightExtremePoint, y: 0 },
        0.1
      );
      const isEqualCoordsRight = isEqualCoordsTolerance(
        { ...objExtremePointsForModel["rightExtremePoint"], y: 0 },
        { ...leftExtremePoint, y: 0 },
        0.1
      );

      if (isEqualCoordsLeft) {

        if (diffHeightSize < -0.1) {
          arrTypeConnecting.push(getTypeConnectingLeft(diffDepthSize, isCornerCabinet));
        } else if (diffHeightSize > 0.1) {
          return;
        } else {
          arrTypeConnecting.push("left");
        }

      }

      if (isEqualCoordsRight) {

        if (diffHeightSize < -0.1) {
          arrTypeConnecting.push(getTypeConnectingRight(diffDepthSize, isCornerCabinet));
        } else if (diffHeightSize > 0.1) {
          return;
        } else {
          arrTypeConnecting.push("right");
        }

      }
    }
  });

  // check Corner
  const cornerTypeConnecting = checkTypeConnectingInCorner(
    modelNullNameCurrent,
    modelSizeCurrent
  );
  if (
    !!cornerTypeConnecting &&
    !arrTypeConnecting.includes(cornerTypeConnecting) &&
    !isCornerCabinet
  ) {
    arrTypeConnecting.push(cornerTypeConnecting);
  }

  return arrTypeConnecting;
};

/**
 * Функція визначає для однієї настінної моделі(шкафа) тип коннектинга з сусідніми шкафами.
 * Шукає тип коннектингу по нижньому краю шкафів.
 * Тип коннектингу шукається за допомогою інформації про інтервали на стінах, які отримуються з функції getIntervalsWallCabinetsForAllWalls.
 *
 * @param {ModelCabinetWallT} modelNullNameCurrent Null Name для настінної моделі(шкафа).
 * @returns {ArrTypeConnectingTopMouldingT} Массив, що описує типи коннектинга обраної моделі з сусідніми шкафами.
 */
const getTypeConnectingBottomForModelFromIntervals = (
  modelNullNameCurrent: ModelCabinetWallT,
): ArrTypeConnectingTopMouldingT => {

  let arrTypeConnecting: ArrTypeConnectingTopMouldingT = [];
  const intervalsWallCabinetsForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: modelNullNameCurrent});
  const isCornerCabinet = checkIfCornerCabinet(itemNodeFromNullModel);
  const positionModelCurrent = getTranslationThreekit({name: modelNullNameCurrent});
  const sizeModelCurrent = getSizeModelBoxFromAssetCabinetWall(modelNullNameCurrent);

  // для моделі modelNullNameCurrent знайти массив проміжків які знаходяться на стіні разом з моделлю
  // Знайти об'єкт для проміжоку обраної моделі modelNullNameCurrent
  Object.values(intervalsWallCabinetsForAllWalls).forEach((arrWallRanges: ArrWallRangesT) => {
    let indicatorIncludesModel: boolean = false;
    let objRangeModelCurrent: WallRangeT | undefined;
    arrWallRanges.forEach((objWallRange: WallRangeT) => {
      if (objWallRange.hasOwnProperty("name") && objWallRange["name"] === modelNullNameCurrent) {
        indicatorIncludesModel = true;
        objRangeModelCurrent = objWallRange;
      }
    })

    if (!indicatorIncludesModel || objRangeModelCurrent === undefined) return;

    const arrIntervalsForWall: ArrWallRangesT = arrWallRanges;

    // Знайти всі проміжки, які дотикаються або перетинаються з обраною моделлю modelNullNameCurrent
    const  matchingIntervals = findMatchingIntervals(arrIntervalsForWall, objRangeModelCurrent);

    // знаходимо позицію по Y для обраної моделі
    // шукаємо верхню та нижню точки по Y
    const posYBottomModelCurrent = positionModelCurrent["y"];
    const posYTopModelCurrent = positionModelCurrent["y"] + sizeModelCurrent["y"];

    // позиція обраної моделі вздовж стіни, на якій вона розташована
    const posXLeftModelCurrent = objRangeModelCurrent["range"][0];
    const posXRightModelCurrent = objRangeModelCurrent["range"][1];

    // шукаємо тип з'єднання обраної моделі з іншими моделями
    let left: boolean = false;
    let center: boolean = false;
    let right: boolean = false;
    matchingIntervals.forEach((objWallRange: WallRangeT) => {
      const { empty, range, name } = objWallRange;
      if (!!name) {
        
        const positionNeaborModel = getTranslationThreekit({name: name});
        const sizeNeaborModel = getSizeModelBoxFromAssetCabinetWall(name);
        const posXLeftNeaborModel = range[0];
        const posXRightNeaborModel = range[1];
        const posYBottomNeaborModel = positionNeaborModel["y"];
        const posYTopNeaborModel = positionNeaborModel["y"] + sizeNeaborModel["y"];
        const diffDepthSize = sizeModelCurrent["z"] - sizeNeaborModel["z"];

        // перевіряємо типи конектингу по низу обраної моделі
        // сусідня модель має розташовуватись в межах нижнього краю обраної моделі
        if (
          posYTopNeaborModel - posYBottomModelCurrent >= -0.05 &&
          posYBottomNeaborModel - posYBottomModelCurrent <= 0.05
        ) {

          // правий край обраної моделі знаходиться над сусідньою моделлю
          // а лівий край обраної моделі не знаходиться над сусідньою знизу моделлю
          // для цього випадку вмикається молдинг спереду та зліва
          if (
            // Math.abs(posXRightModelCurrent - posXLeftNeaborModel) <= 0.05 &&
            // posXRightNeaborModel > posXRightModelCurrent
            posXRightModelCurrent >= posXLeftNeaborModel - 0.05 &&
            posXRightModelCurrent <= posXRightNeaborModel + 0.05 &&
            posXLeftModelCurrent < posXLeftNeaborModel
          ) {
            const typeConnectingLeft = getTypeConnectingRight(diffDepthSize, isCornerCabinet);
            if (!arrTypeConnecting.includes(typeConnectingLeft)) {
              arrTypeConnecting.push(getTypeConnectingRight(diffDepthSize, isCornerCabinet));
            }
            right = true;
            return;
          }


          // лівий край обраної моделі знаходиться над сусідньою моделлю
          // а правий край обраної моделі не знаходиться над сусідньою знизу моделлю
          // для цього випадку вмикається молдинг спереду та справа
          if (
            // Math.abs(posXLeftModelCurrent - posXRightNeaborModel) <= 0.05 &&
            // posXLeftNeaborModel < posXLeftModelCurrent
            posXLeftModelCurrent >= posXLeftNeaborModel - 0.05 &&
            posXLeftModelCurrent <= posXRightNeaborModel + 0.05 &&
            posXRightModelCurrent > posXRightNeaborModel
          ) {
            const typeConnectingLeft = getTypeConnectingLeft(diffDepthSize, isCornerCabinet);
            if (!arrTypeConnecting.includes(typeConnectingLeft)) {
              arrTypeConnecting.push(getTypeConnectingLeft(diffDepthSize, isCornerCabinet));
            }
            left = true;
            return;
          }


          // сусідня модель розташована під обраною моделлю
          // розміри моделей не співпадають
          // для цього випадку нижній молдинг в моделі не вмикається
          // повертаємо тип конектингу "front"
          if (
            (Math.abs(posXLeftModelCurrent - posXLeftNeaborModel) <= 0.05 &&
            Number(posXRightNeaborModel.toFixed(2)) >= Number(posXRightModelCurrent.toFixed(2))) ||
            (Math.abs(posXRightModelCurrent - posXRightNeaborModel) <= 0.05 &&
            Number(posXLeftNeaborModel.toFixed(2)) <= Number(posXLeftModelCurrent.toFixed(2))) ||
            (Number(posXLeftNeaborModel.toFixed(2)) < Number(posXLeftModelCurrent.toFixed(2)) &&
            Number(posXRightNeaborModel.toFixed(2)) > Number(posXRightModelCurrent.toFixed(2)))
          ) {
            if (!arrTypeConnecting.includes("front")) {
              arrTypeConnecting.push("front");
            }
            left = true;
            center = true;
            right = true;
            return;
          }

        }
      }
    })

  })

  return arrTypeConnecting;

};

/**
 * Функція визначає для кожної настінної моделі(шкафа) тип коннектинга(по нижньому краю) з сусідніми шкафами.
 * Шукає тип коннектингу по нижньому краю шкафів.
 * Цей об'єкт необхідний для побудови нижнього молдингу.
 *
 * @returns {ObjTypeConnectingTopMouldingT} Об'ект з інформацією про тип коннектингу шкафів з сусідніми шкафами.
 */
export const getTypeConnectingBottomCabinetsWall =
  (): ObjTypeConnectingTopMouldingT => {
    const objExtremePointsForModels = getExtremePointsForAllModelsWall(
      NODES_THREEKIT.MODEL_CABINET_WALL
    );
    const objSizeForAllModelsWall = getSizeFullForAllCabinetsWall();
    const extremePointsForModelsKeys = Object.keys(
      objExtremePointsForModels
    ) as Array<keyof typeof objExtremePointsForModels>;

    const typeConnectingTopMoulding = extremePointsForModelsKeys.reduce(
      (
        objExtremeModelsInfoAcc: ObjTypeConnectingTopMouldingT,
        modelNullNameCurrent
      ) => {

        // let arrTypeConnecting: ArrTypeConnectingTopMouldingT =
        //   getTypeConnectingBottomForModel(
        //     modelNullNameCurrent,
        //     objExtremePointsForModels,
        //     objSizeForAllModelsWall,
        //   );

        const arrTypeConnecting = getTypeConnectingBottomForModelFromIntervals(
          modelNullNameCurrent,
        )

        return {
          ...objExtremeModelsInfoAcc,
          [modelNullNameCurrent]: arrTypeConnecting,
        };
      },
      {}
    );

    return typeConnectingTopMoulding;
  };
