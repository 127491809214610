import { DeleteAllSvg } from "../../../assets/images/svg/DeleteAll";
import { DimentionsSvg } from "../../../assets/images/svg/Dimentions";
import { HistoryNextStepSvg } from "../../../assets/images/svg/HistoryNextStep";
import { HistoryPrevStepSvg } from "../../../assets/images/svg/HistoryPrevStep";
import { useAppDispatch } from "../../../hooks/useStoreHooks";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonIcon } from "../../StandartComponents/Buttons/BtnIcon";
// scss
import s from "./SceneControlsBottom3D.module.scss";

// svg icons

export const SceneControlsBottom3D = ({...props}) => {
  const { deleteAllWalls, handleToggleDimentions } = props;
  const dispatch = useAppDispatch();

  const handleClickPrevHistory = () => {
    console.log('handleClickPrevHistory --- ==== ',);
  }

  const handleClickNextHistory = () => {
    console.log('handleClickNextHistory --- ==== ',);
  }

  const handleClickDimentions = () => {
    // handleToggleDimentions();
  }

  const handleClickRemoveAllCabinets = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.DELETE_ALL_CABINETS, value: true}))
  }

  return (
    <div className={s.sceneControlsBottom3D} onMouseUp={(e) => e.stopPropagation()}>
      {/* <ButtonIcon onClick={handleClickPrevHistory}><HistoryPrevStepSvg /></ButtonIcon> */}
      {/* <ButtonIcon onClick={handleClickNextHistory}><HistoryNextStepSvg /></ButtonIcon> */}
      <ButtonIcon onClick={handleClickDimentions}><DimentionsSvg /><span>Ruler</span></ButtonIcon>
      <ButtonIcon onClick={handleClickRemoveAllCabinets}><DeleteAllSvg /><span>Delete All</span></ButtonIcon>
    </div>
  );
};
