import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getTHREE } from "../../utils/three/general/getFunctionsTHREE";
import { getWorldTransformEvalNode } from "../../utils/threekit/general/getFunctions";
import { getNumberNodeThreekitFromName } from "../general";

/**
 * Повертає вектор, який показує напрям від Front-сторони стіни (перпендикулярно до площини стіни).
 *
 * @param {WallItemT} wallName Name стіни з Threekit.
 * @return {ICoordinates} Вектор wallDir,
 * який показує напрям від Front-сторони стіни (перпендикулярно до площини стіни)..
 */
export const getWallDir = (wallName: WallItemT): ICoordinates => {
  const THREE = getTHREE();
  const worldTransformWall = getWorldTransformEvalNode(wallName);
  const wallDir = new THREE.Vector3(0, 0, 1).transformDirection(worldTransformWall);
  return wallDir;
}

type LeftRigthDirectionsWallT = {
  leftDirectionWall: ICoordinates,
  rightDirectionWall: ICoordinates,
}
/**
 * Повертає два вектори { leftDirectionWall, rightDirectionWall }.
 *
 * @param {WallItemT} wallName Name стіни з Threekit.
 * @return {LeftRigthDarectionsPlane} Вектори { leftDirectionWall, rightDirectionWall },
 * які показують відповідно напрями вліво та вправо по площині стіни від центру стіни.
 */
export const getLeftRigthDirectionsWall = (wallName: WallItemT): LeftRigthDirectionsWallT => {
  const THREE = getTHREE();
  const wallDir = getWallDir(wallName);
  const topVectorY = new THREE.Vector3(0, 1, 0);
  const bottomVectorY = new THREE.Vector3(0, -1, 0);
  // скалярний добуток векторів
  // отримуємо вектори, направлені вліво і вправо від центру стіни(Wall Translation)
  let leftCrossVectorNorm = new THREE.Vector3()
    .crossVectors(wallDir, topVectorY)
    .normalize();
  let rightCrossVectorNorm = new THREE.Vector3()
    .crossVectors(wallDir, bottomVectorY)
    .normalize();

  return {
    leftDirectionWall: leftCrossVectorNorm,
    rightDirectionWall: rightCrossVectorNorm
  }
}

/**
 * Повертає name для стіни, на якій розташований плейн з ім'ям planeName.
 *
 * @param {PlaneCabinetsWallT} planeName Name плейну з Threekit.
 * @return {WallItemT} Name стіни з Threekit.
 */
export const getWallNameFromPlaneName = (
  planeName: PlaneCabinetsWallT
): WallItemT => {
  return `${
    NODES_THREEKIT.WALL_ITEM
  }${getNumberNodeThreekitFromName(planeName)}` as WallItemT;
}