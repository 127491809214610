// https://layonez.medium.com/performant-animations-with-requestanimationframe-and-react-hooks-99a32c5c9fbf
import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { useEffect, useRef } from "react";
import { isEqualCoords } from "../functionsConfigurator/wallsAndFloor/buildWallFromData";
import { setCabinetControls } from "../store/slices/settingsUI/settingsUISlice";
import { SelectedModelNameT } from "../store/slices/settingsUI/typesSettingsUISlice";
import { getPositionCamera, getTranslationThreekit } from "../utils/threekit/general/getFunctions";
import { useAppDispatch } from "./useStoreHooks";

export const useAnimationFrameFromCameraPosition = (
  nextAnimationFrameHandler: any,
  shouldAnimate: boolean = true,
  selectedModelName: SelectedModelNameT
) => {
  const dispatch = useAppDispatch();
  const frame = useRef(0);
  const modelName = useRef<SelectedModelNameT>(selectedModelName);
  const oldCameraPosition = useRef<ICoordinates>(getPositionCamera());
  const oldModelPosition = useRef<ICoordinates>(getTranslationThreekit({name: selectedModelName}));

  

  const animate = () => {

    const newModelPosition = getTranslationThreekit({name: modelName["current"]});
    if (oldModelPosition["current"] !== null && !isEqualCoords(oldModelPosition["current"], {x: 0, y: 0, z: 0}) && !isEqualCoords(newModelPosition, oldModelPosition["current"])) {
      return dispatch(setCabinetControls({
        visible: false,
        selectedModelName: '',
      }));
    }

    const newCameraPosition = getPositionCamera();
    if (!isEqualCoords(newCameraPosition, oldCameraPosition["current"])) {
        nextAnimationFrameHandler(modelName["current"]);
    };
    
    frame["current"] = requestAnimationFrame(animate);
    oldCameraPosition["current"] = newCameraPosition;
    oldModelPosition["current"] = newModelPosition;

  };

  useEffect(() => {
    modelName["current"] = selectedModelName;
    oldModelPosition["current"] = getTranslationThreekit({name: selectedModelName});
  }, [selectedModelName]);

  useEffect(() => {
    if (shouldAnimate) {
      frame["current"] = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(frame["current"]);
    }

    return () => cancelAnimationFrame(frame["current"]);
  }, [shouldAnimate]);

  
};
