import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { CabinetsAndFeatures_NodesT, ModelCabinetWallT, ModelsName_NodesT, NODES_THREEKIT, PlaneCabinetsWallT } from "../../utils/constants/nodesNamesThreekit";
import {
  getTHREE,
  getVector3FromCoordinates,
} from "../../utils/three/general/getFunctionsTHREE";
import {
  getBoxWidthThreekit,
  getItemNodeFromNullModel,
  getRelativeTransformBoundingBoxEvalNode,
  getSceneInstanceId,
  getTranslationThreekit,
} from "../../utils/threekit/general/getFunctions";
import { getStartEndPointWall } from "../wallsAndFloor/getStartEndPointWall";
import { getAllPlanesNode, getStartEndCoordsPlane } from "../wallsAndFloor/getWallPlanesInfo";
import { getСompletedModelsNullNames } from "../../functionsConfigurator/cabinets/getNodesCabinets";
import { getSizeModelBoxFromAssetCabinetWall } from "../cabinets/cabinetsWall/size";
import * as THREE from "three";
import { checkIfCornerCabinet } from "../cabinets/cabinetsWall/extremePoints";
import { getNumberNodeThreekitFromName } from "../general";
import { filteringBaseFeatures, getArrNullNamesWallConnections } from "../features/general";
import { getWallRangesFeaturesFilled } from "../features/intervalsBaseFeatures";

export type RangeT = [number, number];
export type WallRangeT = {
  empty: boolean;
  range: RangeT;
  name?: CabinetsAndFeatures_NodesT;
};
export type ArrWallRangesT = WallRangeT[];

/**
 * Повертає розмір об'єкта
 *
 * @param {string} name Name об'єкта Threekit, розміри якого потрібно визначити
 * @return {ICoordinates} Розмір об'єкта до його трансформації на сцені (переміщення, поворот, ...)
 */
export const getSizeModelRelativeTransform = (name: string): ICoordinates => {
  const THREE = getTHREE();
  const relativeTransformBoundingBoxPoint =
    getRelativeTransformBoundingBoxEvalNode(name);
  const size = relativeTransformBoundingBoxPoint.getSize(new THREE.Vector3());
  return size;
};

/**
 * Визначає відстань від лінії до точки
 *
 * @param {ICoordinates} pointA Координати початковоє точка відрізку
 * @param {ICoordinates} pointB Координати кінцевої точка відрізку
 * @param {ICoordinates} pointToCheck Координати точки, яку перевряємо на принадлежність заданому відрізку
 * @return {Number} відстань від лінії до точки.
 */
export const getDistanceFromLineToPoint = (
  pointA: ICoordinates,
  pointB: ICoordinates,
  pointToCheck: ICoordinates,
): number => {
  const pointAVector = getVector3FromCoordinates(pointA);
  const pointBVector = getVector3FromCoordinates(pointB);
  const pointToCheckVector = getVector3FromCoordinates(pointToCheck);
  const d = new THREE.Vector3()
    .subVectors(pointAVector, pointBVector)
    .normalize();
  const v = new THREE.Vector3().subVectors(pointToCheckVector, pointAVector);
  const t = v.dot(d);
  const pivotPoint = pointAVector.add(d.multiplyScalar(t));
  const distancePivotPointToPointToCheck =
    pointToCheckVector.distanceTo(pivotPoint);
  return distancePivotPointToPointToCheck;
};

/**
 * Визначає чи належить точка заданому відрізку
 *
 * @param {ICoordinates} pointA Координати початковоє точка відрізку
 * @param {ICoordinates} pointB Координати кінцевої точка відрізку
 * @param {ICoordinates} pointToCheck Координати точки, яку перевряємо на принадлежність заданому відрізку
 * @param {Number} delta Допустима відстань, в околі якої точка може бути віддалена від відрізку 
 * @return {Boolean} true/false - Чи знаходиться точка checkPoint на відрізку [startPoint, endPoint].
 */
export const isPointOnLine = (
  pointA: ICoordinates,
  pointB: ICoordinates,
  pointToCheck: ICoordinates,
  delta: number
): boolean => {
  const distancePivotPointToPointToCheck = getDistanceFromLineToPoint(
    pointA,
    pointB,
    pointToCheck,
  );
  return distancePivotPointToPointToCheck <= delta;
};

/**
 * Перетворює массив заповнених проміжків таким чином, що в результаті отримуємо массив пустих і заповнених проміжків.
 *
 * @param {ArrWallRangesT} arrRangesFilled Проміжки на стіні, в яких знаходяться об'єкти (заповнені проміжки).
 * @param {Number} intervalLength Довжина відрізку, для якого розраховуються проміжки.
 * @return {ArrWallRangesT} Массив всіх проміжків(заповнених та пустих) на стіні.
 */
export const createRangesEmptyAndFilled = (arrRangesFilled: ArrWallRangesT, intervalLength: number): ArrWallRangesT => {

  const filteredRangesFilled = arrRangesFilled
    .slice()
    .sort((rangeA, rangeB) => rangeA["range"][0] - rangeB["range"][0]);

  let fullRanges: ArrWallRangesT = [];
  let lastIndex = 0;

  // додаємо пусту частину на початку
  if (filteredRangesFilled[0]["range"][0] >= 0.005) {
    fullRanges.push({
      range: [0, filteredRangesFilled[0]["range"][0]],
      empty: true,
    });
    lastIndex = filteredRangesFilled[0]["range"][0];
  }

  // додаємо заповнені та пусті частини
  filteredRangesFilled.forEach(segment => {
    if (segment["range"][0] - lastIndex >= 0.005) {
      fullRanges.push({
        range: [lastIndex, segment["range"][0]],
        empty: true
      });
    }
    fullRanges.push(segment);
    lastIndex = segment["range"][1];
  });

  // додаємо пусту частину в кінці
  if (lastIndex <= intervalLength - 0.005) {
    fullRanges.push({
      range: [lastIndex, intervalLength],
      empty: true
    });
  }

  return fullRanges;
}

/**
 * Створює массив всіх проміжків(заповнених та пустих) на стіні.
 *
 * @param {Number} namePlane Name для Plane на стіні. По цьому Plane ми рухаємо об'єкти.
 * @return {ArrWallRangesT} Массив всіх проміжків(заповнених та пустих) на стіні.
 */
export const getIntervalsInfoOnWall = (
  namePlane: PlaneCabinetsWallT
): ArrWallRangesT => {
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(namePlane);
  const currentPlaneWidth = getBoxWidthThreekit({ name: namePlane });
  const modelsWallNullOnWall = getModelsWallNullOnPlane(namePlane);
  const fullRangePlane: WallRangeT = {
    empty: true,
    range: [0, currentPlaneWidth],
  }

  // if (modelsWallNullOnWall.length === 0)
  //   return [fullRangePlane]

  let wallRangesFilled: ArrWallRangesT = [];

  // додаємо інтервали для вікон в загальний список інтервалів на стіні
  const wallNum = getNumberNodeThreekitFromName(namePlane);
  const arrNullNamesWallConnections = getArrNullNamesWallConnections(wallNum);
  if (arrNullNamesWallConnections.length > 0) {
    const wallRangesFeaturesFilled = getWallRangesFeaturesFilled(
      arrNullNamesWallConnections,
      [currentPlaneCoordsLeft, currentPlaneCoordsRight]
    );
    wallRangesFilled = [ ...wallRangesFeaturesFilled ];
  }

  modelsWallNullOnWall.forEach((modelNullName) => {

    const modelNullTranslation = getTranslationThreekit({
      from: getSceneInstanceId(),
      name: modelNullName,
    });
    // const isModelNullOnPlane = isPointOnLine(
    //   { ...currentPlaneCoordsLeft, y: 0 },
    //   { ...currentPlaneCoordsRight, y: 0 },
    //   { ...modelNullTranslation, y: 0},
    //   0.005
    // );

    // if (isModelNullOnPlane) {
      // const sizeModel = getSizeModelRelativeTransform(modelNullName);
      const sizeModel = getSizeModelBoxFromAssetCabinetWall(modelNullName);
      const modelWidth = sizeModel["x"];
      const distanceFromPlaneStartToModelNull = getVector3FromCoordinates(
        { ...currentPlaneCoordsLeft, y: 0 }
      ).distanceTo(getVector3FromCoordinates({ ...modelNullTranslation, y: 0}));
      const rangeModel: RangeT = [
        distanceFromPlaneStartToModelNull - modelWidth / 2,
        distanceFromPlaneStartToModelNull + modelWidth / 2,
      ];
      wallRangesFilled.push({
        empty: false,
        range: rangeModel,
        name: modelNullName,
      });
    // }
  })

  // check Cabinets Wall Corner for the next wall
  const completedModelsNullNamesCabinetsWall = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );
  const modelsWallNullNotOnWall = completedModelsNullNamesCabinetsWall.filter((modelNullName) => !modelsWallNullOnWall.includes(modelNullName));
  const cornerCabinetsWallNotOnWall = modelsWallNullNotOnWall.filter((modelNullName) => {
    const modelItem = getItemNodeFromNullModel({ name: modelNullName });
    return checkIfCornerCabinet(modelItem);
  })
  cornerCabinetsWallNotOnWall.forEach((modelNullName) => {
    const sizeCornerCabinet = getSizeModelBoxFromAssetCabinetWall(modelNullName);
    const posCornerCabinet = getTranslationThreekit({
      from: getSceneInstanceId(),
      name: modelNullName,
    });
    const distanceFromPlaneToCornerCabinet = getDistanceFromLineToPoint(
      { ...currentPlaneCoordsLeft, y: 0 },
      { ...currentPlaneCoordsRight, y: 0 },
      { ...posCornerCabinet, y: 0 },
    )
    if (Math.abs(distanceFromPlaneToCornerCabinet - sizeCornerCabinet["x"] / 2) <= 0.05) {
      wallRangesFilled.push({
        empty: false,
        range: [
          // currentPlaneWidth - sizeCornerCabinet["x"],
          // currentPlaneWidth
          0,
          sizeCornerCabinet["z"]
        ],
        name: modelNullName,
      });
    }
  })

  if (wallRangesFilled.length === 0)
    return [fullRangePlane];

  const wallRangesEmptyAndFilled = createRangesEmptyAndFilled(
    wallRangesFilled,
    currentPlaneWidth
  );

  return wallRangesEmptyAndFilled;
};

/**
 * Шукає моделі на стіні, які встановлені безпосередньо на обраній стіні. НІСТІННІ МОДЕЛІ(Cabinets Wall).
 *
 * @param {PlaneCabinetsWallT} planeName Ім'я для Plane на стіні з Threekit, біля якої шукаємо настінні моделі (поцьому плейну переміщуються моделі на стіні).
 * @return {ModelsName_NodesT[]} Массив з іменами Threekit, для Models Null ["Model_Cabinets_Wall_0", ...].
 */
export const getModelsWallNullOnPlane = (
  planeName: PlaneCabinetsWallT
): ModelsName_NodesT[] => {
  const completedModelsNullNamesCabinetsWall = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );
  const [planeCoordsLeft, planeCoordsRight] = getStartEndCoordsPlane(planeName);

  let modelsOnWall: ModelsName_NodesT[] = [];
  completedModelsNullNamesCabinetsWall.forEach((nodeModelNullName) => {
    const posModel = getTranslationThreekit({ name: nodeModelNullName });
    // перевіряємо чи належить точка розташування моделі відрізку,
    // який спроектований на піідлогу (y = 0)
    const isModelNullOnPlane = isPointOnLine(
      { ...planeCoordsLeft, y: 0 },
      { ...planeCoordsRight, y: 0 },
      { ...posModel, y: 0 },
      0.01
    );
    if (isModelNullOnPlane) modelsOnWall.push(nodeModelNullName);
  });

  return modelsOnWall;
};

/**
 * Повертає перший пустий інтервал, в який може поміститися модель з розмірами modelWidth.
 *
 * @param {ArrWallRangesT} arrIntervals Массив всіх проміжків(заповнених та пустих) на стіні.
 * @param {Number} modelWidth Ширина моделі, під яку потрібно підібрати проміжок.
 * @return {[ICoordinates, ICoordinates]} Координати початку і кінця для Plane.
 */
export const getIntervalForModel = (arrIntervals: ArrWallRangesT, modelWidth: number): WallRangeT | undefined => {
  const arrIntervalsCopy = [ ...arrIntervals ];
  const reversedArrIntervals = arrIntervalsCopy.reverse();
  const intervalResult = reversedArrIntervals.find((interval) => {
    const intervalLength = interval["range"][1] - interval["range"][0];
    return interval["empty"] && modelWidth <= intervalLength;
  })
  return intervalResult;
}

/**
 * Функція перевіряє модель, яка стоїть в куті.
 * Якщо це модель "36" Blind Corner Base Cabinet" то ми не маємо вставляти кутову мождель-заглушку
 *
 * @param {ArrWallRangesT} intervalsCorner Массив всіх проміжків(заповнених та пустих) на стіні.
 * @return {Boolean} Якщо модель "36" Blind Corner Base Cabinet" - повертає true. Якщо ні - false.
 */
export const checkIntervalFilledCornerBlind = (intervalsCorner: ArrWallRangesT): boolean => {

  if (intervalsCorner.length < 1) return false;

  const nameNullCornerModel = intervalsCorner[0]["name"];
  if (!!nameNullCornerModel) {
    const nameModel = getItemNodeFromNullModel({name: nameNullCornerModel});
    if (!!nameModel && nameModel["name"].includes("Corner") && nameModel["name"].includes("Blind")) {
      return true;
    }
  }

  return false

}

/**
 * Перевіряє чи є на сусідній стіні заповнений проміжок, який розташований близько до кута.
 * Якщо немає - повертає true
 * Якщо є - повертає false
 *
 * @param {ArrWallRangesT} intervalsInfoOnWall Массив всіх проміжків(заповнених та пустих) на стіні.
 * @param {Number} cornerOffset Відстань, на яку перевіряється наявність заповнених проміжків в масиві інтервалів intervalsInfoOnWall.
 * @param {"left" | "right"} side Визнічає справа чи зліва шукаємо пустий проміжок.
 * @return {Boolean} Якщо кутовий проміжок зайнятий - повертає false. Якщо вільний - true.
 */
export const checkCornerIntervalEmpty = (intervalsInfoOnWall: ArrWallRangesT, cornerOffset: number, side: "left" | "right"): boolean => {
  let isCornerIntervalEmpty: boolean = true;

  if (side === "right") {
    const startPoint = intervalsInfoOnWall[intervalsInfoOnWall.length - 1]["range"][1]
    const extremePoint = Math.abs(startPoint - cornerOffset);
    let intervalsCorner: ArrWallRangesT = [];
    intervalsInfoOnWall.forEach((objInterval) => {
      if (!objInterval["empty"] && (objInterval["range"][0] > extremePoint || objInterval["range"][1] > extremePoint)) {
        isCornerIntervalEmpty = false;
        intervalsCorner.push(objInterval)
      }
    });

    const isInretvalBlindCorner = checkIntervalFilledCornerBlind(intervalsCorner);
    isCornerIntervalEmpty = isInretvalBlindCorner ? isInretvalBlindCorner : isCornerIntervalEmpty;

    // if (intervalsCorner.length > 0) {
    //   const nameNullCornerModel = intervalsCorner[0]["name"];
    //   if (!!nameNullCornerModel) {
    //     const nameModel = getItemNodeFromNullModel({name: nameNullCornerModel});
    //     if (!!nameModel && nameModel["name"].includes("Corner") && nameModel["name"].includes("Blind")) {
    //       isCornerIntervalEmpty = true;
    //     }
    //   }
    // }
    
  }

  if (side === "left") {
    const startPoint = intervalsInfoOnWall[0]["range"][0];
    const extremePoint = Math.abs(startPoint - cornerOffset);
    let intervalsCorner: ArrWallRangesT = [];
    intervalsInfoOnWall.forEach((objInterval) => {
      if (!objInterval["empty"] && (objInterval["range"][0] < extremePoint || objInterval["range"][1] < extremePoint)) {
        isCornerIntervalEmpty = false;
        intervalsCorner.push(objInterval)
      }
    })

    const isInretvalBlindCorner = checkIntervalFilledCornerBlind(intervalsCorner);
    isCornerIntervalEmpty = isInretvalBlindCorner ? isInretvalBlindCorner : isCornerIntervalEmpty;

    // if (intervalsCorner.length > 0) {
    //   const nameNullCornerModel = intervalsCorner[0]["name"];
    //   if (!!nameNullCornerModel) {
    //     const nameModel = getItemNodeFromNullModel({name: nameNullCornerModel});
    //     if (!!nameModel && nameModel["name"].includes("Corner") && nameModel["name"].includes("Blind")) {
    //       isCornerIntervalEmpty = true;
    //     }
    //   }
    // }

  }

  return isCornerIntervalEmpty;
}

/**
 * Додає в існуючий масив проміжків новий проміжок і повертає новий масив проміжків.
 * Новий проміжок додається в порядку слідування існуючого масива проміжків.
 *
 * @param {ArrWallRangesT} arrIntervals Массив всіх проміжків(заповнених та пустих) на стіні.
 * @param {WallRangeT} newInterval Проміжок, який потрібно додати в іннуючий массив проміжків.
 * @return {ArrWallRangesT} Новий масив проміжків з доданим новим інтервалом.
 */
export const addIntervalToArrIntervals = (arrIntervals: ArrWallRangesT, newInterval: WallRangeT): ArrWallRangesT => {

  const newIntervalRange = newInterval["range"];
  const newArrIntervals: ArrWallRangesT = [];

  arrIntervals.forEach((objInterval) => {
    const {empty, range} = objInterval;

    // відрізки співпадають
    // |------------|
    // |------------| (new)
    if (newIntervalRange[0].toFixed(3) === range[0].toFixed(3) && newIntervalRange[1].toFixed(3) === range[1].toFixed(3)) {

      return newArrIntervals.push(newInterval)

    } else
    // новий відрізок всередині з лівої сторони
    // |------------|
    // |-----| (new)
    if (newIntervalRange[0].toFixed(3) === range[0].toFixed(3) && newIntervalRange[1] < range[1]) {

      return newArrIntervals.push(newInterval, {
        empty: empty,
        range: [newIntervalRange[1], range[1]]
      })

    } else
    // новий відрізок всередині
    // |------------|
    //     |-----| (new)
    if (newIntervalRange[0] > range[0] && newIntervalRange[1] < range[1]) {

      return newArrIntervals.push({
        empty: empty,
        range: [range[0], newIntervalRange[0]]
      }, newInterval, {
        empty: empty,
        range: [newIntervalRange[1], range[1]]
      })

    } else
    // новий відрізок всередині з правої сторони
    // |------------|
    //        |-----| (new)
    if (newIntervalRange[0] > range[0] && newIntervalRange[1].toFixed(3) === range[1].toFixed(3)) {

      return newArrIntervals.push({
        empty: empty,
        range: [range[0], newIntervalRange[0]]
      }, newInterval)

    } else
    // новий відрізок зовні з лівої сторони
    //       |------------|
    // |-----| (new)
    if (newIntervalRange[0] < range[0] && newIntervalRange[1].toFixed(3) === range[1].toFixed(3)) {

      return newArrIntervals.push(newInterval, objInterval)

    } else
    // новий відрізок зовні з правої сторони
    // |------------|
    //              |-----| (new)
    if (newIntervalRange[0].toFixed(3) === range[0].toFixed(3) && newIntervalRange[1] > range[1]) {

      return newArrIntervals.push(objInterval, newInterval)

    } else
    // відрізки не перетинаються
    // повертаємо інтервал на своє місце
    {

      return newArrIntervals.push(objInterval)

    }

  })
  return newArrIntervals;
}

export type AllWallsIntervalsWallT = {
  [key in PlaneCabinetsWallT]: ArrWallRangesT;
}
/**
 * Створює об'єкт массивів проміжків(заповнених та пустих) ДЛЯ ШКАФІВ НА СТІНІ для УСІХ стін.
 *
 * @return {AllWallsIntervalsFloorT} Об'єкт массивів проміжків(заповнених та пустих) ДЛЯ ШКАФІВ НА ПІДЛОЗІ для УСІХ стін.
 */
export const getIntervalsWallCabinetsForAllWalls = (): AllWallsIntervalsWallT => {
  const nodesAllPlanes = getAllPlanesNode();
  const objWallsIntervals = Object.values(nodesAllPlanes).reduce((accumulator: AllWallsIntervalsWallT, node: ISceneResult) => {
    const planeName = node["name"] as PlaneCabinetsWallT;
    // const modelsBaseNullOnWall = getModelsWallNullOnPlane(planeName);
    const intervalsInfoOnWallForCabinetsBase = getIntervalsInfoOnWall(planeName);
    return { ...accumulator, [planeName]: intervalsInfoOnWallForCabinetsBase }
  }, {});
  return objWallsIntervals;
}