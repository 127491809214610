import { CabinetsAndFeatures_NodesT, ModelCabinetWallT, ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit"
import { getItemNodeFromNullModel, getTranslationThreekit, getWorldTransformEvalNode } from "../../../utils/threekit/general/getFunctions";
import { ExtremePointI } from "../getExtremeModels";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import * as THREE from "three";
import { moveCoordsByVector } from "../../wallsAndFloor/buildWallFromData";
import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";

/**
 * Функція змінює напрям вектора, заданого параметром vector,
 * залежно від трансформаційної матриці worldTransformModel,
 * яка відображає місцеположення або орієнтацію об'єкта в тривимірному просторі.
 *
 * @param {ICoordinates} vectorFrom regexp для Null моделей, для яких шукаємо крайні точки.
 * @param {THREE.Matrix4} transformMatrix Трансформаційна матриця може містити обертання, масштабування і зміщення.
 * @returns {ObjExtremePointT} Об'ект з інформацією про крайні точки для кожної моделі на сцені.
 */
export const getDirVectorFromTransformMatrix = (vectorFrom: ICoordinates, transformMatrix: THREE.Matrix4): THREE.Vector3 => {
  return new THREE.Vector3(vectorFrom["x"], vectorFrom["y"], vectorFrom["z"]).transformDirection(
    transformMatrix
  );
}

/**
  * Функція для перевірки моделі на кутову модель.
  * 
  @param {ISceneResult} modelItem - Item моделі з Threekit.
  @returns {Boolean} true/false - є модель кутовою чи ні.
  */
export const checkIfCornerCabinet = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner");
};
export const checkIfCornerCabinetFromNullName = (nullNameModel: CabinetsAndFeatures_NodesT): boolean => {
  const modelItem = getItemNodeFromNullModel({ name: nullNameModel });
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner");
};

/**
  * Функція для перевірки моделі на кутову модель-заглушку.
  * 
  @param {ISceneResult} modelItem - Item моделі з Threekit.
  @returns {Boolean} true/false - є модель кутовою чи ні.
  */
export const checkIfCornerEmptyCabinet = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner") && modelItem["name"].includes("empty");
};

/**
 * Функція для визначення крайніх точок однієї моделі.
 * Шукає крайні точки зліва ззаду та справа ззаду для моделі на сцені.
 *
 * @param {ModelCabinetWallT} modelName Null name моделеі, для якої шукаємо крайні точки.
 * @returns {ExtremePointsWallI} Об'ект з інформацією про крайні точки моделі.
 */
export const getExtremePointsForModelWall = (modelName: ModelCabinetWallT): ExtremePointsWallI => {
  const modelItem = getItemNodeFromNullModel({ name: modelName });
  // const modelPos = getModelPositionFromName(modelName);
  const modelPos = getTranslationThreekit({name: modelName});
  const modelSize = getSizeModelBoxFromAssetCabinetWall(modelName);
  const worldTransformModel = getWorldTransformEvalNode(modelName);

  if (checkIfCornerCabinet(modelItem) && !checkIfCornerEmptyCabinet(modelItem)) {
    const modelDirLeft = getDirVectorFromTransformMatrix({x: -1, y: 0, z: 0}, worldTransformModel);
    const modelDirLeftС = getDirVectorFromTransformMatrix({x: 0, y: 0, z: 1}, worldTransformModel);
    const modelDirRightС = getDirVectorFromTransformMatrix({x: 1, y: 0, z: 0}, worldTransformModel);

    let leftExtremePointС = moveCoordsByVector(
      modelPos,
      modelDirLeft,
      modelSize["x"]/2
    );
    leftExtremePointС = moveCoordsByVector(
      leftExtremePointС,
      modelDirLeftС,
      modelSize["z"]
    );
    const rightExtremePointС = moveCoordsByVector(
      modelPos,
      modelDirRightС,
      modelSize["x"]/2
    );

    return {
      leftExtremePoint: leftExtremePointС,
      rightExtremePoint: rightExtremePointС,
    };

  }

  if (checkIfCornerCabinet(modelItem) && checkIfCornerEmptyCabinet(modelItem)) {
    const modelDirLeftС = getDirVectorFromTransformMatrix({x: 0, y: 0, z: 1}, worldTransformModel);
    const modelDirRightС = getDirVectorFromTransformMatrix({x: 1, y: 0, z: 0}, worldTransformModel);
    const leftExtremePointС = moveCoordsByVector(
      modelPos,
      modelDirLeftС,
      modelSize["z"]
    );
    const rightExtremePointС = moveCoordsByVector(
      modelPos,
      modelDirRightС,
      modelSize["x"]
    );

    return {
      leftExtremePoint: leftExtremePointС,
      rightExtremePoint: rightExtremePointС,
    };

  }

  const modelDirLeft = getDirVectorFromTransformMatrix({x: -1, y: 0, z: 0}, worldTransformModel);
  const modelDirRight = getDirVectorFromTransformMatrix({x: 1, y: 0, z: 0}, worldTransformModel);

  const leftExtremePoint = moveCoordsByVector(
    modelPos,
    modelDirLeft,
    modelSize["x"] / 2
  );
  const rightExtremePoint = moveCoordsByVector(
    modelPos,
    modelDirRight,
    modelSize["x"] / 2
  );

  return { leftExtremePoint, rightExtremePoint };
}

export interface ExtremePointsWallI {
  leftExtremePoint: ICoordinates;
  rightExtremePoint: ICoordinates;
}
export type ObjExtremePointsWallT = {
  [key in ModelCabinetWallT]: ExtremePointI;
};
/**
 * Функція для визначення крайніх точок моделей.
 * Шукає крайні точки зліва ззаду та справа ззаду для кожної моделі на сцені.
 *
 * @param {NODES_THREEKIT} modelsCabinetsRegExp regexp для Null моделей, для яких шукаємо крайні точки.
 * @returns {ObjExtremePointT} Об'ект з інформацією про крайні точки для всіх настінних моделей на сцені.
 */
export const getExtremePointsForAllModelsWall = (modelsCabinetsRegExp: NODES_THREEKIT): ObjExtremePointsWallT => {
  const allNullForСabinetsWall =
    getСompletedModelsNullNames(modelsCabinetsRegExp) as ModelCabinetWallT[];

  return allNullForСabinetsWall.reduce(
    (objExtremePointsAcc: ObjExtremePointsWallT, modelName) => {
      const objExtremePointsForModel = getExtremePointsForModelWall(modelName);
      return {
        ...objExtremePointsAcc,
        [modelName]: objExtremePointsForModel
      };
    },
    {}
  );
};