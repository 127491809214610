import React from 'react';
import { createDispatchHook, createSelectorHook, Provider } from "react-redux";
import { ModalsGlobal } from "./components/Modals/ModalsGlobal/ModalsGlobal";
import { GlobalStyle } from "./components/UtilsComponents/GlobalStyle/GlobalStyle";
import { MyRouterProvider } from "./router/RouterProvider/RouterProvider";
import store from "./store/store";
import InitializationProvider, {
  IInitializationProvider,
} from "./utils/initializationProvider/initializationProvider";
import { ThreekitProvider } from '@threekit-tools/treble';
import { onAnnotationChange } from './utils/threekit/annotation/annotationStyle';
import { useAppDispatch } from './hooks/useStoreHooks';

// const store1Context = React.createContext();
// export const useStoreDispatch = createDispatchHook(store1Context);
// export const useStoreSelector = createSelectorHook(store1Context);

const threekitEnv = 'preview';
export const InitializationProviderThreekit = ({ ...props }: IInitializationProvider) => {
  const dispatch = useAppDispatch();
  const playerConfig = {
    onAnnotationChange: (annotations: any, parentEl: any) =>
      onAnnotationChange(annotations, parentEl, dispatch),
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const shortId = params["shortId"];

  const projects = {
    products: {
      preview: {
        configurationId: shortId,
      },
    },
  };

  return (
    //@ts-ignore
    <ThreekitProvider threekitEnv={threekitEnv} playerConfig={playerConfig} project={!!shortId ? projects : {}}>
      <InitializationProvider.Provider value={{ ...props }}>
        <MyRouterProvider />
        <GlobalStyle />
        {/* <ModalsGlobal /> */}
        {/* <CabinetsBuilder /> */}
      </InitializationProvider.Provider>
    </ThreekitProvider>
  )
}

export const App = ({ ...props }: IInitializationProvider) => {
  return (
    <Provider store={store}>
      <InitializationProviderThreekit {...props} />
    </Provider>
  );
};

export const ProductConfiguration = ({ ...props }: IInitializationProvider) => (
    <App {...props} />
);
