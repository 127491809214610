import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import {
  getBoxDepthThreekit,
  getBoxWidthThreekit,
  getEvalNodeFromName,
  getItemNodeFromNullModel,
  getSceneInstanceId,
  getWorldTransformEvalNode,
  getWorldTransformEvalNodeFromId,
} from "../../general/getFunctions";
import * as THREE from "three";
import { getAllWallsNode } from "../../../../functionsConfigurator/wallsAndFloor/buildWallFromData";
import { ModelsName_NodesT, NAME_BASE_CABINET_CORNER_STUB, WallItemT } from "../../../constants/nodesNamesThreekit";
import { MoveDirectionOnWallT } from "../../../../functionsConfigurator/cabinets/cabinetsBase/moving/moveAllCabinetsBaseOnWall";
import { ArrNamesCornerWallsT } from "../../../../functionsConfigurator/cabinets/addCornerModelBase";
import { setRotationThreekit } from "../../general/setFunctions";
import { ArrWallRangesT, getSizeModelRelativeTransform } from "../../../../functionsConfigurator/intervals/getIntervalsInfoOnWall";
import { CORNER_EMPTY_BASE_SIZE_X, CORNER_EMPTY_BASE_SIZE_Z } from "../../../../functionsConfigurator/cabinets/constatns";
import { getItemNumberFromMetadataNullName } from "../../../../functionsConfigurator/cabinets/metadata";
import { getStartEndPointWall } from "../../../../functionsConfigurator/wallsAndFloor/getStartEndPointWall";

/**
 * Функція для отримаання позиції моделі в 3D-просторі.
 *
 * @param {string} modelId Id моделі з Threekit.
 * @return {THREE.Vector3} Позиція моделі.
 */
export const getModelPosition = (modelId: string): THREE.Vector3 => {
  const modelWorldTransform = getWorldTransformEvalNodeFromId(modelId);
  return new THREE.Vector3().setFromMatrixPosition(modelWorldTransform);
};

/**
 * Функція для отримаання позиції моделі в 3D-просторі.
 *
 * @param {string} name Name моделі з Threekit.
 * @return {THREE.Vector3} Позиція моделі.
 */
export const getModelPositionFromName = (name: string): THREE.Vector3 => {
  const modelWorldTransform = getWorldTransformEvalNode(name);
  return new THREE.Vector3().setFromMatrixPosition(modelWorldTransform);
};

/**
* Функція для отримання напряму стіни.
@param {THREE.Matrix4} wallMatrix - Матриця, що представляє положення і напрям стіни в 3D-просторі.
@returns {THREE.Vector3} Напрям стіни в 3D-просторі.
*/
export const getWallDirection = (wallMatrix: THREE.Matrix4): THREE.Vector3 => {
  const wallDir = new THREE.Vector3(0, 0, 1).transformDirection(wallMatrix); // change (0,1,0) to the direction the wall faces when it has no rotation
  return wallDir;
};

/**
* Функція для отримання відстані від моделі до стіни спереду.
@param {THREE.Vector3} modelPos - Положення моделі в 3D-просторі.
@param {THREE.Vector3} wallPos - Положення стіни в 3D-просторі.
@param {THREE.Vector3} wallDir - Напрям стіни в 3D-просторі.
@returns {number} Відстань від моделі до стіни спереду.
*/
export const getDistanceToFrontWall = (
  modelPos: THREE.Vector3,
  wallPos: THREE.Vector3,
  wallDir: THREE.Vector3
): number => {
  const distanceFront = modelPos.clone().sub(wallPos).dot(wallDir);
  return distanceFront;
};

/**
* Функція для отримання відстані від моделі до лівої сторони стіни.
@param {THREE.Vector3} modelPos - Положення моделі в 3D-просторі.
@param {any} boundingBoxWall - Обмежуючий паралелепіпед стіни.
@param {THREE.Vector3} wallDirPositiveX - Напрям позитивної сторони по осі X в 3D-просторі.
@returns {number} Відстань від моделі до лівої сторони стіни.
*/
export const getDistanceToLeftXWall = (
  modelPos: THREE.Vector3,
  boundingBoxWall: any,
  wallDirPositiveX: THREE.Vector3
): number => {
  const distanceLeftX = modelPos
    .clone()
    .sub({ ...boundingBoxWall.max, y: 0 })
    .dot(wallDirPositiveX);
  return distanceLeftX;
};

/**
* Функція для отримання відстані від моделі до правої сторони стіни.
@param {THREE.Vector3} modelPos - Положення моделі в 3D-просторі.
@param {any} boundingBoxWall - Обмежуючий паралелепіпед стіни.
@param {THREE.Vector3} wallDirNegativeX - Напрям позитивної сторони по осі X в 3D-просторі.
@returns {number} Відстань від моделі до правої сторони стіни.
*/
export const getDistanceToRightXWall = (
  modelPos: THREE.Vector3,
  boundingBoxWall: any,
  wallDirNegativeX: THREE.Vector3
): number => {
  const distanceLeftX = modelPos
    .clone()
    .sub({ ...boundingBoxWall["min"], y: 0 })
    .dot(wallDirNegativeX);
  return distanceLeftX;
};

/**
* Функція для перевірки моделі на кутову модель.
@param {ModelsName_NodesT} modelNullName - Item моделі з Threekit.
@returns {Boolean} true/false - є модель кутовою чи ні.
*/
export const checkIfBaseCornerCabinet = (modelNullName: ModelsName_NodesT): boolean => {
  if (modelNullName.length === 0) return false;
  // const idModel = getItemNumberFromMetadataNullName(modelNullName);
  const itemNode = getItemNodeFromNullModel({
    name: modelNullName
  })
  if (itemNode === undefined) return false;
  return (
    // idModel === "1017"
    itemNode["name"].includes("1017")
    // itemNode["name"].includes(NAME_BASE_CABINET_CORNER_STUB)
  );
};

interface WallsDistanceI {
  distanceFront: number;
  distanceLeftX: number;
  distanceRightX: number;
  width: number;
  wallDir: ICoordinates;
  wallCoordsLeft: ICoordinates;
  wallCoordsRight: ICoordinates;
}
export type ObjWallsDistanceT = {
  [key in WallItemT]: WallsDistanceI;
};

/**
* Функція для формування об'єкту відстаней від моделі до стін.
@param {objWallsForCalculateDistance} objWallsForCalculateDistance - Об'єкт значень типу ObjWallsForCalculateDistanceT для всіх стін..
@param {ICoordinates} modelPos - Item моделі з Threekit.
@returns {ObjWallsDistanceT} Об'єкт відстаней від моделі до стін.
*/
export const getObjDistance = (
  objWallsForCalculateDistance: ObjWallsForCalculateDistanceT,
  modelPos: THREE.Vector3
): ObjWallsDistanceT => {
  const arrWallsNames = Object.keys(objWallsForCalculateDistance) as Array<
    keyof typeof objWallsForCalculateDistance
  >;
  const objDistance: ObjWallsDistanceT = arrWallsNames.reduce(
    (objWallsDistance: ObjWallsDistanceT, wallName: WallItemT) => {
      const distanceFront = getDistanceToFrontWall(
        modelPos,
        objWallsForCalculateDistance[wallName]["wallPos"],
        objWallsForCalculateDistance[wallName]["wallDir"]
      );
      const distanceLeftX = getDistanceToLeftXWall(
        modelPos,
        objWallsForCalculateDistance[wallName]["boundingBoxWall"],
        objWallsForCalculateDistance[wallName]["wallDirPositiveX"]
      );
      const distanceRightX = getDistanceToLeftXWall(
        modelPos,
        objWallsForCalculateDistance[wallName]["boundingBoxWall"],
        objWallsForCalculateDistance[wallName]["wallDirNegativeX"]
      );
      const [wallCoordsLeft, wallCoordsRight] = getStartEndPointWall(
        wallName
      );

      return {
        ...objWallsDistance,
        [wallName]: {
          distanceFront: distanceFront - (objWallsForCalculateDistance[wallName]["wallDepth"]/2),
          distanceLeftX,
          distanceRightX,
          width: objWallsForCalculateDistance[wallName]["wallWidth"],
          wallDir: objWallsForCalculateDistance[wallName]["wallDir"],
          wallCoordsLeft,
          wallCoordsRight,
        },
      };
    },
    {}
  );
  return objDistance;
};

/**
* Функція для отримання масиву name для всіх стін з Threekit.
@returns {WallItemT[]} Масив name для всіх стін з Threekit.
*/
export const getArrWallsNames = (): WallItemT[] => {
  const allWalls = getAllWallsNode();
  const arrWallsNames = Object.keys(allWalls).map(
    (wallId: string) => allWalls[wallId]["name"]
  );
  return arrWallsNames;
};

export type ObjInfoWallForCalculateDistanceT = {
  boundingBoxWall: THREE.Box3;
  wallDirPositiveX: THREE.Vector3;
  wallDirNegativeX: THREE.Vector3;
  wallPos: THREE.Vector3;
  wallWidth: number;
  wallDir: THREE.Vector3;
  wallDepth: number;
};
export type ObjWallsForCalculateDistanceT = {
  [key in WallItemT]: ObjInfoWallForCalculateDistanceT;
};

/**
* Функція для створення об'єкту значень для всіх стін. Цей об'єкт використовується для визначення відстаней від моделі до стін у функції getObjDistance.
@returns {ObjWallsForCalculateDistanceT} Об'єкт значень типу ObjWallsForCalculateDistanceT для всіх стін.
*/
export const getObjWallsForCalculateDistance =
  (): ObjWallsForCalculateDistanceT => {
    const arrWallsNames = getArrWallsNames();
    const wallsObjForCalculateDistance = arrWallsNames.reduce(
      (
        objForCalculateDistance: ObjWallsForCalculateDistanceT,
        wallName: string
      ) => {
        const evalNodeWall = getEvalNodeFromName(wallName);
        //@ts-ignore
        const boundingBoxWall = evalNodeWall.getBoundingBox();
        //@ts-ignore
        const wallMatrix = evalNodeWall.worldTransform;
        const wallWidth = getBoxWidthThreekit({
          from: getSceneInstanceId(),
          name: wallName,
        });
        const wallDepth = getBoxDepthThreekit({
          from: getSceneInstanceId(),
          name: wallName,
        });

        const wallDir = getWallDirection(wallMatrix);
        const wallDirPositiveX = new THREE.Vector3(1, 0, 0).transformDirection(
          wallMatrix
        );
        const wallDirNegativeX = new THREE.Vector3(-1, 0, 0).transformDirection(
          wallMatrix
        );
        const wallPos = new THREE.Vector3().setFromMatrixPosition(wallMatrix);

        return {
          ...objForCalculateDistance,
          [wallName]: {
            boundingBoxWall,
            wallDirPositiveX,
            wallDirNegativeX,
            wallPos,
            wallWidth,
            wallDir,
            wallDepth,
          },
        };
      },
      {}
    );
    return wallsObjForCalculateDistance;
  };

/**
* Функція створює масив відстаней від моделі до внутрішньої сторони стіни.
@param {ObjWallsDistanceT} objDistance - Об'єкт відстаней від моделі до стін.
@returns {number[]} Масив відстаней від моделі до внутрішньої сторони стіни.
*/
export const arrAllDistanceFromModelToFrontWall = (
  objDistance: ObjWallsDistanceT
): number[] => {
  return Object.values(objDistance).map(
    (objWallDistance) => objWallDistance["distanceFront"]
  );
};

/**
* Функція створює масив відстаней від моделі до внутрішньої сторони стіни, які більіше нуля.
* Тобто це масив відстаней до передньої сторони стіни (прибираємо відстані до задньої сторони стіни).
*
@param {ObjWallsDistanceT} objDistance - Об'єкт відстаней від моделі до стін.
@returns {number[]} Масив відстаней від моделі до внутрішньої сторони стіни.
*/
export const arrPositiveDistanceFromModelToFrontWall = (
  objDistance: ObjWallsDistanceT
): number[] => {
  const arrPositiveAllDistanceFront: number[] = [];
  Object.values(objDistance).forEach((objWallDistance) => {
    const distanceFront = objWallDistance["distanceFront"];
    if (distanceFront >= 0) arrPositiveAllDistanceFront.push(distanceFront);
  });
  return arrPositiveAllDistanceFront;
};

/**
* Функція повертає name стіни, від якої модель знаходиться на більшій відстані.
*
@param {ObjWallsDistanceT} cornerDistance - Об'єкт для двух стін, які формують кут. (Модель наблизилась до цього кута)
@returns {WallItemT} Name стіни, від якої модель знаходиться на більшій відстані.
*/
export const getWallNameFromMaxDistanceInCorner = (
  cornerDistance: ObjWallsDistanceT
): WallItemT => {
  const arrWallNameFromObjCornerWalls = Object.keys(cornerDistance) as Array<
    keyof typeof cornerDistance
  >;
  const maxDistanceWallName = arrWallNameFromObjCornerWalls.reduce(
    (maxDistanceWallName: WallItemT, wallName: WallItemT) => {
      const distanceFront = cornerDistance[wallName]["distanceFront"];
      const maxDistance = cornerDistance[maxDistanceWallName]["distanceFront"];
      return maxDistance > distanceFront ? maxDistanceWallName : wallName;
    },
    arrWallNameFromObjCornerWalls[0]
  );
  return maxDistanceWallName;
};

/**
* Повертає сторону на стіні (ліва або права), на якій потрібно визначити присутність моделей.
* Якщо моделі з обраної сторони на зазначеній відстані від кута присутні,
* то всі моделі на стіні потрібно зсунути, щоб в кут стала кутова модель.
*
@param {WallItemT} wallName - Name стіни, для якої маємо шукати вільний чи зайнятий проміжок біля кута
@param {WallItemT[]} cornerWallsSortedLeftRight - Масив з імен для двух стін, які формують кут (відфільтровані зліва - направо)
@returns {MoveDirectionOnWallT} Сторона стіни, на якій потрібно визначити наявніть моделей.
*/
export const getDirectionIntervalEmpty = (
  wallName: WallItemT,
  cornerWallsSortedLeftRight: WallItemT[]
): MoveDirectionOnWallT => {
  let directionIntervalEmpty: MoveDirectionOnWallT = "left";
  if (wallName === cornerWallsSortedLeftRight[1]) {
    directionIntervalEmpty = "left";
  } else if (wallName === cornerWallsSortedLeftRight[0]) {
    directionIntervalEmpty = "right";
  }
  return directionIntervalEmpty;
};

/**
* Повертає відстань, на яку потрібно зсунути всі моделі на стіні, щоб вмістився кутовий елемент.
*
@param {MoveDirectionOnWallT} moveDirection - Напрям, в якому потрібно зсунути моделі на стіні.
@param {number} baseOffsetDistance - Базова дистанція, яка дорівнює ширині кутової моделі
@param {ArrWallRangesT} intervalsForWall - Массив інтервалів для стіни
@returns {number} Відстань, на яку потрібно зсунути всі моделі на стіні, щоб вмістився кутовий елемент.
*/
export const getMoveDistanceModels = (
  moveDirection: MoveDirectionOnWallT,
  baseOffsetDistance: number,
  intervalsForWall: ArrWallRangesT
): number => {
  // let offsetDistance = baseOffsetDistance;

  let moveDistance: number = 0;
  if (moveDirection === "right") {
    const interval = intervalsForWall[0];
    moveDistance = interval["empty"]
      ? CORNER_EMPTY_BASE_SIZE_X - interval["range"][1]
      : CORNER_EMPTY_BASE_SIZE_X;
  } else {
    const interval = intervalsForWall[intervalsForWall.length - 1];
    moveDistance = interval["empty"]
      ? CORNER_EMPTY_BASE_SIZE_Z - (interval["range"][1] - interval["range"][0])
      : CORNER_EMPTY_BASE_SIZE_X;
  }
  return moveDistance;
};

/**
 * Повертає назви кутових стін відсортовані в порядку зліва направо [ліва стіна, права стіна].
 *
 * @param {ObjWallsDistanceT} cornerDistance Об'єкт дистанцій для кутових стін.
 * @return {ArrNamesCornerWallsT} cornerDistanceSorted; Назви кутових стін відсортовані в порядку зліва направо.
 */
export const getCornerDistanceSorted = (
  cornerDistance: ObjWallsDistanceT
): ArrNamesCornerWallsT => {
  const arrWallNameFromObjCornerDistance = Object.keys(cornerDistance) as Array<
    keyof typeof cornerDistance
  >;
  const cornerDistanceSorted = arrWallNameFromObjCornerDistance.sort(
    (wallNameA, wallNameB) => {
      const wallAWorldTransform = getWorldTransformEvalNode(wallNameA);
      const wallBWorldTransform = getWorldTransformEvalNode(wallNameB);

      const wallAPos = new THREE.Vector3().setFromMatrixPosition(
        wallAWorldTransform
      );
      const wallBPos = new THREE.Vector3().setFromMatrixPosition(
        wallBWorldTransform
      );

      let wallAAngle = (Math.atan2(wallAPos.x, wallAPos.z) * 180) / Math.PI;
      let wallBAngle = (Math.atan2(wallBPos.x, wallBPos.z) * 180) / Math.PI;

      if (wallAAngle < 0) wallAAngle = wallAAngle + 360;
      if (wallBAngle < 0) wallBAngle = wallBAngle + 360;

      if (wallBAngle === 0) wallBAngle = 360;

      if (
        (wallBAngle <= 90 && wallAAngle >= 270) ||
        (wallAAngle <= 90 && wallBAngle >= 270)
      ) {
        return wallAAngle - wallBAngle;
      }

      return wallBAngle - wallAAngle;
    }
  );
  return cornerDistanceSorted;
};

/**
 * Повертає відфільтрований об'єкт objDistance дистанцій, в якому залишаються тільки дистанції для стін в куті.
 * Якщо кількість елементів в об'єкті дорівнює 2, це значить що модель наблизилась в зону кута.
 * Дистанція до кута рахується з урахуванням розміру моделі.
 *
 * @param {ObjWallsDistanceT} objDistance Об'єкт дистанцій до всіх стін кімнати.
 * @param {ICoordinates} modelSize Розмір моделі.
 * @return {ObjWallsDistanceT} cornerDistance; Відфільтрований об'єкт objDistance, в якому є дистанції для стін в кут яких наближається модель.
 */
export const checkApproachToCorner = (
  objDistance: ObjWallsDistanceT,
  modelSize: ICoordinates
) => {
  const modelWidth = modelSize["x"];
  const modelDepth = modelSize["z"];

  // об'єкт, який буде містити відстані моделі до стін в кутах
  const cornerDistance: ObjWallsDistanceT = {};

  // масив імен стін
  const arrWallNameFromObjDistance = Object.keys(objDistance) as Array<
    keyof typeof objDistance
  >;

  arrWallNameFromObjDistance.forEach((wallName: WallItemT) => {
    const distanceFront = objDistance[wallName]["distanceFront"];
    const distancePositiveX = objDistance[wallName]["distanceLeftX"];
    const distanceNegativeX = objDistance[wallName]["distanceRightX"];
    const wallWidth = objDistance[wallName]["width"];

    // todo тут треба напевно враховувати прижимну планку
    // Перевіряємо, чи модель перебуває в зоні кута, обчислюємо відстань моделі до стін кута
    if (
      // Math.abs(distanceFront) <= modelDepth * 2 &&
      Math.abs(distanceFront) <= (modelWidth / 2) + modelDepth + (modelDepth / 2) &&
      Math.abs(distancePositiveX) < wallWidth &&
      Math.abs(distanceNegativeX) < wallWidth
    ) {
      // Якщо модель перебуває в діапазоні бічних стін, то додаємо в об'єкт cornerDistance відстань до поточної стіни
      cornerDistance[wallName] = objDistance[wallName];
    }
  });

  // Повертаємо об'єкт відстаней моделі до кутів
  return cornerDistance;
};

/**
 * Повертає модель задньою стороною до стіни, враховуючи напрям стіни.
 *
 * @param {string} modelId Id моделі з Threekit.
 * @param {ICoordinates} wallDir Вектор, який розташований вздовж стіни.
 * @param {boolean} sideNegative Вказує на те що модель знаходиться з задньої сторони стіни (для випадку внутрішніх стін в кімнаті).
 */
export const rotateObjBackToWall = (
  modelId: string,
  wallDir: ICoordinates,
  sideNegative?: boolean
) => {
  const wallAngle = (Math.atan2(wallDir.x, wallDir.z) * 180) / Math.PI;
  setRotationThreekit({
    id: modelId,
    //@ts-ignore
    value: { x: 0, y: !!sideNegative ? wallAngle + 180 : wallAngle, z: 0 },
  });
};

/**
 * Функція перевіряє позицію моделі на розташування в куті.
 *
 * @param {ModelsName_NodesT} nullNodeName Об'єкт дистанцій до всіх стін кімнати.
 * @return {ObjWallsDistanceT | undefined} cornerDistance;
 * undefined - якщо модель не знаходиться в куті.
 * ObjWallsDistanceT - об'єкт objDistance(length = 2), в якому є дистанції для стін в куті яких розташовується модель.
 */
export const checkModelPositionInCorner = (nullNodeName: ModelsName_NodesT): ObjWallsDistanceT | undefined => {
  const modelPos = getModelPositionFromName(nullNodeName);
  const modelSize = getSizeModelRelativeTransform(nullNodeName);
  const objWallsForCalculateDistance = getObjWallsForCalculateDistance();
  const objDistance = getObjDistance(objWallsForCalculateDistance, modelPos);
  const cornerDistance = checkApproachToCorner(objDistance, modelSize);
  if (Object.keys(cornerDistance).length !== 2) return undefined;
  return cornerDistance;
}