import axios, { AxiosRequestConfig } from "axios";

const EMAIL_ADDRESS = "osh.elementalsweb@gmail.com";
const REACT_APP_ELEMENTALS_API_URL = process.env.REACT_APP_ELEMENTALS_API_URL;

interface ObjParamsSendEmailI {
  pdf: any;
  link: string;
  name: string;
  id: string;
  mail: string;
}
export const sendEmailGetRequest = async (objParams: ObjParamsSendEmailI): Promise<any> => {

  const url = `${REACT_APP_ELEMENTALS_API_URL}/sendMail`;

  // console.log('objParams --- ==== ',objParams);

  var form_data = new FormData();

  for ( var key in objParams ) {
    //@ts-ignore
    form_data.append(key, objParams[key]);
  }

  return axios.post(url, form_data, { headers: { accept: "Accept: Content-Type" } })
    .then(response => {
      return response.data;
    })
    .then((data: any) => {
      console.log('Data: --- ==== ',data);
      return data;
    })
    .catch(error => {
      console.error('Error: ==== ---- ', error);
    });

}