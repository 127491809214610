import { WallItemT } from "../../../../utils/constants/nodesNamesThreekit";
import {
  ModelCabinetBaseT,
  NODES_THREEKIT,
} from "../../../../utils/constants/nodesNamesThreekit";
import {
  getBoxWidthThreekit,
  getRotationThreekit,
  getSceneInstanceId,
} from "../../../../utils/threekit/general/getFunctions";
import {
  setRotationThreekit,
  setTranslationThreekit,
} from "../../../../utils/threekit/general/setFunctions";

import { getNumberNodeThreekitFromName } from "../../../general";
import {
  ArrWallRangesT,
  WallRangeT,
  addIntervalToArrIntervals,
  checkCornerIntervalEmpty,
  getIntervalForModel,
} from "../../../intervals/getIntervalsInfoOnWall";
import { getIntervalsInfoOnWallForCabinetsBase } from "../../../intervals/getIntervalsInfoOnWallBase";
import { moveCoordsByVector } from "../../../wallsAndFloor/buildWallFromData";
import {
  getPlaneNameInFrontCamera,
  getWallInFrontCamera,
} from "../../../wallsAndFloor/getWallInFrontCamera";
import {
  getLeftRigthDirectionsPlane,
  getNeighborPlanes,
  getStartEndCoordsPlane,
} from "../../../wallsAndFloor/getWallPlanesInfo";
import { getModelsBaseNullOnWall } from "../../getNodesCabinets";
import { ActiveAndNewValuesThreekitT } from "../../getObjActiveAndNewValuesAttributeThreekit";
import { getSizeModelBoxFromAssetCabinetBase } from "../size";

/**
 * При додаванні моделей Cabinets Base (шкафи, що розміщуються на підлозі, але не островні),
 * розміщує моделі таким чином, щоб вони не конфліктувати (не перетинались) одна з одною
 * і розміщувались біля стіни.
 *
 * @param {ActiveAndNewValuesThreekitT} objAssetIdValuesCabinetsWall Об'єкт з массивом вже встановлених моделей в атрибут типу массив - objAssetIdValuesCabinetsWall["activeValues"],
 * та массив нових значень(які потрібно додати на сцену) - objAssetIdValuesCabinetsWall["activeValues"].
 */
export const setPositionCabinetsBaseOnWall = (
  objAssetIdValuesCabinetsWall: ActiveAndNewValuesThreekitT
) => {
  const { activeValues, newValues } = objAssetIdValuesCabinetsWall;
  const currentWallName = getWallInFrontCamera();
  const currentPlaneName = getPlaneNameInFrontCamera();
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });
  const {planeLeftName, planeRightName} = getNeighborPlanes(currentPlaneName);
  // // console.log('planeLeftName --- ==== ',planeLeftName);
  // // console.log('planeRightName --- ==== ',planeRightName);
  const currentPlaneCabinetsRotation = getRotationThreekit({
    from: getSceneInstanceId(),
    name: currentPlaneName,
  });
  const { leftDirectionPlane, rightDirectionPlane} = getLeftRigthDirectionsPlane(currentPlaneName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  const modelsBaseNullOnWall = getModelsBaseNullOnWall(currentWallName);
  let intervalsInfoOnWall: ArrWallRangesT = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, currentPlaneName);;

  for (let i = activeValues.length; i < activeValues.length + newValues.length; i++) {
    const nextPointName: ModelCabinetBaseT = `${NODES_THREEKIT.MODEL_CABINET_BASE}${i}`;
    // const newModelSize = getSizeModelRelativeTransform(nextPointName);
    const newModelSize = getSizeModelBoxFromAssetCabinetBase(nextPointName);
    console.log('newModelSize --- ==== ',newModelSize);
    const newModelWidth = newModelSize["x"];
    const newModelDepth = newModelSize["z"];
    const newModelHeight = newModelSize["y"];

    let offsetDistance = newModelWidth/2;
    
    // в cornerOffset ще потрібно додати ширину прижимної планки
    const cornerOffset = newModelDepth;
    // якщо позиція нової моделі знаходиться близько до сусідньої стіни зліва
    // то перевіряємо чи не стоїть на сусідній зліва стіні модель в спільному куті
    if (!!planeLeftName) { // && offsetDistance <= cornerOffset
      const wallNum = getNumberNodeThreekitFromName(planeLeftName);
      const wallLeftName: WallItemT = `${NODES_THREEKIT.WALL_ITEM}${wallNum}`;
      const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallLeftName);
      const intervalsInfoOnWallLeft = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, planeLeftName);
      const isCornerIntervalEmpty = checkCornerIntervalEmpty(intervalsInfoOnWallLeft, cornerOffset, "right");
      // якщо кут на сусідній стіні зайнятий,
      // то в масив проміжків додаємо новий проміжок розміром ModelDepth + ширина добору
      if (!isCornerIntervalEmpty) {
        const newInterval: WallRangeT = {
          empty: false,
          range: [0, cornerOffset]
        }
        intervalsInfoOnWall = addIntervalToArrIntervals(intervalsInfoOnWall, newInterval);
      }
    }

    // якщо позиція нової моделі знаходиться близько до сусідньої стіни справа
    // то перевіряємо чи не стоїть на сусідній стіні справа модель в спільному куті
    if (!!planeRightName) { // && offsetDistance >= currentPlaneWidth - cornerOffset
      const wallNum = getNumberNodeThreekitFromName(planeRightName);
      const wallLeftName: WallItemT = `${NODES_THREEKIT.WALL_ITEM}${wallNum}`;
      const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallLeftName);
      const intervalsInfoOnWallRight = getIntervalsInfoOnWallForCabinetsBase(modelsBaseNullOnWall, planeRightName);
      const isCornerIntervalEmpty = checkCornerIntervalEmpty(intervalsInfoOnWallRight, cornerOffset, "left");
      // якщо кут на сусідній стіні зайнятий,
      // то в масив проміжків додаємо новий проміжок розміром ModelDepth + ширина добору
      if (!isCornerIntervalEmpty) {
        const newInterval: WallRangeT = {
          empty: false,
          range: [currentPlaneWidth - cornerOffset, currentPlaneWidth]
        }
        intervalsInfoOnWall = addIntervalToArrIntervals(intervalsInfoOnWall, newInterval);
      }
    }

    const intervalForModel = getIntervalForModel(intervalsInfoOnWall, newModelWidth);
    if (intervalForModel !== undefined) {
      // offsetDistance = intervalForModel["range"][0] + newModelWidth/2;
      offsetDistance = (currentPlaneWidth - intervalForModel["range"][1]) + newModelWidth/2;
    }

    // const movedCoordsModel = moveCoordsByVector(
    //   { ...currentPlaneCoordsLeft, y: 0},
    //   rightDirectionPlane,
    //   offsetDistance
    // );
    const movedCoordsModel = moveCoordsByVector(
      { ...currentPlaneCoordsRight, y: 0},
      leftDirectionPlane,
      offsetDistance
    );

    setTranslationThreekit({
      from: getSceneInstanceId(),
      name: nextPointName,
      value: movedCoordsModel,
    });
    setRotationThreekit({
      from: getSceneInstanceId(),
      name: nextPointName,
      value: {
        x: 0,
        y: currentPlaneCabinetsRotation["y"],
        z: 0,
      },
    });

    // створюємо проміжок для щойно доданої моделі і додаємо його в масив проміжків intervalsInfoOnWall
    // для того щом наступна модель з массиву newValues враховувала проміжок попередньої моделі
    const intervalForNewModel: WallRangeT = {
      empty: false,
      range: [offsetDistance - newModelWidth/2, offsetDistance + newModelWidth/2],
      name: nextPointName,
    }
    intervalsInfoOnWall = addIntervalToArrIntervals(intervalsInfoOnWall, intervalForNewModel);

  }

};