import { ISceneResult } from "@threekit-tools/treble/dist/types";
import { ModelCabinetBaseT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { checkIfCornerEmptyCabinetBase } from "../getExtremeModels";
import { getСompletedEvalNodeModels } from "./../getNodesCabinets";
import { Dispatch } from "@reduxjs/toolkit";
import { removeModels } from "../../../store/actions/player.action";
import { getDistanceFromCornerEmtyBaseBoxToCabinetsBase } from "./distanceBox";
import { setActiveTagsThreekit } from "../../../utils/threekit/general/setFunctions";
import { CONNECTORS_CABINETS_BASE } from "../../../utils/constants/connectors";

export const getEvalNodesCornerEmptyCabinetsBase = (): ISceneResult[] => {
  const allEvalNodeCabinetsBase = getСompletedEvalNodeModels(NODES_THREEKIT.MODEL_CABINET_BASE);
  let arrEvalNodeCornerEmpty: ISceneResult[] = [];
  allEvalNodeCabinetsBase.forEach((evalNode) => {
    const modelItem = getItemNodeFromNullModel({ name: evalNode["name"] });
    if (checkIfCornerEmptyCabinetBase(modelItem)) {
      arrEvalNodeCornerEmpty.push(evalNode);
    }
  });
  return arrEvalNodeCornerEmpty
}

export const checkCornersEmptyRemoveCabinetsBase = (dispatch: Dispatch) => {
  const arrEvalNodesCornerEmptyCabinetsBase = getEvalNodesCornerEmptyCabinetsBase();
  arrEvalNodesCornerEmptyCabinetsBase.forEach((evalNodeCornerEmpty) => {
    const arrModelsNamesWithShortDistances = getDistanceFromCornerEmtyBaseBoxToCabinetsBase(evalNodeCornerEmpty["name"] as ModelCabinetBaseT);
    if (arrModelsNamesWithShortDistances.length !== 2) {
      //@ts-ignore
      const instanceIdCornerEmptyCabinetsBase = evalNodeCornerEmpty["Null"]["link"]["Proxy"]["proxy"]["instanceId"];
      setActiveTagsThreekit({
        from: instanceIdCornerEmptyCabinetsBase,
        name: CONNECTORS_CABINETS_BASE.LEFT,
        value: false,
      })
      setActiveTagsThreekit({
        from: instanceIdCornerEmptyCabinetsBase,
        name: CONNECTORS_CABINETS_BASE.RIGHT,
        value: false,
      })
      dispatch(removeModels([evalNodeCornerEmpty["name"]]));
    }
  })
}