import axios from "axios";

const PUBLIC_TOKEN = process.env.REACT_APP_THREEKIT_PREVIEW_PUBLIC_TOKEN;
const THREEKIT_URL = process.env.REACT_APP_THREEKIT_URL;
const CONFIGURATIONS_API_ROUTE = `api/configurations`;

export const getSavedConfiguration = async ({ ...props }) => {
  const { reconfigurationShortId } = props;

  let error;
  if (!reconfigurationShortId) error = "Requires Configuration ID";
  if (error) return [undefined, { message: error }];

  let response = await axios
    .get(
      `${THREEKIT_URL}/${CONFIGURATIONS_API_ROUTE}/${reconfigurationShortId}?bearer_token=${PUBLIC_TOKEN}`
    )
    .then((response) => response);

  return response["data"];
};