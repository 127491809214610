import { IMetadata } from "@threekit-tools/treble/dist/types";
import { ModelCabinetWallT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel, getValuesMetadataItemFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { isEqualCoordsTolerance } from "../addCornerModelBase";
import { checkIfCornerCabinet, getExtremePointsForAllModelsWall, ObjExtremePointsWallT } from "./extremePoints";
import { checkTypeConnectingInCorner } from "./getTypeConnectingCabinetsWall";
import { getSizeBoxForAllCabinetsWall, ObjSizeCabinetsWallT } from "./size";

const DIFF_SIZE = 0.01;

/**
 * Функція для отримання загальної довжини верхнього молдінгу для шафи.
 *
 * @param {ModelCabinetWallT} modelNullNameCurrent Null Name для настінної моделі(шкафа).
 * @param {ObjExtremePointsWallT} objExtremePointsForModels Об'ект з інформацією про крайні точки для всіх настінних моделей на сцені.
 * @param {ObjSizeCabinetsWallT} objSizeForAllModelsWall Об'ект з інформацією про розміри всіх встановлених на сцені настінних моделей.
 * @returns {Number} Довжина верхнього молдингу для шафи.
 */
export const getLengthMouldingTopForModel = (
  modelNullNameCurrent: ModelCabinetWallT,
  objExtremePointsForModels: ObjExtremePointsWallT,
  objSizeForAllModelsWall: ObjSizeCabinetsWallT,
): number => {
  const objExtremePointsForModelCurrent = objExtremePointsForModels[modelNullNameCurrent];
  const modelSizeCurrent = objSizeForAllModelsWall[modelNullNameCurrent];
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: modelNullNameCurrent})
  const metadataItem: IMetadata = getValuesMetadataItemFromNullModel({name: modelNullNameCurrent});
  const isCornerCabinet = checkIfCornerCabinet(itemNodeFromNullModel);
  const extremePointsForModelsKeys = Object.keys(
    objExtremePointsForModels
  ) as Array<keyof typeof objExtremePointsForModels>;

  let lengthFrontMoulding: number = !isCornerCabinet ? modelSizeCurrent["x"] : metadataItem["sizeMouldingFront"] as number;
  let lengthSideMouldingLeft: number = 0;
  let lengthSideMouldingRight: number = 0;
  let isLeftPointFree = true;
  let isRightPointFree = true;

  extremePointsForModelsKeys.forEach((modelNullName) => {
    if (modelNullName !== modelNullNameCurrent) {
      const { leftExtremePoint, rightExtremePoint } =
        objExtremePointsForModels[modelNullName];
      const size = objSizeForAllModelsWall[modelNullName];
      const diffDepthSize = modelSizeCurrent["z"] - size["z"];
      const isEqualCoordsLeft = isEqualCoordsTolerance(
        { ...objExtremePointsForModelCurrent["leftExtremePoint"], y: 0 },
        { ...rightExtremePoint, y: 0 },
        0.1
      );
      const isEqualCoordsRight = isEqualCoordsTolerance(
        { ...objExtremePointsForModelCurrent["rightExtremePoint"], y: 0 },
        { ...leftExtremePoint, y: 0 },
        0.1
      );

      if (isEqualCoordsLeft) {
        if (diffDepthSize > DIFF_SIZE && !isCornerCabinet) {
          lengthSideMouldingLeft = diffDepthSize
        }
        isLeftPointFree = false;
      }

      if (isEqualCoordsRight) {
        if (diffDepthSize > DIFF_SIZE && !isCornerCabinet) {
          lengthSideMouldingRight = diffDepthSize
        }
        isRightPointFree = false;
      }
    }
  });

  // check Corner position
  const cornerTypeConnecting = checkTypeConnectingInCorner(
    modelNullNameCurrent,
    modelSizeCurrent
  );
  if (!!cornerTypeConnecting && !isCornerCabinet) {
    if (cornerTypeConnecting === "left") isLeftPointFree = false;
    if (cornerTypeConnecting === "right") isRightPointFree = false;
  }

  if (isLeftPointFree) lengthSideMouldingLeft = !isCornerCabinet ? modelSizeCurrent["z"] : metadataItem["sizeMouldingSide"] as number;
  if (isRightPointFree) lengthSideMouldingRight = !isCornerCabinet ? modelSizeCurrent["z"] : metadataItem["sizeMouldingSide"] as number;

  const resultLengthMoulding = lengthFrontMoulding + lengthSideMouldingLeft + lengthSideMouldingRight;

  return resultLengthMoulding;
}

/**
 * Функція для отримання загальної довжини нижнього молдінгу для моделі.
 *
 * @param {ModelCabinetWallT} modelNullNameCurrent Null Name для настінної моделі(шкафа).
 * @param {ObjExtremePointsWallT} objExtremePointsForModels Об'ект з інформацією про крайні точки для всіх настінних моделей на сцені.
 * @param {ObjSizeCabinetsWallT} objSizeForAllModelsWall Об'ект з інформацією про розміри всіх встановлених на сцені настінних моделей.
 * @returns {Number} Довжина нижнього молдингу для шафи.
 */
export const getLengthMouldingBottomForModel = (
  modelNullNameCurrent: ModelCabinetWallT,
  objExtremePointsForModels: ObjExtremePointsWallT,
  objSizeForAllModelsWall: ObjSizeCabinetsWallT,
): number => {
  const objExtremePointsForModelCurrent = objExtremePointsForModels[modelNullNameCurrent];
  const modelSizeCurrent = objSizeForAllModelsWall[modelNullNameCurrent];
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: modelNullNameCurrent})
  const metadataItem: IMetadata = getValuesMetadataItemFromNullModel({name: modelNullNameCurrent});
  const isCornerCabinet = checkIfCornerCabinet(itemNodeFromNullModel);
  const extremePointsForModelsKeys = Object.keys(
    objExtremePointsForModels
  ) as Array<keyof typeof objExtremePointsForModels>;

  let lengthFrontMoulding: number = !isCornerCabinet ? modelSizeCurrent["x"] : metadataItem["sizeMouldingFront"] as number;
  let lengthSideMouldingLeft: number = 0;
  let lengthSideMouldingRight: number = 0;
  let isLeftPointFree = true;
  let isRightPointFree = true;

  extremePointsForModelsKeys.forEach((modelNullName) => {
    if (modelNullName !== modelNullNameCurrent) {
      const { leftExtremePoint, rightExtremePoint } =
        objExtremePointsForModels[modelNullName];
      const size = objSizeForAllModelsWall[modelNullName];
      const diffDepthSize = modelSizeCurrent["z"] - size["z"];
      const diffHeightSize = modelSizeCurrent["y"] - size["y"];
      const isEqualCoordsLeft = isEqualCoordsTolerance(
        { ...objExtremePointsForModelCurrent["leftExtremePoint"], y: 0 },
        { ...rightExtremePoint, y: 0 },
        0.1
      );
      const isEqualCoordsRight = isEqualCoordsTolerance(
        { ...objExtremePointsForModelCurrent["rightExtremePoint"], y: 0 },
        { ...leftExtremePoint, y: 0 },
        0.1
      );

      if (isEqualCoordsLeft) {
        if (diffHeightSize > DIFF_SIZE && !isCornerCabinet) {
          lengthSideMouldingLeft = modelSizeCurrent["z"]
        }
        if (diffHeightSize < DIFF_SIZE && diffDepthSize > DIFF_SIZE && !isCornerCabinet) {
          lengthSideMouldingLeft = diffDepthSize;
        }
        isLeftPointFree = false;
      }

      if (isEqualCoordsRight) {
        if (diffHeightSize > DIFF_SIZE && !isCornerCabinet) {
          lengthSideMouldingRight = modelSizeCurrent["z"]
        }
        if (diffHeightSize < DIFF_SIZE && diffDepthSize > DIFF_SIZE && !isCornerCabinet) {
          lengthSideMouldingRight = diffDepthSize;
        }
        isRightPointFree = false;
      }
    }
  });

  // check Corner position
  const cornerTypeConnecting = checkTypeConnectingInCorner(
    modelNullNameCurrent,
    modelSizeCurrent
  );
  if (!!cornerTypeConnecting && !isCornerCabinet) {
    if (cornerTypeConnecting === "left") isLeftPointFree = false;
    if (cornerTypeConnecting === "right") isRightPointFree = false;
  }

  if (isLeftPointFree) lengthSideMouldingLeft = !isCornerCabinet ? modelSizeCurrent["z"] : metadataItem["sizeMouldingSide"] as number;
  if (isRightPointFree) lengthSideMouldingRight = !isCornerCabinet ? modelSizeCurrent["z"] : metadataItem["sizeMouldingSide"] as number;

  const resultLengthMoulding = lengthFrontMoulding + lengthSideMouldingLeft + lengthSideMouldingRight;

  return resultLengthMoulding;
}

export interface ObjMouldingTopBottomLengthI {
  lengthMouldingTop: number;
  lengthMouldingBottom: number;
};
export type ObjMouldingLengthCabinetsWallT = {
  [key in ModelCabinetWallT]: ObjMouldingTopBottomLengthI;
};
/**
 * Функція для отримання загальної довжини верхнього та нижнього молдінгів по усіх шкафах.
 *
 * @returns {ObjMouldingLengthCabinetsWallT} Об'єкт, який описує загальні довжини верхнього та нижнього молдингів для кожної шафи.
 */
export const getMouldingLengthCabinetsWall = (): ObjMouldingLengthCabinetsWallT => {
  const objExtremePointsForModels = getExtremePointsForAllModelsWall(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );
  const objSizeForAllModelsWall = getSizeBoxForAllCabinetsWall();

  const extremePointsForModelsKeys = Object.keys(
    objExtremePointsForModels
  ) as Array<keyof typeof objExtremePointsForModels>;

  const lengthMouldingTopAndBottom = extremePointsForModelsKeys.reduce(
    (
      objExtremeModelsInfoAcc: ObjMouldingLengthCabinetsWallT,
      modelNullNameCurrent
    ) => {

      let lengthMouldingTop = getLengthMouldingTopForModel(
        modelNullNameCurrent,
        objExtremePointsForModels,
        objSizeForAllModelsWall,
      );

      const lengthMouldingBottom = getLengthMouldingBottomForModel(
        modelNullNameCurrent,
        objExtremePointsForModels,
        objSizeForAllModelsWall,
      );

      return {
        ...objExtremeModelsInfoAcc,
        [modelNullNameCurrent]: {
          lengthMouldingTop,
          lengthMouldingBottom,
        },
      };
    },
    {}
  );

  return lengthMouldingTopAndBottom;
}