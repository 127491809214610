import { IConfigurationArray } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit"
import { ATTRIBUTE_NAMES } from "../../utils/constants/attributesThreekitRoomBuilder";
import { getValueAttributeThreekit } from "../../utils/threekit/general/getFunctions"
import { CabinetsAndFeatures_NodesT, FeaturesName_NodesT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";

export const getWallConnections = (wallIndex: number): string[] => {
  const valueAttributeWalls = getValueAttributeThreekit(ATTRIBUTES_NAMES_THREEKIT.WALLS) as IConfigurationArray;
  const wallConfiguration = valueAttributeWalls[wallIndex]["configuration"];
  if (wallConfiguration !== undefined && typeof wallConfiguration !== 'string') {
    return JSON.parse(String(wallConfiguration[ATTRIBUTE_NAMES.wallConnections]));
  }
  return [];
}

export const getArrNullNamesWallConnections = (wallIndex: number): FeaturesName_NodesT[] => {
  const wallConnections = getWallConnections(wallIndex);

  if (wallConnections.length < 1) return [];

  let arrNullNamesWallConnections: FeaturesName_NodesT[] = []
  wallConnections.forEach((featureName) => {
    const featureNameNull = featureName.split(".").join("_") as FeaturesName_NodesT;
    arrNullNamesWallConnections.push(featureNameNull)
  })

  return arrNullNamesWallConnections;

}

export const filteringBaseFeatures = (arrNullNamesWallConnections: FeaturesName_NodesT[]): FeaturesName_NodesT[] => {
  return arrNullNamesWallConnections.filter(nullNameFeature => {
    return nullNameFeature.includes(NODES_THREEKIT.MODEL_DOOR) || nullNameFeature.includes(NODES_THREEKIT.MODEL_OPENING);
  })
}

export const isFeaturesModelNullName = (modelNullName: CabinetsAndFeatures_NodesT) => {
  return modelNullName.includes(NODES_THREEKIT.MODEL_WINDOW) ||
    modelNullName.includes(NODES_THREEKIT.MODEL_DOOR) ||
    modelNullName.includes(NODES_THREEKIT.MODEL_OPENING)
}