import * as THREE from "three";
import { ATTRIBUTE_NAMES } from "../../utils/constants/attributesThreekitRoomBuilder";

export const getStartPointFromConfigurationWall = (wallValue: any): THREE.Vector2 => {
  return new THREE.Vector2(
    wallValue["configuration"][ATTRIBUTE_NAMES.wallStartX],
    wallValue["configuration"][ATTRIBUTE_NAMES.wallStartZ],
  );
}

export const getEndPointFromConfigurationWall = (wallValue: any): THREE.Vector2 => {
  return new THREE.Vector2(
    wallValue["configuration"][ATTRIBUTE_NAMES.wallEndX],
    wallValue["configuration"][ATTRIBUTE_NAMES.wallEndZ],
  );
}

export const getWallLength = (startPoint: THREE.Vector2, endPoint: THREE.Vector2): number => {
  return Math.sqrt((endPoint["x"] - startPoint["x"])**2 + (endPoint["y"] - startPoint["y"])**2);
}

export interface IntervalWallI {
  start: THREE.Vector2;
  end: THREE.Vector2;
}
export type IntervalsWallsT = IntervalWallI[];
export const getArrIntervalsWalls = (
  wallAttributeValue: any[],
): IntervalsWallsT => {
  return wallAttributeValue.reduce((accWallsSegments: any[], wallValue: any) => {
    const start = getStartPointFromConfigurationWall(wallValue);
    const end = getEndPointFromConfigurationWall(wallValue);
    accWallsSegments.push({ start,end });
    return accWallsSegments;
  }, [])
}