import { NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getСompletedModelsNullNames } from "../../functionsConfigurator/cabinets/getNodesCabinets";
import { checkIfCornerEmptyCabinet } from "../cabinets/cabinetsWall/extremePoints";
import { getItemNodeFromNullModel } from "../../utils/threekit/general/getFunctions";
import { ProductsOther } from "../../utils/constants/cabinets";
import { ArrPriceDataTableT } from "../../store/slices/dataTablesSlice/dataTablesSliceT";
import { FramedOptionsValuesT } from "../../utils/constants/framedOptions";
import { ColorOptionsValuesT } from "../../utils/constants/colorsRoom";
import { getFrameValueDataTableFromFrameUI } from "../../functionsUI/сompareFrameUIAndFrameDataTable";
import { getColorValueDataTableFromColorUI } from "../../functionsUI/сompareColorUIAndColorDataTable";
import { ArrSummaryPriceDataTableT } from "../../functionsUI/summaryInfo/getArrSummaryProductsForCabinets";

const REACT_APP_THREEKIT_IMAGES_URL = process.env.REACT_APP_THREEKIT_IMAGES_URL;

const ID_FILLER_STRIP = "1110";

const getObjProductFillerStrip = (id: string) => {
  return ProductsOther.find((objProduct) => objProduct["id"] === id);
};

const getSummaryFillerStripCabinetsPosition = (regExpCabinetsModels: NODES_THREEKIT): number => {
  const allNullForСabinets = getСompletedModelsNullNames(regExpCabinetsModels);
  let countFillerStrip: number = 0;
  allNullForСabinets.forEach((modelNullName) => {
    const modelItem = getItemNodeFromNullModel({ name: modelNullName });
    if (checkIfCornerEmptyCabinet(modelItem)) {
      countFillerStrip += 2
    }
  })
  return countFillerStrip;
}

export const getSummaryFillerStrip = (
  priceDatatable: ArrPriceDataTableT,
  frameValue: FramedOptionsValuesT,
  colorValueCabinetsBase: ColorOptionsValuesT,
  colorValueCabinetsWall: ColorOptionsValuesT,
): ArrSummaryPriceDataTableT => {

  const countFillerStripСabinetsBase = getSummaryFillerStripCabinetsPosition(NODES_THREEKIT.MODEL_CABINET_BASE);
  const countFillerStripСabinetsWall = getSummaryFillerStripCabinetsPosition(NODES_THREEKIT.MODEL_CABINET_WALL);

  if (countFillerStripСabinetsBase === 0 && countFillerStripСabinetsWall === 0) return [];

  const convertedFrameValue = getFrameValueDataTableFromFrameUI(frameValue);
  const convertedColorValueCabinetsBase = getColorValueDataTableFromColorUI(colorValueCabinetsBase);
  const convertedColorValueCabinetsWall = getColorValueDataTableFromColorUI(colorValueCabinetsWall);

  if (convertedColorValueCabinetsBase === convertedColorValueCabinetsWall) {
    const productInfoFromDataTable = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_FILLER_STRIP &&
        objProduct["Framed"] === convertedFrameValue &&
        objProduct["Color"] === convertedColorValueCabinetsBase
    );
    if (!!productInfoFromDataTable) {
      return [{
        ...productInfoFromDataTable,
        thumbnail: `${REACT_APP_THREEKIT_IMAGES_URL}${productInfoFromDataTable["thumbnail"]}`,
        count: countFillerStripСabinetsBase + countFillerStripСabinetsWall,
      }];
    }
  }

  let resultArr: ArrSummaryPriceDataTableT = [];

  if (countFillerStripСabinetsBase > 0) {
    const productInfoFromDataTableCabinetsBase = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_FILLER_STRIP &&
        objProduct["Framed"] === convertedFrameValue &&
        objProduct["Color"] === convertedColorValueCabinetsBase
    );
    if (!!productInfoFromDataTableCabinetsBase) {
      resultArr.push({
        ...productInfoFromDataTableCabinetsBase,
        thumbnail: `${REACT_APP_THREEKIT_IMAGES_URL}${productInfoFromDataTableCabinetsBase["thumbnail"]}`,
        count: countFillerStripСabinetsBase,
      });
    }
  }

  if (countFillerStripСabinetsWall > 0) {
    const productInfoFromDataTableCabinetsWall = priceDatatable.find(
      (objProduct) =>
        objProduct["ItemNumber"] === ID_FILLER_STRIP &&
        objProduct["Framed"] === convertedFrameValue &&
        objProduct["Color"] === convertedColorValueCabinetsWall
    );
    if (!!productInfoFromDataTableCabinetsWall) {
      resultArr.push({
        ...productInfoFromDataTableCabinetsWall,
        thumbnail: `${REACT_APP_THREEKIT_IMAGES_URL}${productInfoFromDataTableCabinetsWall["thumbnail"]}`,
        count: countFillerStripСabinetsWall,
      });
    }
  }

  return resultArr;

}