import { getValueOption } from "../../../store/selectors/settingsUISelectors";
import store from "../../../store/store";
import {
  getEvalNodeFromId, getNodeFromName,
} from "../../../utils/threekit/general/getFunctions";
import { ExtremeModelI, getExtremeModels } from "../getExtremeModels";
import {
  CabinetsAndFeatures_NodesT,
  ModelsName_NodesT,
  NODES_THREEKIT,
} from "../../../utils/constants/nodesNamesThreekit";
import { getСompletedModelsNull } from "../../../functionsConfigurator/cabinets/getNodesCabinets";
import { PRODUCT_POSITIONS_KEYS } from "../../../utils/constants/cabinets";
import { getCornerDistanceSorted, getWallNameFromMaxDistanceInCorner } from "../../../utils/threekit/tools/toolsDragCabinetsBase/generalFunc";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsInfoOnWall";
import { IConfiguration, ISetConfiguration, IThreekitPrivateConfigurator } from "@threekit-tools/treble/dist/types";
import { UpdateSettingsT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import { checkModelPositionInCorner } from "../../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import { updateDecorativeEndAppliances } from "./decorativePanelAppliances";
import { buildCountertopForIsland } from "../cabinetsIsland/buildCountertopForIsland";
import { CONNECTORS_CABINET_ISLAND, getConnectorConnected } from "../connectors";

export const enum ATTRIBUTES_DECORATIVE_PANELS {
  DECORATIVE = "Decorative",
  DECORATIVE_LOCATION = "Decorative Location",
  DECORATIVE_ISLAND = "Island Option",
}

export type DecorativeValuesT = "No" | "Yes";
export type DecorativeLocationValuesT = "Left" | "Right" | "Left and Right";
export type DecorativeIslandValuesT = "No" | "Yes";
export type MouldingConfigurationT = {
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]?: DecorativeValuesT,
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]?: DecorativeLocationValuesT,
  [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]?: DecorativeIslandValuesT,
}

type SideDecorativeEndT = "Left" | "Right" | "Left and Right";
/**
 * Функція визначає сторону (значення для атрибуту Decorative Location з Threekit), на яку потрібно додати декоративну панель.
 *
 * @param {ExtremeModelI} objExtremeModel Інформація про модель, необхідна для визначення сторони.
 * @return {ModelsName_NodesT[]} Массив з Nodes для Models Null ["Model_Cabinets_Base_0", ...] які заповнені (з встановленими ассетами).
 */
export const getSideDecorativeEnd = (
  objExtremeModel: ExtremeModelI
): SideDecorativeEndT => {
  const { isLeftPointFree, isRightPointFree } = objExtremeModel;
  let resultSide: SideDecorativeEndT = "Left";

  if (isLeftPointFree) resultSide = "Left";

  if (isRightPointFree) resultSide = "Right";

  if (isLeftPointFree && isRightPointFree) resultSide = "Left and Right";

  return resultSide;
};

/**
 * Функція визначає сторону (значення для атрибуту Decorative Location з Threekit), на яку потрібно додати декоративну панель, враховуючи значення коннекторів.
 *
 * @param {boolean} isDecorativeLeft Індикатор, чи є зайняті коннекктори з лівої сторони.
 * @param {boolean} isDecorativeRight Індикатор, чи є зайняті коннекктори з правої сторони.
 * @return {DecorativeLocationValuesT} Значення яке вказує розташування декоративних панелей.
 */
export const getSideDecorativeEndFromConnectors = (
  isDecorativeLeft: boolean,
  isDecorativeRight: boolean,
): DecorativeLocationValuesT => {

  let resultSide: SideDecorativeEndT = "Left";

  if (isDecorativeLeft) resultSide = "Left";

  if (isDecorativeRight) resultSide = "Right";

  if (isDecorativeLeft && isDecorativeRight) resultSide = "Left and Right";

  return resultSide;
};

/**
 * Функція повертає конфігуратор(сonfigurator) для ітему моделі з Threekit.
 *
 * @param {ModelsName_NodesT} nameModelNull Name для Model Null з Threekit.
 * @return {IThreekitPrivateConfigurator} Конфігуратор(сonfigurator) для ітему моделі з Threekit.
 */
export const getConfiguratorModelFromNullName = (nameModelNull: CabinetsAndFeatures_NodesT): IThreekitPrivateConfigurator => {
  const modelNullNode = getNodeFromName(nameModelNull);
  //@ts-ignore
  const assetIdModel = modelNullNode["plugs"]["Null"][0]["asset"];
  const evalNodeModel = getEvalNodeFromId(assetIdModel);
  //@ts-ignore
  return evalNodeModel["configurator"];
}

interface DecorativeEndConfigurationI {
  "Decorative": "Yes" | "No"
  "Decorative Location"?: SideDecorativeEndT,
}
/**
 * Функція оновлює декоративні панелі для моделей Base Cabinets або Wall Cabinets або Island Cabinets, відповідно до значення з UI та розташування моделі.
 *
 * @param {NODES_THREEKIT} modelsCabinetsRegExp regexp для Null моделей, які необхідно оновити.
 */
const updateDecorativeEndForCabinets = (
  modelsCabinetsRegExp: NODES_THREEKIT,
  valueDecorativeEnd: boolean
): Promise<any> => {
  const allСompletedNullForCabinets =
    getСompletedModelsNull(modelsCabinetsRegExp);
  const objExtremeModels = getExtremeModels(modelsCabinetsRegExp);
  const arrExtremeModelsKeys = Object.keys(objExtremeModels);
  let arrPromices: Promise<any>[] = [];

  if (!valueDecorativeEnd) {
    allСompletedNullForCabinets.forEach((nullNode) => {
      const nullNodeName = nullNode["name"] as ModelsName_NodesT;
      const configuratorModel = getConfiguratorModelFromNullName(nullNodeName);
      arrPromices.push(configuratorModel.setConfiguration({
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
      }));
    });
    return Promise.all(arrPromices);
  }

  allСompletedNullForCabinets.forEach((nullNode) => {
    const nullNodeName = nullNode["name"] as ModelsName_NodesT;
    const configuratorModel = getConfiguratorModelFromNullName(nullNodeName);

    // якщо модель внутрішня, маємо прибрати декоративні панелі.
    let objDecorativeEndConfiguration: ISetConfiguration | IConfiguration = {
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
      [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: "No",
    }

    // Встановлюємо декоративні панелі для шкафів в залежності від з'єднань коннекторів
    if (modelsCabinetsRegExp === NODES_THREEKIT.MODEL_CABINET_ISLAND) {

      const connectingLeft = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.LEFT);
      const connectingLeftFront = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.LEFT_FRONT);

      const connectingRight = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.RIGHT);
      const connectingRightFront = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.RIGHT_FRONT);

      const connectingBackLeft = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.BACK_LEFT);
      const connectingBackRight = getConnectorConnected(nullNodeName, CONNECTORS_CABINET_ISLAND.BACK_RIGHT);

      const isDecorativeLeft = connectingLeft === null && connectingLeftFront === null;
      const isDecorativeRight = connectingRight === null && connectingRightFront === null;
      const isDecorativeBack = connectingBackLeft === null && connectingBackRight === null;

      const decorativeSide = isDecorativeBack || isDecorativeRight ? "Yes" : "No";
      const decorativeSideLocation = getSideDecorativeEndFromConnectors(isDecorativeLeft, isDecorativeRight);
      const decorativeBack = isDecorativeBack ? "Yes" : "No";

      objDecorativeEndConfiguration = {
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: decorativeSide,
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: decorativeSideLocation,
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_ISLAND]: decorativeBack,
      }

      return arrPromices.push(configuratorModel.setConfiguration(objDecorativeEndConfiguration));

    }

    if (arrExtremeModelsKeys.includes(nullNodeName)) {
      // маємо додати для моделі декоративні панелі

      // визначаємо з якої сторони потрібно додати декоративну панель
      const sideDecorativeEnd = getSideDecorativeEnd(
        objExtremeModels[nullNodeName]
      );

      objDecorativeEndConfiguration = {
        ...objDecorativeEndConfiguration,
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "Yes",
        [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: sideDecorativeEnd,
      }

      // Перевіряємо чи не є модель в куті.
      // Чи не притиснута вона боковою стінкою до сусідньої кутової стіни.
      const cornerDistance = checkModelPositionInCorner(nullNodeName);

      if (!!cornerDistance) {
        const modelSize = getSizeModelRelativeTransform(nullNodeName);
        const maxDistanceWallName =
          getWallNameFromMaxDistanceInCorner(cornerDistance);
        const cornerWallsSortedLeftRight =
          getCornerDistanceSorted(cornerDistance);
        if (
          cornerDistance[maxDistanceWallName]["distanceFront"] <
            modelSize["x"] &&
          sideDecorativeEnd === "Left and Right"
        ) {
          if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
            objDecorativeEndConfiguration = {
              ...objDecorativeEndConfiguration,
              [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Right",
            };
          }

          if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
            objDecorativeEndConfiguration = {
              ...objDecorativeEndConfiguration,
              [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE_LOCATION]: "Left",
            };
          }

          // перевірка на те, що модель стоїть одною стороною в куті а другою стороною з'єднується з іншою тумбою
        } else if (
          sideDecorativeEnd !== "Left and Right" &&
          cornerDistance[maxDistanceWallName]["distanceFront"] < modelSize["x"]
        ) {
          objDecorativeEndConfiguration = {
            [ATTRIBUTES_DECORATIVE_PANELS.DECORATIVE]: "No",
          }
        }

      }
    }

    arrPromices.push(configuratorModel.setConfiguration(objDecorativeEndConfiguration));
  });

  return Promise.all(arrPromices);
};

/**
 * Функція оновлює декоративні панелі для всіх моделей, відповідно до значення з UI та розташування моделі.
 * Для оновлення після переміщення однієї з моделей
 *
 */
export const updateDecorativeEnd = (
  movedModelPosition?: PRODUCT_POSITIONS_KEYS,
  decorativeEndValue?: UpdateSettingsT
) => {
  const state = store.getState();

  // отримуємо активні значення Decorative End з UI для: Base Cabinets, Island Cabinets
  let decorativeEndCabinetsBaseValue = getValueOption({
    idOption: "CabinetsBase_DecorativeEnd",
    sectionId: "CabinetBuild",
  })(state) as boolean;
  // let decorativeEndCabinetsWallValue = getValueOption({
  //   idOption: "CabinetsWall_DecorativeEnd",
  //   sectionId: "CabinetBuild",
  // })(state) as boolean;
  let decorativeEndCabinetsIslandValue = getValueOption({
    idOption: "CabinetsIsland_DecorativeEnd",
    sectionId: "CabinetBuild",
  })(state) as boolean;

  let isUpdateCabinetsBase =
    !!movedModelPosition &&
    movedModelPosition === PRODUCT_POSITIONS_KEYS.BASE_CABINET;
  // let isUpdateCabinetsWall = !!movedModelPosition && movedModelPosition === PRODUCT_POSITIONS_KEYS.WALL_CABINET;
  let isUpdateCabinetsIsland =
    !!movedModelPosition &&
    movedModelPosition === PRODUCT_POSITIONS_KEYS.ISLAND_CABINET;

  if (!!movedModelPosition && movedModelPosition === PRODUCT_POSITIONS_KEYS.APPLIANCES) {
    isUpdateCabinetsBase = true;
    isUpdateCabinetsIsland = true;
  }

  if (decorativeEndValue) {
    const { optionId, value } = decorativeEndValue;
    const activeValue = value as boolean;
    switch (optionId) {
      case "CabinetsBase_DecorativeEnd":
        decorativeEndCabinetsBaseValue = activeValue;
        isUpdateCabinetsBase = true;
        break;
      // case 'CabinetsWall_DecorativeEnd':
      //   decorativeEndCabinetsWallValue = activeValue;
      //   isUpdateCabinetsWall = true;
      //   break;
      case "CabinetsIsland_DecorativeEnd":
        decorativeEndCabinetsIslandValue = activeValue;
        isUpdateCabinetsIsland = true;
        break;
      default:
        decorativeEndCabinetsBaseValue = activeValue;
        // decorativeEndCabinetsWallValue = activeValue;
        decorativeEndCabinetsIslandValue = activeValue;
        isUpdateCabinetsBase = true;
        // isUpdateCabinetsWall = true;
        isUpdateCabinetsIsland = true;
        break;
    }
  }

  // оновити Decorative End для Base Cabinets
  if (isUpdateCabinetsBase) {
    updateDecorativeEndForCabinets(
      NODES_THREEKIT.MODEL_CABINET_BASE,
      decorativeEndCabinetsBaseValue
    );
  }

  // оновити Decorative End для Wall Cabinets
  // if (isUpdateCabinetsWall) {
  //   updateDecorativeEndForCabinets(NODES_THREEKIT.MODEL_CABINET_WALL, decorativeEndCabinetsWallValue);
  // }

  // оновити Decorative End для Island Cabinets
  if (isUpdateCabinetsIsland) {
    updateDecorativeEndForCabinets(
      NODES_THREEKIT.MODEL_CABINET_ISLAND,
      decorativeEndCabinetsIslandValue
    ).then(() => {
      buildCountertopForIsland();
    });
  }

  // оновлюємо декоративні панелі для Appliances
  updateDecorativeEndAppliances(decorativeEndCabinetsBaseValue, decorativeEndCabinetsIslandValue)
  
};