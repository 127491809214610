export default {
  credentials: {
    preview: {
      orgId: process.env.REACT_APP_THREEKIT_PREVIEW_ORG_ID,
      publicToken: process.env.REACT_APP_THREEKIT_PREVIEW_PUBLIC_TOKEN,
    },
    'admin-fts': {
      orgId: process.env.REACT_APP_THREEKIT_ADMIN_FTS_ORG_ID,
      publicToken: process.env.REACT_APP_THREEKIT_ADMIN_FTS_PUBLIC_TOKEN,
    },
  },
  products: {
    preview: {
      assetId: '5430b04a-c04b-48b2-999d-f88cc0ad3b37',
      // assetId: '9ea7b28e-62c6-494f-94f9-28b32c01bba3',
      configurationId: undefined,
      stageId: undefined,
    },
    'admin-fts': {
      assetId: '5430b04a-c04b-48b2-999d-f88cc0ad3b37',
      // assetId: '595a765d-1a1e-41d8-8506-3e52616ee574',
      configurationId: undefined,
      stageId: undefined,
    },
  },
};
