import { useNavigate } from "react-router";
import { deleteAllWallsFrom3D } from "../../../functionsConfigurator/wallsAndFloor/deleteWalls3D";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalContinue2D.module.scss";
import { ROUTES } from "../../../router/RouterProvider/RouterProvider";
import { getConfiguration2D } from "../../../functionsConfigurator/configurator2D/getConfiguration2D";
import { setSavedConfiguration2D } from "../../../store/slices/settingsUI/settingsUISlice";

export const ModalContinue2D = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.CONTINUE_2D));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    dispatch(setModalVisible({modalId: MODAL_KEYS.CONTINUE_2D, value: false}))
  }

  const handleContinue = () => {
    const configuration2D = getConfiguration2D();
    dispatch(setSavedConfiguration2D(configuration2D))
    dispatch(setModalVisible({modalId: MODAL_KEYS.CONTINUE_2D, value: false}))
    deleteAllWallsFrom3D();
    navigate(ROUTES.buildRoom);
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.CONTINUE_2D}>
      <div className={s.modalContinue2D}>
        <p className={s.title}>Any modifications made to the 2D room will result in the deletion of progress made on the cabinets in the 3D room.</p>
        <p className={s.noteText}>Сontinue anyway?</p>
        <div className={s.btnsWrap}>
          <div className={s.col}>
            <ButtonMain text="Cancel" onClick={handleCloseModal} color="white" size="bigSuper" fullWidth={true} />
          </div>
          <div className={s.col}>
            <ButtonMain text="Continue" onClick={handleContinue} color="blueDark" size="bigSuper" fullWidth={true} bold={true} />
          </div>
        </div>
      </div>
    </ModalsWrap>
  )
}