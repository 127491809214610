import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { ROUTES } from "../../../router/RouterProvider/RouterProvider";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { RadioButtonsSmall } from "../../RadioButtons/RadioButtonsSmall/RadioButtonsSmall";
import { MeasurementUnitValues } from "../../../utils/constants/settingsConfig2D";
import { IUnits } from "@threekit-tools/treble/dist/hooks/useSpaces/types";
import { getSavedConfiguration2D } from "../../../store/selectors/settingsUISelectors";
import { getConfiguration2D } from "../../../functionsConfigurator/configurator2D/getConfiguration2D";
import { isEqual } from "lodash";
import { removeAllModels } from "../../../store/actions/player.action";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { useAttribute } from "@threekit-tools/treble/dist";
import { setInitialWalls } from "../../../store/slices/settingsUI/settingsUISlice";
import { extractConfiguration } from "../../../utils/other/extractConfiguration";
import { ArrWallTemplateT } from "../../../utils/constants/presetsRoom";
import { deleteAllWallsFrom3D } from "../../../functionsConfigurator/wallsAndFloor/deleteWalls3D";
import { isClosedContour } from "../../../functionsConfigurator/configurator2D/validateClosedContour";
import { getArrIntervalsWalls } from "../../../functionsConfigurator/configurator2D/wallsGeneral";
import { IdeaSvg } from "../../../assets/images/svg/Idea";

// scss
import s from "./SceneControlsTop2D.module.scss";

export const SceneControlsTop2D = ({ ...props }) => {
  const { unit } = props;
  const [isDisabledRoomDesign, setDisabledRoomDesign] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const savedConfiguration2D = useAppSelector(getSavedConfiguration2D);
  const [attributeWalls, setAttributeWalls]: any = useAttribute(
    ATTRIBUTES_NAMES_THREEKIT.WALLS
  );

  const handleClickHelp = () => {
    dispatch(
      setModalVisible({
        modalId: MODAL_KEYS.HELP_2D,
        value: true,
        isFirstShow: false,
      })
    );
  };

  const handleClickRoomDesign = () => {
    const configuration2D = getConfiguration2D();
    if (
      Object.keys(savedConfiguration2D).length > 0 &&
      !isEqual(savedConfiguration2D, configuration2D)
    ) {
      dispatch(removeAllModels());
      deleteAllWallsFrom3D();
    }
    const outputArray: ArrWallTemplateT = extractConfiguration(attributeWalls["value"]);
    dispatch(setInitialWalls(outputArray));
    navigate(ROUTES["buildCabinets"]);
  };

  const handleChangeMeasurementUnit = (value: IUnits) => {
    unit.handleChange(value);
  };

  useLayoutEffect(() => {

    if (attributeWalls !== undefined) {

      const segmentsWalls = getArrIntervalsWalls(attributeWalls.value);
      const closedContour = isClosedContour(segmentsWalls);

      if (!closedContour) {
        setDisabledRoomDesign(true);
      } else {
        setDisabledRoomDesign(false);
      }
      
    }

  }, [attributeWalls?.value.length]);

  return (
    <div className={s.sceneControlsTop}>
      <div className={s.wallsMeasuremenWrap}>
        <RadioButtonsSmall
          id="MeasurementUnit"
          label="Select Measurement unit"
          values={MeasurementUnitValues}
          onChange={handleChangeMeasurementUnit}
        />
      </div>
      <div className={s.wallsHeightInputWrap}>
        {/* <InputNumberSize
          id="WallHeightGlobal"
          label="Wall Height"
          min={1}
          max={4}
          defaultValue={2.5}
        /> */}
      </div>
      <ButtonMain
        text="Help"
        onClick={handleClickHelp}
        color="white"
        size="big"
      />
      <ButtonMain
        text="View Room Design"
        onClick={handleClickRoomDesign}
        color="blueDark"
        size="big"
        disabled={isDisabledRoomDesign}
      />
      {isDisabledRoomDesign && 
        <div className={s.infoClosedContur}>
          <IdeaSvg />
          <p className={s.text}>To proceed to the next step, <b>please draw the outline of the closed room</b></p>
        </div>
      }
      
    </div>
  );
};
