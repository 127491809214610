import {
  ModelAppliancesT,
  ModelCabinetIslandT,
  NODES_THREEKIT,
} from "../../../../utils/constants/nodesNamesThreekit";
import { getNodeIdFromName } from "../../../../utils/threekit/general/getFunctions";
import { getModelPositionFromName } from "../../../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import { checkPointEquality } from "../../../wallsAndFloor/buildFloorForRoom";
import { checkIntersectModelFromModelsList } from "../../IntersectsModelsBox";
import { getSizeModelBoxFromAssetCabinetBase } from "../../cabinetsBase/size";
import { getСompletedModelsNullNames } from "../../getNodesCabinets";
import {
  CONNECTORS_CABINET_ISLAND,
  getConnectorConnected,
} from "../../connectors";

type nameCabinetT = ModelCabinetIslandT | ModelAppliancesT;
type regexpModelT =
  | NODES_THREEKIT.MODEL_CABINET_ISLAND
  | NODES_THREEKIT.MODEL_APPLIANCES;
// function checkModelInPositionFree(nameNullNewModel: ModelCabinetIslandT): boolean {
//   const newModelSize = getSizeModelBoxFromAssetCabinetBase(nameNullNewModel);
//   const completedModelsNullNamesCabinetsIsland = getСompletedModelsNullNames(
//     NODES_THREEKIT.MODEL_CABINET_ISLAND
//   );
//   let isStartCoordsFree = true;
//   completedModelsNullNamesCabinetsIsland.forEach((nulNameCompletedModel) => {
//     const modelPos = getModelPositionFromName(nulNameCompletedModel);
//     if (nulNameCompletedModel !== nameNullNewModel && checkPointEquality({x: 0, y: 0, z: 0}, modelPos, newModelSize["x"]/2)) {
//       isStartCoordsFree = false;
//     }
//   })
//   return isStartCoordsFree
// }

// якщо повертає true тоді немає зіткнень
function checkAllItersectsInIslandAndAppliances(nameCabinet: nameCabinetT) {
  const isIntersectNewModelIsland = checkIntersectModelFromModelsList(
    nameCabinet,
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );
  const isIntersectNewModelAppliances = checkIntersectModelFromModelsList(
    nameCabinet,
    NODES_THREEKIT.MODEL_APPLIANCES
  );
  const IntersectingAppliancesAndIslands =
    !isIntersectNewModelAppliances && !isIntersectNewModelIsland;

  return IntersectingAppliancesAndIslands;
}

function checkIntersectOrConections(nameCabinet: nameCabinetT) {
  const isIntersectNewModels =
    checkAllItersectsInIslandAndAppliances(nameCabinet);

  const connectingLeft = getConnectorConnected(
    nameCabinet,
    CONNECTORS_CABINET_ISLAND.LEFT
  );
  const connectingRight = getConnectorConnected(
    nameCabinet,
    CONNECTORS_CABINET_ISLAND.RIGHT
  );

  const result = isIntersectNewModels || connectingLeft || connectingRight;

  return result;
}

function moveElementInScene(
  nameCabinet: nameCabinetT,
  movePoint: number,
  updatedX: number,
  x: number,
  y: number
) {
  //@ts-ignore
  window.threekit.player.selectionSet.clear();
  var el: HTMLElement | null = document.querySelector("#player_wrapper canvas");
  const modelId = getNodeIdFromName(nameCabinet);
  //@ts-ignore
  window.threekit.player.selectionSet.add(modelId);

  if (!!el) {
    // для урахування положення канвасу в вікні браузера
    const boundingClientRect: DOMRect = el?.getBoundingClientRect();
    let ev = new MouseEvent("mousedown", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: x + boundingClientRect["left"] + updatedX,
      clientY: y,
    });
    el.dispatchEvent(ev);

    ev = new MouseEvent("mousemove", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: x + boundingClientRect["left"] + updatedX + movePoint,
      clientY: y,
    });
    el.dispatchEvent(ev);

    ev = new MouseEvent("mouseup", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: x + boundingClientRect["left"] + updatedX + movePoint,
      clientY: y,
    });
    el.dispatchEvent(ev);
  }
  // @ts-ignore
  window.threekit.player.selectionSet.clear();
}

function clickCustom(
  numPoint: number,
  x: number,
  y: number,
  regexpModel: regexpModelT,
  allModelsLength: number
) {
  const nameCabinet: nameCabinetT = `${regexpModel}${numPoint}`;
  const isIntersectNewModels =
    checkAllItersectsInIslandAndAppliances(nameCabinet);

  const emptyModelsOrNoIntersectingAppliancesAndIslands =
    allModelsLength === 0 || isIntersectNewModels;
  if (emptyModelsOrNoIntersectingAppliancesAndIslands) return;
  //

  function repeatMovingScene() {
    let movePoint = 50;
    let updatedX = 0;
    let isIntersectNewModels = checkIntersectOrConections(nameCabinet);
    let countToStop = 0;
    async function innerFunction() {
      if (countToStop >= 5) return;

      if (!isIntersectNewModels) {
        moveElementInScene(nameCabinet, movePoint, updatedX, x, y);
        movePoint += 30;
        updatedX = 50;

        await new Promise((resolve) => setTimeout(resolve, 1));
        isIntersectNewModels = checkIntersectOrConections(nameCabinet);

        setTimeout(() => {
          innerFunction();
        }, 1);
      }
      countToStop++;
    }

    innerFunction();
  }

  repeatMovingScene();
}

export const emulationMoveCabinetIsland = (
  countNewCabinets: number,
  regexpModel: regexpModelT
) => {
  const completedModelsNullNamesCabinets =
    getСompletedModelsNullNames(regexpModel);

  const completedModelsNullNamesCabinetsIsland = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_ISLAND
  );
  const completedModelsNullNamesCabinetsAppliances =
    getСompletedModelsNullNames(NODES_THREEKIT.MODEL_APPLIANCES);
  const modelsList = [
    ...completedModelsNullNamesCabinetsIsland,
    ...completedModelsNullNamesCabinetsAppliances,
  ];

  //@ts-ignore
  const worldPositionToScreen = window.threekit.player.worldPositionToScreen({
    x: 0,
    y: 0,
    z: 0,
  });
  for (
    let i = completedModelsNullNamesCabinets.length - countNewCabinets;
    i < completedModelsNullNamesCabinets.length;
    i++
  ) {
    const allModelsLength = modelsList.length - countNewCabinets;
    clickCustom(
      i,
      worldPositionToScreen["x"],
      worldPositionToScreen["y"] - 30,
      regexpModel,
      allModelsLength
    );
  }
};
