export const addCustomTool = (funcTool: any) => {
  window.threekit.player.tools.addTool(funcTool);
}
export const removeCustomTool = (nameTool: string) => {
  clearSelectThreekit()
  window.threekit.player.tools.removeTool(nameTool);
}
export const activateToolThreekit = (toolKey: string) => {
  //@ts-ignore
  window.threekit.player.player.tools.tools[toolKey].enabled = true;
}
export const deactivateToolThreekit = (toolKey: string) => {
  //@ts-ignore
  window.threekit.player.player.tools.tools[toolKey].enabled = false;
}
export const clearSelectThreekit = () => {
  //@ts-ignore
  window.threekit.player.selectionSet.clear();
}