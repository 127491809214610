import { CONNECTORS_CABINET_WALL } from "../../../utils/constants/connectors";
import { ModelsName_NodesT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getInstanceIdAssetFromNullModel, getNameNodeThreekit, getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { setActiveTagsThreekit } from "../../../utils/threekit/general/setFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { checkIntersectIntervalFromIntervals } from "../../intervals/intersectIntervals";
import { ArrWallRangesT, RangeT, WallRangeT, getIntervalsWallCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { getWallNameFromPlaneName } from "../../wallsAndFloor/getGeneralWallInfo";
import { isConnectorsSideTrue } from "../cabinetsBase/checkCabinetsBaseConnectors";
import { checkIfCornerCabinetFromNullName } from "../cabinetsWall/extremePoints";
import { isUpperPantryCabinetWall } from "../checkModels";
import { CABINETS_WALL_BOTTOM_POSITION, CABINETS_WALL_STANDART_HEIGHT } from "../constatns";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";

type SideConnectorsT = {
  leftConnectors: boolean | undefined;
  rightConnectors: boolean | undefined;
}

const updateConnectorsTagsCabinetsWall = (objSideConnectors: SideConnectorsT, instanceIdParentAsset: string) => {
  if (objSideConnectors["leftConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_LEFT,
      value: objSideConnectors["leftConnectors"],
    })
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.BOTTOM_LEFT,
      value: objSideConnectors["leftConnectors"],
    })
  }
  if (objSideConnectors["rightConnectors"] !== undefined) {
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.TOP_RIGHT,
      value: objSideConnectors["rightConnectors"],
    })
    setActiveTagsThreekit({
      from: instanceIdParentAsset,
      name: CONNECTORS_CABINET_WALL.BOTTOM_RIGHT,
      value: objSideConnectors["rightConnectors"],
    })
  }
}

const getFakeRightInterval = (
  range: RangeT,
  widthModel: number,
): RangeT => [ range[1] + 0.05, range[1] + widthModel + 0.05 ];

const getFakeLeftInterval = (
  range: RangeT,
  widthModel: number,
): RangeT => [ range[0] - widthModel - 0.05, range[0] - 0.05 ];

// (Upper Pantry)
// перевіряємо моделі на активність конекторів для виділеної моделі Upper Pantry
const checkConnectorsForUpperPantry = (
  isUpperPantrySelectedModel: boolean,
  rangeIterModel: RangeT,
  widthSelectedModel: number,
  arrBaseIntervals: ArrWallRangesT,
  side: "left" | "right"
): boolean => {
  if (!isUpperPantrySelectedModel) return true;

  const fakeRighhtInterval =
    side === "left"
      ? getFakeLeftInterval(rangeIterModel, widthSelectedModel)
      : getFakeRightInterval(rangeIterModel, widthSelectedModel);

  const isIntersectsIntervalsRight = checkIntersectIntervalFromIntervals(
    fakeRighhtInterval,
    arrBaseIntervals
  );

  if (isIntersectsIntervalsRight) return false;

  return true;
};

export const checkCabinetsWallConnectors = (idSelectedModel: string) => {

  const nullNameSelectedModel = getNameNodeThreekit({id: idSelectedModel}) as ModelsName_NodesT;
  const sizeSelectedModel = getSizeModelBoxFromAssetCabinetWall(nullNameSelectedModel);
  const isUpperPantrySelectedModel = isUpperPantryCabinetWall(nullNameSelectedModel);

  const intervalsCabinetsWallForAllWalls = getIntervalsWallCabinetsForAllWalls();
  const intervalsCabinetsBaseForAllWalls = getIntervalsBaseCabinetsForAllWalls();

  const keysIntervalsCabinetsWallForAllWalls = getKeys(
    intervalsCabinetsWallForAllWalls
  );

  keysIntervalsCabinetsWallForAllWalls.forEach((planeName) => {

    const arrWallIntervals =
      intervalsCabinetsWallForAllWalls[planeName];
    const arrBaseIntervals =
      intervalsCabinetsBaseForAllWalls[getWallNameFromPlaneName(planeName)];

    let prevInterval: WallRangeT | null = null;
    let prevFilledInterval: WallRangeT | null = null;

    arrWallIntervals.forEach((interval, indx) => {

      let { empty, range, name } = interval;
      const nextInterval = arrWallIntervals[indx + 1];
      const nextFilledInterval = nextInterval !== undefined && nextInterval["empty"] ? arrWallIntervals[indx + 2] : arrWallIntervals[indx + 1];
      let objSideConnectors: SideConnectorsT = {
        leftConnectors: undefined,
        rightConnectors: undefined,
      }

      if (!empty && !!name && !isFeaturesModelNullName(name)) {

        const instanceIdAssetModel = getInstanceIdAssetFromNullModel({name: name});

        // якщо настінна модель знаходить виже або нижче дефолтної позиції,
        // то вмикаємо всі конектори
        const positionModel = getTranslationThreekit({name});
        const sizeModel = getSizeModelBoxFromAssetCabinetWall(name);
        if (Math.abs((positionModel["y"] + sizeModel["y"]) - (CABINETS_WALL_BOTTOM_POSITION + CABINETS_WALL_STANDART_HEIGHT)) > 0.005) {
          objSideConnectors["leftConnectors"] = true;
          objSideConnectors["rightConnectors"] = true;
          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)
        }

        // якщо це перша модель на стіні, і вона є кутовою моделлю
        // то перевіряємо тільки праву сторону
        if (indx === 0 && checkIfCornerCabinetFromNullName(name)) {

          if (
            isConnectorsSideTrue(
              nextInterval,
              nextFilledInterval,
              nullNameSelectedModel,
              sizeSelectedModel["x"]
            )
          ) {
            objSideConnectors["rightConnectors"] = true;
          } else {
            objSideConnectors["rightConnectors"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)

        }

        // якщо це остання модель на стіні, і вона є кутовою моделлю
        // то перевіряємо тільки ліву сторону
        if (indx === arrWallIntervals.length - 1 && checkIfCornerCabinetFromNullName(name)) {

          if (
            isConnectorsSideTrue(
              prevInterval,
              prevFilledInterval,
              nullNameSelectedModel,
              sizeSelectedModel["x"]
            )
          ) {
            objSideConnectors["leftConnectors"] = true
          } else {
            objSideConnectors["leftConnectors"] = false;
          }

          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)

        }

        // якщо це є виділеною моделлю, то вмикаємо для неї всі конектори
        if (name === nullNameSelectedModel) {

          objSideConnectors["leftConnectors"] = true;
          objSideConnectors["rightConnectors"] = true;
          prevInterval = interval;
          prevFilledInterval = interval;
          return updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel)

        }

        // перевіряємо ліву сторону
        const isConnectorsLeft = isConnectorsSideTrue(
          prevInterval,
          prevFilledInterval,
          nullNameSelectedModel,
          sizeSelectedModel["x"]
        )
        const isConnectorsForUpperPantryLeft = checkConnectorsForUpperPantry(
          isUpperPantrySelectedModel,
          range,
          sizeSelectedModel["x"],
          arrBaseIntervals,
          "left",
        );
        if (
          isConnectorsLeft && isConnectorsForUpperPantryLeft
        ) {
          objSideConnectors["leftConnectors"] = true
        } else {
          objSideConnectors["leftConnectors"] = false;
        }
        
        // перевіряємо праву сторону
        const isConnectorsRight = isConnectorsSideTrue(
          nextInterval,
          nextFilledInterval,
          nullNameSelectedModel,
          sizeSelectedModel["x"]
        )
        const isConnectorsForUpperPantryRight = checkConnectorsForUpperPantry(
          isUpperPantrySelectedModel,
          range,
          sizeSelectedModel["x"],
          arrBaseIntervals,
          "right",
        );
        if (
          isConnectorsRight && isConnectorsForUpperPantryRight
        ) {
          objSideConnectors["rightConnectors"] = true;
        } else {
          objSideConnectors["rightConnectors"] = false;
        }

        updateConnectorsTagsCabinetsWall(objSideConnectors, instanceIdAssetModel);

        prevFilledInterval = interval;

      }

      if (!empty && !!name && isFeaturesModelNullName(name)) {
        prevFilledInterval = interval;
      }

      prevInterval = interval;

    })
  })

}