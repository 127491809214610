import { ICoordinates, PlayerToolEvent } from "@threekit-tools/treble/dist/types";
import {
  ModelsName_NodesT,
  NODES_THREEKIT,
  WallItemT,
} from "../../../constants/nodesNamesThreekit";
import {
  getItemNodeFromNullModel,
  getNodeFromId,
  getNodeThreekit,
  getTranslationThreekit,
} from "../../general/getFunctions";
import {
  AllWallsIntervalsFloorT,
  getIntervalsBaseCabinetsForAllWalls,
} from "../../../../functionsConfigurator/intervals/getIntervalsInfoOnWallBase";
import {
  addCornerModelBase,
  ArrNamesCornerWallsT,
  checkModelBaseInCornerPoint,
  getInternalCornerCoordsForWalls,
  isEqualCoordsTolerance,
} from "../../../../functionsConfigurator/cabinets/addCornerModelBase";
import { once } from "../../../../pattern/once";
import {
  moveAllCabinetsBaseOnWall,
  MoveDirectionOnWallT,
} from "../../../../functionsConfigurator/cabinets/cabinetsBase/moving/moveAllCabinetsBaseOnWall";
import {
  arrAllDistanceFromModelToFrontWall,
  arrPositiveDistanceFromModelToFrontWall,
  checkApproachToCorner,
  checkIfBaseCornerCabinet,
  getCornerDistanceSorted,
  getDirectionIntervalEmpty,
  getModelPosition,
  getMoveDistanceModels,
  getObjDistance,
  getObjWallsForCalculateDistance,
  getWallNameFromMaxDistanceInCorner,
  ObjWallsForCalculateDistanceT,
  rotateObjBackToWall,
} from "./generalFunc";
import { checkCornerIntervalEmpty, getSizeModelRelativeTransform } from "../../../../functionsConfigurator/intervals/getIntervalsInfoOnWall";
import { getExtremeModels, getExtremeModelsAppliances } from "../../../../functionsConfigurator/cabinets/getExtremeModels";
import { getConfiguratorModelFromNullName, updateDecorativeEnd } from "../../../../functionsConfigurator/cabinets/configuration/decorativePanel";
import { PRODUCT_POSITIONS_KEYS } from "../../../constants/cabinets";
import { testIntersectsBox } from "../../../../functionsConfigurator/cabinets/cabinetsIsland/testIntersectsBox";
import { buildCountertopForIsland } from "../../../../functionsConfigurator/cabinets/cabinetsIsland/buildCountertopForIsland";
import { updateFillerStripForCabinetsWallOTR } from "../../../../functionsConfigurator/cabinets/configuration/fillerStripOTR";
import { updateFrigePanels } from "../../../../functionsConfigurator/cabinets/configuration/fridgePanels";
import { checkCabinetsBaseConnectors } from "../../../../functionsConfigurator/cabinets/cabinetsBase/checkCabinetsBaseConnectors";
import { isNullNameAppliances } from "../../../../functionsConfigurator/cabinets/checkModels";

const checkIsThereSelectedIdAppliances = (id: string): boolean => {
  const node = getNodeThreekit({id})
  return isNullNameAppliances(node["name"]);
};

const getSelectedFirstItem = () => {
  //@ts-ignore
  const selectedIds = window.threekit.player.selectionSet.ids;
  if (selectedIds.length < 1) return undefined;
  const selectedFirstItem = selectedIds[0];
  return selectedFirstItem;
};

const getPositionMovedModel = (idModel: string): PRODUCT_POSITIONS_KEYS => {
  const nameModel = getNodeFromId(idModel)["name"];
  const position = nameModel.split("_").slice(1, -1).join(" ") as PRODUCT_POSITIONS_KEYS;
  return position;
}

const checkIsThereCabinet = (ev: PlayerToolEvent) => {
  const nameCabinetBase = NODES_THREEKIT.MODEL_CABINET_BASE;
  const nameCabinetWall = NODES_THREEKIT.MODEL_CABINET_WALL;
  const nameCabinetIsland = NODES_THREEKIT.MODEL_CABINET_ISLAND;

  let selectedFirstItem = null;
  if (ev.hitNodes.length < 1) return selectedFirstItem;
  if (ev.hitNodes[0]["hierarchy"].length < 1) return selectedFirstItem;

  selectedFirstItem = ev.hitNodes[0]["hierarchy"].find(
    (item) =>
      item["name"].includes(nameCabinetBase) ||
      item["name"].includes(nameCabinetWall) ||
      item["name"].includes(nameCabinetIsland)
  );

  return selectedFirstItem;
};

const checkIsThereCabinetIslandOrAppliances = (ev: PlayerToolEvent) => {
  const nameCabinetIsland = NODES_THREEKIT.MODEL_CABINET_ISLAND;
  const nameAppliances = NODES_THREEKIT.MODEL_APPLIANCES;

  let selectedFirstItem = null;
  if (ev.hitNodes.length < 1) return selectedFirstItem;
  if (ev.hitNodes[0]["hierarchy"].length < 1) return selectedFirstItem;

  selectedFirstItem = ev.hitNodes[0]["hierarchy"].find(
    (item) =>
      item["name"].includes(nameCabinetIsland) ||
      item["name"].includes(nameAppliances)
  );

  return selectedFirstItem;
};

const getDistanceFromObjToWall = (
  objWallsForCalculateDistance: ObjWallsForCalculateDistanceT,
  modelId: any,
  intervalsBaseCabinetsForAllWAlls: AllWallsIntervalsFloorT,
  changeCabinets: any
) => {
  const modelItem = getItemNodeFromNullModel({ id: modelId });
  const modelPos = getModelPosition(modelId);
  const modelNode = getNodeFromId(modelId);
  const modelNullName = modelNode["name"] as ModelsName_NodesT;
  const modelSize = getSizeModelRelativeTransform(modelNullName);
  // console.log("modelSize --- ==== ", modelSize);

  const objDistance = getObjDistance(objWallsForCalculateDistance, modelPos);
  const arrWallNameFromObjDistance = Object.keys(objDistance) as Array<
    keyof typeof objDistance
  >;

  // check base corner cabinet from id "1017"
  // if (checkIfBaseCornerCabinet(modelItem)) {
  //   const cornerDistance = checkApproachToCorner(objDistance, modelSize);

  //   if (Object.keys(cornerDistance).length === 2) {
  //     const cornerWallsSortedLeftRight =
  //       getCornerDistanceSorted(cornerDistance);

  //     rotateObjBackToWall(
  //       modelId,
  //       cornerDistance[cornerWallsSortedLeftRight[1]]["wallDir"]
  //     );
  //   }
  //   return;
  // }

  if (isNullNameAppliances(modelNullName)) {
    const arrAllDistanceFront = arrAllDistanceFromModelToFrontWall(objDistance);
    const arrPositiveAllDistanceFront =
      arrPositiveDistanceFromModelToFrontWall(objDistance);
    arrWallNameFromObjDistance.forEach((wallName) => {
      const distanceFront = objDistance[wallName]["distanceFront"];
      const distancePositiveX = objDistance[wallName]["distanceLeftX"];
      const distanceNegativeX = objDistance[wallName]["distanceRightX"];
      const wallWidth = objDistance[wallName]["width"];
  
      if (distanceFront < 0) {
        if (
          distanceFront > -1 &&
          Math.abs(distancePositiveX) < wallWidth &&
          Math.abs(distanceNegativeX) < wallWidth &&
          distanceFront.toFixed(4) ===
            Math.min.apply(null, arrAllDistanceFront).toFixed(4)
        )
          return rotateObjBackToWall(
            modelId,
            objDistance[wallName]["wallDir"],
            true
          );
      }
  
      if (
        distanceFront < modelSize["z"] * 2 &&
        Math.abs(distancePositiveX) < wallWidth &&
        Math.abs(distanceNegativeX) < wallWidth &&
        distanceFront.toFixed(4) ===
          Math.min.apply(null, arrPositiveAllDistanceFront).toFixed(4)
      )
        return rotateObjBackToWall(modelId, objDistance[wallName]["wallDir"]);
    });
  }

  const cornerDistance = checkApproachToCorner(objDistance, modelSize);

  // console.log('cornerDistance --- ==== ',cornerDistance);

  if (Object.keys(cornerDistance).length === 2) {
    const maxDistanceWallName =
      getWallNameFromMaxDistanceInCorner(cornerDistance);
    const cornerWallsSortedLeftRight = getCornerDistanceSorted(cornerDistance);
    // const intervalsForWallMaxDistance =
    //   intervalsBaseCabinetsForAllWAlls[maxDistanceWallName];
    // const offsetDistance = modelSize["z"] + 0.04; // 0.04 === ширина прижимної планки
    // const directionIntervalEmpty = getDirectionIntervalEmpty(
    //   maxDistanceWallName,
    //   cornerWallsSortedLeftRight
    // );
    // const isCornerIntervalEmpty = checkCornerIntervalEmpty(
    //   intervalsForWallMaxDistance,
    //   offsetDistance,
    //   directionIntervalEmpty
    // );
    // console.log('isCornerIntervalEmpty --- ==== ',isCornerIntervalEmpty);

    // if (!isCornerIntervalEmpty) {
    //   const moveDirection =
    //     directionIntervalEmpty === "left" ? "right" : "left";
    //   const moveDistance = getMoveDistanceModels(
    //     moveDirection,
    //     offsetDistance,
    //     intervalsForWallMaxDistance
    //   );

    //   changeCabinets(
    //     maxDistanceWallName,
    //     cornerWallsSortedLeftRight,
    //     moveDirection,
    //     moveDistance
    //   );
    // }

    // check Decorative End
    if (cornerDistance[maxDistanceWallName]["distanceFront"] < modelSize["x"]) {
      const configuratorModel = getConfiguratorModelFromNullName(modelNullName);
      if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
        configuratorModel.setConfiguration({
          "Decorative Location": "Right",
        });
      }
      if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
        configuratorModel.setConfiguration({
          "Decorative Location": "Left",
        });
      }
    }
  }
};

/**
 * Тимчасова функція заглушка. Ємулює завершення події drag.
 * Щоб не виникала помилка трикіта.
 *
 */
export const cancelDragModelCustom = () => {
  var el: HTMLElement | null = document.querySelector("#player_wrapper canvas");
  if (!!el) {
    let ev = new MouseEvent("mouseup", {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: 0,
      clientY: 0,
    });
    el.dispatchEvent(ev);
  }
};

let selectedCabinetIslandOrApplianceId: string | undefined = undefined;
export const dragCabinetsIsland = () => {
  let movedModelPosition: PRODUCT_POSITIONS_KEYS | undefined = undefined;
  let startModelPosistion: ICoordinates = {x: 0, y: 0, z: 0};

  return {
    active: true,
    enabled: true,
    key: "drag_cabinets_island",
    handlers: {
      mousedown: (ev: PlayerToolEvent) => {
        
        const selectedCabinetIslandOrAppliances = checkIsThereCabinetIslandOrAppliances(ev);
        if (selectedCabinetIslandOrAppliances) {
          startModelPosistion = getTranslationThreekit({id: selectedCabinetIslandOrAppliances["nodeId"]});
          if (checkIsThereSelectedIdAppliances(selectedCabinetIslandOrAppliances["nodeId"])) {
            checkCabinetsBaseConnectors(selectedCabinetIslandOrAppliances["nodeId"]);
          }
        }

        return false;
        
      },
      mouseup: (ev: PlayerToolEvent) => {

        // buildCountertopForIsland()

        // const intervalsBaseCabinetsForAllWalls = getIntervalsBaseCabinetsForAllWalls();
        // console.log('intervalsBaseCabinetsForAllWalls --- ==== ',intervalsBaseCabinetsForAllWalls);

        // const objExtremeModelsAppliances = getExtremeModelsAppliances();
        // console.log('objExtremeModelsAppliances --- ==== ',objExtremeModelsAppliances);

        // const objExtremeModelsCabinetsBase = getExtremeModels(NODES_THREEKIT.MODEL_CABINET_BASE);
        // console.log('objExtremeModelsCabinetsBase --- ==== ',objExtremeModelsCabinetsBase);

        // const objExtremeModelsCabinetsIsland = getExtremeModels(NODES_THREEKIT.MODEL_CABINET_ISLAND);
        // console.log('objExtremeModelsCabinetsIsland --- ==== ',objExtremeModelsCabinetsIsland);

        // const testIntersects = testIntersectsBox()
        // console.log('testIntersects --- ==== ',testIntersects);

        if (selectedCabinetIslandOrApplianceId !== undefined) {
          const endModelPosistion = getTranslationThreekit({id: selectedCabinetIslandOrApplianceId});
          if (!isEqualCoordsTolerance(startModelPosistion, endModelPosistion, 0.001)) {
            if (movedModelPosition) {
              updateDecorativeEnd(movedModelPosition);
            }
            updateFillerStripForCabinetsWallOTR();
            buildCountertopForIsland();
            if (checkIsThereSelectedIdAppliances(selectedCabinetIslandOrApplianceId)) {
              updateFrigePanels();
            }
          }
        }

        selectedCabinetIslandOrApplianceId = undefined;
        movedModelPosition = undefined;
        return false;
      },
      drag: (ev: PlayerToolEvent) => {
        // const selectedFirstItem = getSelectedFirstItem();

        // if (selectedFirstItem && selectedFirstItem.length > 0) {

          // const selectedModel = checkIsThereCabinet(ev);
          // if (selectedModel) {
          //   movedModelPosition = getPositionMovedModel(selectedModel["nodeId"]);
          // }

          const selectedCabinetIslandOrAppliances = checkIsThereCabinetIslandOrAppliances(ev);
          if (selectedCabinetIslandOrAppliances) {
            selectedCabinetIslandOrApplianceId = selectedCabinetIslandOrAppliances["nodeId"];
            movedModelPosition = getPositionMovedModel(selectedCabinetIslandOrAppliances["nodeId"]);
            runMovedCabinetsIslandOrAppliance(() => {});
          }
        // }

        return false;
      },
    },
  };
};

const runMovedCabinetsIslandOrAppliance = (addModelToCorner: () => void) => {
  const objWallsForCalculateDistance = getObjWallsForCalculateDistance();
  const intervalsBaseCabinetsForAllWalls =
    getIntervalsBaseCabinetsForAllWalls();

  const changeCabinets = once(
    (
      wallName: WallItemT,
      cornerWallsNames: ArrNamesCornerWallsT,
      moveDirection: MoveDirectionOnWallT,
      moveDistance: number
    ) => {
      const cornerCoords = getInternalCornerCoordsForWalls(cornerWallsNames);
      console.log({ cornerWallsNames });

      const isBusyCornerPoint = checkModelBaseInCornerPoint(cornerCoords);
      // перевіряємо наявність кутової моделі в куті
      // якщо в куті вже є кутова модель, то зміни не робимо
      if (isBusyCornerPoint) return;

      // console.log("ADDED CORNER MODEL --- ==== ");
      // console.log("MOVE ALL CABINETS BASE FOR WALL --- ==== ", wallName);
      // console.log("MOVE ALL CABINETS WALL FOR WALL --- ==== ", wallName);
      // console.log('moveDistance --- ==== ',moveDistance);

      // cancelDragModelCustom();
      // moveAllCabinetsBaseOnWall(wallName, moveDirection, moveDistance);
      // moveAllCabinetsWallOnWall(wallName, moveDirection, moveDistance);
      // addCornerModelBase(cornerWallsNames);
    }
  );

  const runEveryFrameWindow = () => {

    if (!selectedCabinetIslandOrApplianceId) return;

    getDistanceFromObjToWall(
      objWallsForCalculateDistance,
      selectedCabinetIslandOrApplianceId,
      intervalsBaseCabinetsForAllWalls,
      changeCabinets
    );

    if (requestAnimationFrame) requestAnimationFrame(runEveryFrameWindow);
  };
  runEveryFrameWindow();
};
