import { RootState } from "../store";
import { getValueOption } from "./settingsUISelectors";

export const isSinkCabinetFilled = (state: RootState): string | undefined => {

  const ARR_IDS_SINK_CABINETS = ["1011", "1015"];
  let resultValue: string | undefined = undefined;

  const arrValuesCabinetsBase = getValueOption({
    idOption: "Cabinets Base",
    sectionId: "Cabinets",
  })(state) as string[];
  const arrValuesCabinetsIsland = getValueOption({
    idOption: "Cabinets Island",
    sectionId: "Island",
  })(state) as string[];
  // const arrValuesAppliances = getValueOption({
  //   idOption: "Cabinets Appliances",
  //   sectionId: "Appliances",
  // })(state) as string[];

  [ ...arrValuesCabinetsBase, ...arrValuesCabinetsIsland/*, ...arrValuesAppliances*/ ].forEach((cabinetId) => {
    if (ARR_IDS_SINK_CABINETS.includes(cabinetId)) resultValue = cabinetId;
  })

  return resultValue;

};