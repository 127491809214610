import React from "react";
import { useAppSelector } from "../../../../hooks/useStoreHooks";
import { isSinkCabinetFilled } from "../../../../store/selectors/appliancesSelectors";
import { getImgLinkForCategoryGroup, getKeySectionActive, getValueOption } from "../../../../store/selectors/settingsUISelectors";

// scss
import s from "./OptionCategory.module.scss"
import NotImage from "./../../../../assets/images/Cabinets/not_image.png";

export const OptionCategory = ({ ...props }) => {
  const { id, value, label, images, onClick, parentId } = props;

  const isSinkCabinet = useAppSelector(isSinkCabinetFilled);
  const activeSectionKey = useAppSelector(getKeySectionActive);
  const valueAppliances = useAppSelector(getValueOption({ idOption: "Cabinets Appliances", sectionId: activeSectionKey })) as string[];
  const imgSrc = useAppSelector(getImgLinkForCategoryGroup(images));

  const chekDisabledOptionSink = (isSinkCabinet: string | undefined, idOptionCategory: string, valueAppliancesSink: string | undefined, activeSectionKey: string | null) => {

    if (isSinkCabinet === undefined)
      return false;

    if (!!activeSectionKey && activeSectionKey === "Appliances") {
      const sinkCabinetIdUI = isSinkCabinet;

      if (sinkCabinetIdUI === "1011")
        return true;

      if(idOptionCategory !== valueAppliancesSink)
        return false
        
    }
    
    return true;
  }

  const isSinkCategoryGroup = parentId.includes("Sink");
  let isDisabledOptionSink: boolean = false;
  if (isSinkCategoryGroup) {
    const sinkValueAppliances = !!valueAppliances && valueAppliances.find((value) => value.includes("sink"));
    isDisabledOptionSink = chekDisabledOptionSink(isSinkCabinet, id, sinkValueAppliances, activeSectionKey);
  }

  return (
    <div className={`${s.optionCategory} ${isDisabledOptionSink ? s.disabled : ""}`} onClick={() => onClick(value)}>
      <div className={s.optionCategoryImg}>
        <div className={s.imgWrap}>
          <img src={imgSrc.length < 1 ? NotImage : imgSrc} alt={label} />
        </div>
      </div>
      <p className={s.optionCategoryLabel}>{label}</p>
    </div>
  );
};
