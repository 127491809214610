export const enum ATTRIBUTES_NAMES_THREEKIT {
  WALLS = "Walls",
  WINDOWS = "Windows",
  DOORS = "Doors",
  OPENINGS = "Openings",

  HEIGHT = "Height",
  CABINETS_BASE = "Cabinets Base",
  CABINETS_WALL = "Cabinets Wall",
  CABINETS_ISLAND = "Cabinets Island",
  APPLIANCES = "Appliances",
  CABINET_MATERIAL = "Cabinet material",
  DOOR_STYLE = "Door Style",
  WALL_THICKNESS = "Wall Thickness",

  // Support Attributes
  PHYSICS_CABINETS_BASE = "OnOffPhysicsCabinetsBase",
  PHYSICS_CABINETS_WALL = "OnOffPhysicsCabinetsWall",
}
