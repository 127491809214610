import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { KeysModalsT, MODAL_KEYS, ModalsInfoT } from './modalsSliceT'

const initialStateModals = {
  isVisible: false, 
}

const initialState: ModalsInfoT = {
  [MODAL_KEYS.DECORATIVE_END]: initialStateModals,
  [MODAL_KEYS.HELP_2D]: initialStateModals,
  [MODAL_KEYS.HELP_3D]: initialStateModals,
  [MODAL_KEYS.FRAMED_TYPE]: initialStateModals,
  [MODAL_KEYS.PRICE]: initialStateModals,
  [MODAL_KEYS.CONTINUE_2D]: initialStateModals,
  [MODAL_KEYS.EMAIL_SUCCESS]: initialStateModals,
  [MODAL_KEYS.EMAIL_ERROR]: initialStateModals,
  [MODAL_KEYS.DELETE_ALL_CABINETS]: initialStateModals,
  [MODAL_KEYS.LOADER]: initialStateModals,
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setModalVisible: (state, action: PayloadAction<{modalId: KeysModalsT, value: boolean, isFirstShow?: boolean}>) => {
      const { modalId, value, isFirstShow } = action.payload;
      let isVisible: boolean = value;

      if (isFirstShow !== false && (modalId === MODAL_KEYS.FRAMED_TYPE || modalId === MODAL_KEYS.HELP_2D || modalId === MODAL_KEYS.HELP_3D)) {
        if (!value) {
          state[modalId]["isFirstShow"] = true;
        }
        if (value && !!state[modalId]["isFirstShow"]) {
          isVisible = false;
        }
      }

      state[modalId]["isVisible"] = isVisible;
    },
    closeAllModals: (state) => {
      const allModalsKeys = Object.keys(state) as KeysModalsT[];
      allModalsKeys.forEach((keyModal: KeysModalsT) => {
        state[keyModal]["isVisible"] = false;
      });
    },
  },
})

export const { setModalVisible, closeAllModals } = modalsSlice.actions;

export default modalsSlice.reducer;