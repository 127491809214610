import { SavedConfiguration } from "../../api/apiThreekit/savedConfiguration";

const PUBLIC_TOKEN = process.env.REACT_APP_THREEKIT_PREVIEW_PUBLIC_TOKEN;
const ORG_ID = process.env.REACT_APP_THREEKIT_PREVIEW_ORG_ID;
const THREEKIT_URL = process.env.REACT_APP_THREEKIT_URL;

export const getShortId = async ({
  objActiveParamsUI,
  transformAllCabinets,
}: any) => {
  if (!!!PUBLIC_TOKEN || !!!ORG_ID || !!!THREEKIT_URL) return;
  let configThreekit = {
    authToken: PUBLIC_TOKEN,
    orgId: ORG_ID,
    threekitUrl: THREEKIT_URL,
    metadata: {
      objectActiveParams: objActiveParamsUI,
      transformAllCabinets,
      // allError: allError,
      // objectsPosition: objectsPosition,
      // positionObjectSide: await getObjectLocations()
    },
  };

  return (
    Promise.all([SavedConfiguration(configThreekit)])
      .then((threekitConfig: any) => {
        const shortId = threekitConfig[0]["shortId"];
        return Promise.all([shortId]);
      })
      // .then((threekitConfig: any) => {
      //   const linkText = `${window.location.origin}${pathname}?shortId=${threekitConfig[0]["shortId"]}`;

      //   return Promise.all([linkText]);
      // })
      .catch((error) => {
        console.log("error --- ==== ", error);
      })
  );
};