import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import { NAME_WALL_CABINET_CORNER_STUB, NODES_THREEKIT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel, getValueObjAttributeThreekit, getWorldTransformEvalNode } from "../../utils/threekit/general/getFunctions";
import { getConfigurationForCabinetMaterial, setConfigurationFull } from "../../functionsConfigurator/cabinets/configuration/stylingCabinets";
import { getWallDirection, getWallDirectionLeft } from "../../utils/threekit/tools/toolsDragCabinetsBase/generalFunc";
import {
  getIntersectCornerCoordsForPlanes,
  isEqualCoordsTolerance,
} from "./addCornerModelBase";
import { getObjAttributeThreekitValues } from "./getObjActiveAndNewValuesAttributeThreekit";
import { getСompletedModelsNullNames } from "./getNodesCabinets";
import { CABINETS_WALL_BOTTOM_POSITION, CORNER_EMPTY_WALL_SIZE_X } from "./constatns";
import { checkIfCornerCabinet } from "./cabinetsWall/extremePoints";
import { moveCoordsByVector } from "../wallsAndFloor/buildWallFromData";
import {
  setRotationThreekit,
  setTranslationThreekit,
} from "../../utils/threekit/general/setFunctions";
import { getExtremePointsForCabinet } from "./getExtremeModels";
import { checkCabinetsWallConnectors } from "./cabinetsWall/checkCabinetsWallConnectors";
import store from "../../store/store";
import { getValueOptionColorCabinetsWall } from "../../store/selectors/settingsUISelectors";

/**
 * Визначає чи встановлена в даній кутовій точці якась модель.
 *
 * @param {ICoordinates} cornerCoords Координати кутової точки.
 * @return {Boolean} true/false - відповідно зайнята/вільна кутова точка.
 */
export const checkModelWallInCornerPoint = (
  cornerCoords: ICoordinates
): boolean => {

  let result = false;
  const completedModelsNullNames = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );

  completedModelsNullNames.forEach((modelNullName) => {

    const modelItem = getItemNodeFromNullModel({ name: modelNullName });
    if (checkIfCornerCabinet(modelItem)) {
      const objExtremePointsForCabinet = getExtremePointsForCabinet(modelNullName);
      const isEqualCoordsExtremeRightAndCornerCoords = isEqualCoordsTolerance(
        { ...cornerCoords, y: 0 },
        { ...objExtremePointsForCabinet["rightExtremePoint"], y: 0},
        0.05
      )
      if (isEqualCoordsExtremeRightAndCornerCoords) {
        result = true;
      }
    };


    // const modelNullNameTranslation = getTranslationThreekit({
    //   name: modelNullName,
    // });
    // const modelSize = getSizeModelRelativeTransform(modelNullName);
    // if (
    //   isEqualCoordsTolerance(
    //     { ...cornerCoords, y: 0 },
    //     { ...modelNullNameTranslation, y: 0 },
    //     0.05 // modelSize["x"] + modelSize["x"]/4
    //   )
    // ) {
    //   // const modelItem = getItemNodeFromNullModel({ name: modelNullName });
    //   // if (modelItem["name"].includes("Corner")) {
    //     result = true;
    //   // }
    // }
    
  });

  return result;

};

export type ArrNamesCornerWallsT = WallItemT[];
/**
 * Додає кутову настінну модель-заглушку в кут між стінами ["wall_item_...", "wall_item_..."].
 *
 * @param {ArrNamesCornerWallsT} arrNamesCornerWallsSorted Масив з імен для двух стін, які формують кут.
 * Нічого не повертає. Додає кутову модель-заглушку в кімнату.
 */
export const addCornerModelWall = async (
  arrNamesCornerWallsSorted: ArrNamesCornerWallsT
) => {

  const state = store.getState();
  const colorCabinetsWall = getValueOptionColorCabinetsWall(state);

  // const cornerCoords = getInternalCornerCoordsForWalls(
  //   arrNamesCornerWallsSorted
  // );
  const cornerCoords = getIntersectCornerCoordsForPlanes(
    arrNamesCornerWallsSorted
  );

  // const isBusyCornerPoint = checkModelInCornerPoint(cornerCoords);
  // if (isBusyCornerPoint) return;

  const valueCornerModelFromAttribute = getValueObjAttributeThreekit(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL,
    NAME_WALL_CABINET_CORNER_STUB
  );
  const { values, value } = getObjAttributeThreekitValues(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL
  );

  await setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL, [
    ...value,
    {
      assetId: valueCornerModelFromAttribute["assetId"],
      configuration: {
        ...getConfigurationForCabinetMaterial(colorCabinetsWall)
      }
    },
  ]).then(() => {

    const nameNullModelCornerEmpty = `${NODES_THREEKIT.MODEL_CABINET_WALL}${value.length}`;
    const worldTransformWall = getWorldTransformEvalNode(
      arrNamesCornerWallsSorted[0]
    );
    const wallDir = getWallDirection(worldTransformWall);
    const wallAngle = (Math.atan2(wallDir.x, wallDir.z) * 180) / Math.PI;
    const wallDirLeft = getWallDirectionLeft(worldTransformWall);

    let modelCornerEmptyPosition = moveCoordsByVector(
      cornerCoords,
      wallDirLeft,
      CORNER_EMPTY_WALL_SIZE_X / 2
    );
  
    setTranslationThreekit({
      name: nameNullModelCornerEmpty,
      value: { ...modelCornerEmptyPosition, y: CABINETS_WALL_BOTTOM_POSITION },
    });
    setRotationThreekit({
      name: nameNullModelCornerEmpty,
      //@ts-ignore
      value: { x: 0, y: wallAngle, z: 0 },
    });

    //@ts-ignore
    checkCabinetsWallConnectors(window.threekit.player.selectionSet.ids[0]);

  })
  
};