import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";
import {
  NAME_BASE_CABINET_CORNER_STUB,
  NODES_THREEKIT,
  PlaneCabinetsWallT,
  WallItemT,
} from "../../utils/constants/nodesNamesThreekit";
import {
  getItemNodeFromNullModel,
  getTranslationThreekit,
  getValueObjAttributeThreekit,
  getWorldTransformEvalNode,
} from "../../utils/threekit/general/getFunctions";
import { setConfigurationFull } from "../../functionsConfigurator/cabinets/configuration/stylingCabinets";
import { getStartEndPointWall } from "../wallsAndFloor/getStartEndPointWall";
import { getObjAttributeThreekitValues } from "./getObjActiveAndNewValuesAttributeThreekit";
import { getСompletedModelsNullNames } from "./getNodesCabinets";
import {
  getWallDirection,
  getWallDirectionLeft,
  getWallDirectionRight,
} from "../../utils/threekit/tools/toolsDragCabinetsBase/generalFunc";
import {
  checkIfBlindCornerCabinet,
  checkIfCornerEmptyCabinetBase,
  checkIfLazySuzanCornerCabinet,
  getExtremePointsForCabinet,
} from "./getExtremeModels";
import { moveCoordsByVector } from "../wallsAndFloor/buildWallFromData";
import { CORNER_EMPTY_BASE_SIZE_X } from "./constatns";
import {
  setRotationThreekit,
  setTranslationThreekit,
} from "../../utils/threekit/general/setFunctions";
import { getNumberNodeThreekitFromName } from "../general";
import { getStartEndCoordsPlane } from "../wallsAndFloor/getWallPlanesInfo";
import { checkCabinetsBaseConnectors } from "./cabinetsBase/checkCabinetsBaseConnectors";
import { getModelPositionFromName } from "../../utils/threekit/tools/toolsDragCabinetsIsland/generalFunc";
import { getSizeModelBoxFromAssetCabinetBase } from "./cabinetsBase/size";
import * as THREE from "three";

export const isEqualCoordsTolerance = (
  point1: ICoordinates,
  point2: ICoordinates,
  tolerance: number
) => {
  // point1 і point2 повинні бути об'єктами з координатами x, y та z
  var dx = point1.x - point2.x;
  var dy = point1.y - point2.y;
  var dz = point1.z - point2.z;

  // Обчислюємо відстань між двома точками за допомогою теореми Піфагора
  var distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
  // console.log('distance --- ==== ',distance);

  // Порівнюємо відстань з допустимою похибкою tolerance
  return distance <= tolerance;
};

/**
 * Повертає координати точки перетину двух відрізків.
 *
 * @param {ICoordinates} pointLine1A Координати початку першої лінії.
 * @return {[ICoordinates, ICoordinates]} Координати початку і кінця для стіни.
 */
export const getIntersectPointSegments = (
  pointLine1A: ICoordinates,
  pointLine1B: ICoordinates,
  pointLine2A: ICoordinates,
  pointLine2B: ICoordinates
): ICoordinates => {
  const x1 = pointLine1A["x"];
  const x2 = pointLine1B["x"];
  const z1 = pointLine1A["z"];
  const z2 = pointLine1B["z"];

  const x3 = pointLine2A["x"];
  const x4 = pointLine2B["x"];
  const z3 = pointLine2A["z"];
  const z4 = pointLine2B["z"];

  // перевіряємо чи довжина однієї з ліній не дорівнює 0
  if ((x1 === x2 && z1 === z2) || (x3 === x4 && z3 === z4)) {
    // return false
  }

  const denominator = (z4 - z3) * (x2 - x1) - (x4 - x3) * (z2 - z1);

  // перевіряємо чи лінії не є паралельними
  if (denominator === 0) {
    // return false
  }

  let ua = ((x4 - x3) * (z1 - z3) - (z4 - z3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (z1 - z3) - (z2 - z1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    // return false
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let z = z1 + ua * (z2 - z1);

  return { x, y: pointLine1A["y"], z };
};

export const getInternalCornerCoordsForWalls = (
  arrNamesCornerWalls: ArrNamesCornerWallsT
): ICoordinates => {
  const [wallFirsCoordsLeft, wallFirstCoordsRight] = getStartEndPointWall(
    arrNamesCornerWalls[0]
  );
  const [wallLastCoordsLeft, wallLastCoordsRight] = getStartEndPointWall(
    arrNamesCornerWalls[1]
  );
  const intersectPointWalls = getIntersectPointSegments(
    wallFirsCoordsLeft,
    wallFirstCoordsRight,
    wallLastCoordsLeft,
    wallLastCoordsRight
  );
  // console.log("intersectPointWalls --- ==== ", intersectPointWalls);
  // const wallDir0 = getWallDir(arrNamesCornerWalls[0]);
  // const wallDir1 = getWallDir(arrNamesCornerWalls[1]);

  // const wallSize0 = getSizeModelRelativeTransform(arrNamesCornerWalls[0]);
  // const wallSize1 = getSizeModelRelativeTransform(arrNamesCornerWalls[1]);

  // let cornerCoords = intersectPointWalls;
  // cornerCoords = moveCoordsByVector(
  //   cornerCoords,
  //   wallDir0,
  //   wallSize0["z"]/2
  // );
  // cornerCoords = moveCoordsByVector(
  //   cornerCoords,
  //   wallDir1,
  //   wallSize1["z"]/2
  // );

  return intersectPointWalls;
};

export const getIntersectCornerCoordsForPlanes = (
  arrNamesCornerWalls: ArrNamesCornerWallsT
): ICoordinates => {

  const wallFirstNum = getNumberNodeThreekitFromName(arrNamesCornerWalls[0])
  const wallLastNum = getNumberNodeThreekitFromName(arrNamesCornerWalls[1])
  const planeFirstName = `${NODES_THREEKIT.PLANE_CABINETS_WALL}${wallFirstNum}` as PlaneCabinetsWallT
  const planeLastName = `${NODES_THREEKIT.PLANE_CABINETS_WALL}${wallLastNum}` as PlaneCabinetsWallT
  const [planeFirsCoordsLeft, planeFirstCoordsRight] = getStartEndCoordsPlane(
    planeFirstName
  );
  const [planeLastCoordsLeft, planeLastCoordsRight] = getStartEndCoordsPlane(
    planeLastName
  );
  const intersectPointPlanes = getIntersectPointSegments(
    planeFirsCoordsLeft,
    planeFirstCoordsRight,
    planeLastCoordsLeft,
    planeLastCoordsRight
  );

  return intersectPointPlanes;
};

/**
 * Визначає чи встановлена в даній кутовій точці якась модель.
 *
 * @param {ICoordinates} cornerCoords Координати кутової точки.
 * @return {Boolean} true/false - відповідно зайнята/вільна кутова точка.
 */
export const checkModelBaseInCornerPoint = (
  cornerCoords: ICoordinates
): boolean => {

  let result = false;
  const completedModelsNullNames = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );
  completedModelsNullNames.forEach((modelNullName) => {
    const modelNullNameTranslation = getTranslationThreekit({
      name: modelNullName,
    });

    const modelItem = getItemNodeFromNullModel({ name: modelNullName });

    if (checkIfLazySuzanCornerCabinet(modelItem) || checkIfCornerEmptyCabinetBase(modelItem)) {
      const modelPos = getModelPositionFromName(modelNullName);
      const modelSize = getSizeModelBoxFromAssetCabinetBase(modelNullName)
      const worldTransformModel = getWorldTransformEvalNode(modelNullName);
      const modelDirRight = new THREE.Vector3(1, 0, 0).transformDirection(
        worldTransformModel
      );
      const cornerPointCornerCabinet = moveCoordsByVector(
        modelPos,
        modelDirRight,
        modelSize["x"] / 2
      );
      const isEqualCoordsCornerCabinetAndCornerCoords = isEqualCoordsTolerance(
        { ...cornerCoords, y: 0 },
        cornerPointCornerCabinet,
        0.05
      );
      if (isEqualCoordsCornerCabinetAndCornerCoords) {
        result = true;
      }

      // const objExtremePointsForCabinet = getExtremePointsForCabinet(modelNullName);
      // const isEqualCoordsExtremeLeftAndCornerCoords = isEqualCoordsTolerance(
      //   { ...cornerCoords, y: 0 },
      //   objExtremePointsForCabinet["rightExtremePoint"],
      //   0.05
      // )
      // if (isEqualCoordsExtremeLeftAndCornerCoords) {
      //   result = true;
      // }
    }

    if (checkIfBlindCornerCabinet(modelItem)) {
      const objExtremePointsForCabinet = getExtremePointsForCabinet(modelNullName);
      const isEqualCoordsExtremeLeftAndCornerCoords = isEqualCoordsTolerance(
        { ...cornerCoords, y: 0 },
        objExtremePointsForCabinet["leftExtremePoint"],
        0.1
      )
      const isEqualCoordsExtremeRightAndCornerCoords = isEqualCoordsTolerance(
        { ...cornerCoords, y: 0 },
        objExtremePointsForCabinet["rightExtremePoint"],
        0.1
      )
      if (isEqualCoordsExtremeLeftAndCornerCoords || isEqualCoordsExtremeRightAndCornerCoords) {
        result = true;
      }
    }

    // if (
    //   isEqualCoordsTolerance(
    //     { ...cornerCoords, y: 0 },
    //     modelNullNameTranslation,
    //     0.05
    //   )
    // )
    //   result = true;
  });
  return result;
};

export type ArrNamesCornerWallsT = WallItemT[];
/**
 * Додає кутову модель-заглушку в кут між стінами ["wall_item_...", "wall_item_..."].
 *
 * @param {ArrNamesCornerWallsT} arrNamesCornerWallsSorted Масив з імен для двух стін, які формують кут.
 * Нічого не повертає. Додає кутову модель-заглушку в кімнату.
 */
export const addCornerModelBase = (
  arrNamesCornerWallsSorted: ArrNamesCornerWallsT
) => {

  // const cornerCoords = getInternalCornerCoordsForWalls(
  //   arrNamesCornerWallsSorted
  // );
  const cornerCoords = getIntersectCornerCoordsForPlanes(
    arrNamesCornerWallsSorted
  );

  // const isBusyCornerPoint = checkModelInCornerPoint(cornerCoords);
  // if (isBusyCornerPoint) return;

  const valueCornerModelFromAttribute = getValueObjAttributeThreekit(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE,
    NAME_BASE_CABINET_CORNER_STUB
  );
  const { values, value } = getObjAttributeThreekitValues(
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE
  );

  setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE, [
    ...value,
    { assetId: valueCornerModelFromAttribute["assetId"] },
  ]).then(() => {
    //@ts-ignore
    checkCabinetsBaseConnectors(window.threekit.player.selectionSet.ids[0])
  });

  const nameNullModelCornerEmpty = `${NODES_THREEKIT.MODEL_CABINET_BASE}${value.length}`;
  const worldTransformWall = getWorldTransformEvalNode(
    arrNamesCornerWallsSorted[0]
  );
  const wallDir = getWallDirection(worldTransformWall);
  const wallAngle = (Math.atan2(wallDir.x, wallDir.z) * 180) / Math.PI;
  const wallDirLeft = getWallDirectionLeft(worldTransformWall);

  let modelCornerEmptyPosition = moveCoordsByVector(
    cornerCoords,
    wallDirLeft,
    CORNER_EMPTY_BASE_SIZE_X / 2
  );

  setTranslationThreekit({
    name: nameNullModelCornerEmpty,
    value: { ...modelCornerEmptyPosition, y: 0 },
  });

  setRotationThreekit({
    name: nameNullModelCornerEmpty,
    value: { x: 0, y: wallAngle, z: 0 },
  });
  
};
