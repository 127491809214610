import React, { useEffect, useRef, useState } from "react";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalPrice.module.scss";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { CloseModalSvg } from "../../../assets/images/svg/CloseModal";
import { RadioOptions } from "../../StandartComponents/RadioOptions/RadioOptions";
import { DistributorsOptions } from "../../../utils/constants/distributors";
import useMobileWidth from "../../../hooks/useMobileWidth";
import {
  getTotalPrice,
} from "../../../store/selectors/summarySelectors";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import {
  SnapshotsResT,
  getCornerPosition,
  getSnapshotsCabinetsConfiguration,
  getWallForCamera,
  rotateCamera,
} from "../../../utils/threekit/tools/camera/snapshotCameras";
import { getRotationThreekit } from "../../../utils/threekit/general/getFunctions";
import { setRotationThreekit } from "../../../utils/threekit/general/setFunctions";
import { FormModalPrice } from "./FormModalPrice/FormModalPrice";
import { getIdGeneratedProject } from "../../../api/getIdGeneratedProject";
import { Header } from "./ProductsDesignPDF/Header/Header";
import { Collection } from "./ProductsDesignPDF/Collection/Collection";
import { Design } from "./ProductsDesignPDF/Design/Design";
import { Products } from "./ProductsDesignPDF/Products/Products";
import { ProductsList } from "./ProductsList/ProductsList";
import { FinishingItems } from "./FinishingItems/FinishingItems";
import { setCabinetControls } from "../../../store/slices/settingsUI/settingsUISlice";

export type SymmaryTabsT = {
  id: string;
  label: string;
};
const SummaryTabsList: SymmaryTabsT[] = [
  {
    id: "Cabinets",
    label: "Cabinets",
  },
  {
    id: "FinishingItems",
    label: "Finishing Items",
  },
];

export const ModalPrice = ({ ...props }) => {
  const modalInfo = useAppSelector((state) =>
    getModalInfo(state)(MODAL_KEYS.PRICE)
  );
  const dispatch = useAppDispatch();
  const isMobSize = useMobileWidth(991);
  const [activeMobTab, setActiveMobTab] = useState<string>("Visible");
  const [isShowQuoteMob, setIsShowQuoteMob] = useState<boolean>(false);
  const totalPrice = useAppSelector(getTotalPrice);
  const [projectName, setProjectName] = useState('');

  const handleProjectNameChange = (event: any) => {
    setProjectName(event.target.value);
  };

  const handleCloseModal = () => {
    dispatch(setModalVisible({ modalId: MODAL_KEYS.PRICE, value: false }));
  };

  useEffect(() => {
    setActiveMobTab(isMobSize ? SummaryTabsList[0]["id"] : "Visible");
    setIsShowQuoteMob(isMobSize ? false : true);
  }, [isMobSize]);

  useEffect(() => {
    if (modalInfo["isVisible"]) {

      const cornerPosition = getCornerPosition();
      if (cornerPosition) {
        rotateCamera(cornerPosition);
      }

      const wallName = getWallForCamera();
      let rotationWall = getRotationThreekit({ name: wallName });

      setRotationThreekit({
        name: "Snapshot Side Camera",
        value: { ...rotationWall },
      });
    }
  }, [modalInfo["isVisible"]]);

  const [drawingId, setDrawingId] = useState<string>('');
  useEffect(() => {
    getIdGeneratedProject().then((res) => {
      setDrawingId(res["idPdf"])
    })
  }, []);

  const [snapshotsObj, setSnapshots] = useState<SnapshotsResT>(null);
  useEffect(() => {
    //@ts-ignore
    window.threekit.player.selectionSet.clear()
    dispatch(setCabinetControls({
      visible: false,
      selectedModelName: '',
    }));
    getSnapshotsCabinetsConfiguration().then((res) => {
      if (res !== null) {
        setSnapshots({
          snapshotSide: res["snapshotSide"],
          snapshotTop: res["snapshotTop"],
        })
      }
    })
  }, []);

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.PRICE} >
      <div className={s.modalPrice}>
        <div className={s.content}>
          <div className={s.close} onClick={handleCloseModal}>
            <CloseModalSvg />
          </div>
          <div className={s.contentProducts}>

            <div className={s.scrollTableBlock}>

              <Header />

              <Collection drawingId={drawingId} projectName={projectName} />

              <Design snapshotsObj={snapshotsObj} />

              {!isMobSize ? <Products /> : <><ProductsList /><FinishingItems /></>}
              {/* <Products /> */}

            </div>

            {/* <h3 className={s.title}>Shopping List</h3> */}

            {/* <ul className={s.summaryProductsTabs}>
              {SummaryTabsList.map((tab: SymmaryTabsT) => (
                <li
                  key={tab["id"]}
                  className={`${s.tabLink} ${
                    activeMobTab === tab["id"] && s.active
                  }`}
                  onClick={() => setActiveMobTab(tab["id"])}
                >
                  {tab["label"]}
                </li>
              ))}
            </ul> */}

            {/* <div className={s.scrollBlock}>
              {["Cabinets", "Visible"].includes(activeMobTab) && (
                <ProductsList />
              )}

              {["FinishingItems", "Visible"].includes(activeMobTab) && (
                <FinishingItems />
              )}
            </div> */}

            <div className={s.mobSummPrice}>
              <div className={s.totalSumm}>
                MSRP*: {!isNaN(totalPrice) ? `$ ${totalPrice}` : "TBD"}
              </div>
              <div className={s.btnWrap}>
                <ButtonMain
                  text="Get Quote"
                  onClick={() => setIsShowQuoteMob(true)}
                  color="blueDark"
                  size="middle"
                  bold={true}
                  fullWidth={true}
                />
              </div>
              <div className={s.totalDescr}>
                <p className={s.totalDescrTitle}>Statement of Conditions</p>
                The MSRP is a Manufacturer Suggested Retail Price. Retailers are
                not bound to sell any component at MSRP and may adjust their
                Retail Price due to market conditions, which may be higher or
                lower than MSRP.
              </div>
            </div>
          </div>
          {isShowQuoteMob && (
            <div className={s.contentQuote}>
              <div
                className={s.closeQuoteMob}
                onClick={() => setIsShowQuoteMob(false)}
              >
                <CloseModalSvg />
              </div>

              <h3 className={s.title}>Get Quote</h3>
              <div className={s.scrolledContentMob}>
                <div className={s.wrapperTopContent}>
                  <RadioOptions
                    id="Distributors"
                    label="Distributor"
                    values={DistributorsOptions}
                  />
                  <div className={s.totalSumm}>
                    MSRP*: {!isNaN(totalPrice) ? `$ ${totalPrice}` : "TBD"}
                  </div>
                  <div className={s.totalDescr}>
                    <p className={s.totalDescrTitle}>Statement of Conditions</p>
                    The MSRP is a Manufacturer Suggested Retail Price. Retailers
                    are not bound to sell any component at MSRP and may adjust
                    their Retail Price due to market conditions, which may be
                    higher or lower than MSRP.
                  </div>

                  <FormModalPrice
                    drawingId={drawingId}
                    snapshotsObj={snapshotsObj}
                    onChangeProjectName={handleProjectNameChange}
                  />
                </div>

                <div className={s.disclaimer}>
                  <h4>Disclaimer</h4>
                  <p>
                    By accessing and using our Threekit configurator
                    ("Program"), you agree and acknowledge that: (i) your use of
                    the Program shall be bound by the <a href="https://www.whalenfurniture.com/terms-of-use/" target="_blank">Terms of Use
                    (https://www.whalenfurniture.com/terms-of-use/)</a> and <a href="https://www.whalenfurniture.com/privacy-policy/" target="_blank">Privacy
                    Policy (https://www.whalenfurniture.com/privacy-policy/)</a>
                    stipulated in our Website; (ii) Whalen is not a retailer for
                    the product shown on our Program and those products can be
                    purchased through the retailers stated therein; and (iii)
                    the quoted prices for the products you choose through our
                    Program are for estimates purposes only and all/each
                    seller(s) or retailor(s) of such products may charge
                    different price(s) for such products.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalsWrap>
  );
};
