import React from "react";
import { IComponent } from "../../types/UI/dynamic-rendering.interfaces";
import shortid from "shortid";
import { SettingsBlock } from "../SettingsBlock/SettingsBlock";
import { Tabs } from "../StandartComponents/Tabs/Tabs";
import { FiltersGroup } from "../FiltersGroup/FiltersGroup";
import { SelectStandart } from "../StandartComponents/SelectStandart/SelectStandart";
import { CategoriesGroup } from "../CategoriesGroup/CategoriesGroup";
import {
  CategoryGroup,
  MemoizedCategoryGroup,
} from "../StandartComponents/CategoryGroup/CategoryGroup";
import { SettingsProduts } from "../SettingsProduts/SettingsProduts";
import { OptionsGroup } from "../StandartComponents/OptionsGroup/OptionsGroup";
import { SwitchStandart } from "../StandartComponents/SwitchStandart/SwitchStandart";
import { SelectColor } from "../StandartComponents/SelectColor/SelectColor";
import { CabinetsBuildGroup } from "../CabinetsBuildGroup/CabinetsBuildGroup";
import { RadioOptions } from "../StandartComponents/RadioOptions/RadioOptions";
import { SwitchSpaceBetween } from "../StandartComponents/SwitchSpaceBetween/SwitchSpaceBetween";
import { SubMenu2DWrap } from "../SubMenu2DWrap/SubMenu2DWrap";
import { SubMenu2DHelp } from "../SubMenu2DHelp/SubMenu2DHelp";
import { ImgOptions } from "../StandartComponents/ImgOptions/ImgOptions";
import { ProductsGroup } from "../ProductsGroup/ProductsGroup";
import { RadioOptionsIconText } from "../StandartComponents/RadioOptionsIconText/RadioOptionsIconText";

type DistributeComponentsT = IComponent & {
  parentKeySection?: unknown;
  index?: number;
  debounceCustomerInfo?: any;
  onCloseMenu2D?: () => void;
};

export const DistributeComponents: React.FC<DistributeComponentsT> = ({
  ...props
}) => {
  const { parentKeySection, type, data, onCloseMenu2D } = props;

  let params: any = { ...data };

  let isVisibilityState = true;

  const keyComponent = `${data["id"]}${shortid.generate()}`;

  switch (type) {
    case "SettingsBlock": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return (
        <SettingsBlock {...params}>
          {embeddedView.length > 0 &&
            embeddedView.map((setting: IComponent) => {
              return (
                <DistributeComponents
                  parentKeySection={parentKeySection}
                  key={setting["data"]["id"]}
                  {...setting}
                />
              );
            })}
        </SettingsBlock>
      );
    }

    case "SettingsProduts": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return (
        <SettingsProduts {...params}>
          {embeddedView.length > 0 &&
            embeddedView.map((setting: IComponent) => {
              return (
                <DistributeComponents
                  parentKeySection={parentKeySection}
                  key={setting["data"]["id"]}
                  {...setting}
                />
              );
            })}
        </SettingsProduts>
      );
    }

    case "Tabs": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return <Tabs {...params} embeddedView={embeddedView}></Tabs>;
    }

    case "FiltersGroup": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return (
        <FiltersGroup {...params}>
          {embeddedView.length > 0 &&
            embeddedView.map((setting: IComponent) => {
              return (
                <DistributeComponents
                  parentKeySection={parentKeySection}
                  key={setting["data"]["id"]}
                  {...setting}
                />
              );
            })}
        </FiltersGroup>
      );
    }

    case "SelectStandart": {
      return <SelectStandart parentKeySection={parentKeySection} {...params} />;
    }

    case "CategoriesGroup": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return (
        <CategoriesGroup {...params}>
          {embeddedView.length > 0 &&
            embeddedView.map((setting: IComponent) => {
              return (
                <DistributeComponents
                  parentKeySection={params["id"]}
                  key={setting["data"]["id"]}
                  {...setting}
                />
              );
            })}
        </CategoriesGroup>
      );
    }

    case "CategoryGroup": {
      return (
        <MemoizedCategoryGroup
          parentKeySection={parentKeySection}
          {...params}
        ></MemoizedCategoryGroup>
      );
    }

    case "OptionsGroup": {
      return (
        <OptionsGroup
          parentKeySection={parentKeySection}
          {...params}
        ></OptionsGroup>
      );
    }

    case "SwitchStandart": {
      return (
        <SwitchStandart
          parentKeySection={parentKeySection}
          {...params}
        ></SwitchStandart>
      );
    }

    case "SelectColor": {
      return (
        <SelectColor
          parentKeySection={parentKeySection}
          {...params}
        ></SelectColor>
      );
    }

    case "CabinetsBuildGroup": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return (
        <CabinetsBuildGroup {...params}>
          {embeddedView.length > 0 &&
            embeddedView.map((setting: IComponent) => {
              return (
                <DistributeComponents
                  parentKeySection={parentKeySection}
                  key={setting["data"]["id"]}
                  {...setting}
                />
              );
            })}
        </CabinetsBuildGroup>
      );
    }

    case "RadioOptions": {
      return (
        <RadioOptions
          parentKeySection={parentKeySection}
          {...params}
        ></RadioOptions>
      );
    }

    case "SwitchSpaceBetween": {
      return (
        <SwitchSpaceBetween
          parentKeySection={parentKeySection}
          {...params}
        ></SwitchSpaceBetween>
      );
    }

    case "SubMenu2DWrap": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return (
        <SubMenu2DWrap {...params}>
          {embeddedView.length > 0 &&
            embeddedView.map((setting: IComponent) => {
              return (
                <DistributeComponents
                  parentKeySection={parentKeySection}
                  key={setting["data"]["id"]}
                  {...setting}
                />
              );
            })}
        </SubMenu2DWrap>
      );
    }

    case "SubMenu2DHelp": {
      return (
        <SubMenu2DHelp
          parentKeySection={parentKeySection}
          onCloseMenu2D={() => !!onCloseMenu2D && onCloseMenu2D()}
          {...params}
        ></SubMenu2DHelp>
      );
    }

    case "SubMenu2DTemplate": {
      return (
        <SubMenu2DWrap
          onCloseMenu2D={() => !!onCloseMenu2D && onCloseMenu2D()}
          children={<ImgOptions />}
        />
      );
    }

    case "ProductsGroup": {
      const embeddedView: IComponent[] = params["embeddedView"];
      return (
        <ProductsGroup {...params}>
          {embeddedView.length > 0 &&
            embeddedView.map((setting: IComponent) => {
              return (
                <DistributeComponents
                  parentKeySection={parentKeySection}
                  key={setting["data"]["id"]}
                  {...setting}
                />
              );
            })}
        </ProductsGroup>
      );
    }

    case "RadioOptionsIconText": {
      return (
        <RadioOptionsIconText
          parentKeySection={parentKeySection}
          {...params}
        ></RadioOptionsIconText>
      );
    }

    case "hiddenProperty": {
      return <></>;
    }

    default:
      return <div>default </div>;
  }
};
