import { IntervalsWallsT } from "./wallsGeneral";

const arePointsEqual = (
  point1: THREE.Vector2,
  point2: THREE.Vector2
) => point1.x === point2.x && point1.y === point2.y;

export const isClosedContour = (segments: IntervalsWallsT): boolean => {
  const visited = new Set();
  let hasClosedContour = false;

  function dfs(currentSegment: any, prevSegment: any) {
      const currentKey = `${currentSegment.start.x}-${currentSegment.start.y}`;
      visited.add(currentKey);

      for (const nextSegment of segments) {
          if (nextSegment === prevSegment) {
              continue; // Пропускаємо попередній сегмент, щоб уникнути повернення
          }

          if (arePointsEqual(currentSegment.end, nextSegment.start)) {
              if (visited.has(`${nextSegment.start.x}-${nextSegment.start.y}`)) {
                  hasClosedContour = true;
              } else {
                  dfs(nextSegment, currentSegment);
              }
          }
      }
  }

  for (const segment of segments) {
      if (!visited.has(`${segment.start.x}-${segment.start.y}`)) {
          dfs(segment, null);
          if (hasClosedContour) {
              return true;
          }
      }
  }

  return false;

}














// function arePointsEqual(point1: THREE.Vector2, point2: THREE.Vector2) {
//   return point1.x === point2.x && point1.y === point2.y;
// }

// export function isClosedContour(segments: any) {
//   const n = segments.length;

//   if (n < 3) {
//       return false; // Контур не може бути замкнутим, якщо менше 3 відрізків
//   }

//   const firstSegment = segments[0];
//   const lastSegment = segments[n - 1];

//   if (!arePointsEqual(firstSegment.start, lastSegment.end)) {
//       return false; // Перша точка першого відрізка має співпадати з останньою точкою останнього відрізка
//   }

//   for (let i = 0; i < n - 1; i++) {
//       const currentSegment = segments[i];
//       const nextSegment = segments[i + 1];

//       if (!arePointsEqual(currentSegment.end, nextSegment.start)) {
//           return false; // Кінцева точка поточного відрізка має співпадати з початковою точкою наступного відрізка
//       }
//   }

//   return true; // Всі умови виконані, контур є замкнутим
// }
