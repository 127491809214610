import { ISceneResult } from "@threekit-tools/treble/dist/types";
import { getItemNodeFromNullModel, getValuesMetadataFromNode, getValuesMetadataItemFromNullModel } from "../../utils/threekit/general/getFunctions";
import { ModelsName_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { SelectedModelNameT } from "../../store/slices/settingsUI/typesSettingsUISlice";

export const getItemNumberFromMetadata = (node: ISceneResult) => {
  const objValuesMetadata = getValuesMetadataFromNode(node);
  if (!objValuesMetadata.hasOwnProperty("_itemNumber")) return undefined;
  return objValuesMetadata["_itemNumber"];
}

export const getItemNumberFromMetadataOrName = (node: ISceneResult) => {
  const objValuesMetadata = getValuesMetadataFromNode(node);
  if (!objValuesMetadata.hasOwnProperty("_itemNumber")) {
    return node["name"].split("_")[0];
  };
  return objValuesMetadata["_itemNumber"];
}

export const getItemNumberFromMetadataNullName = (nullName: ModelsName_NodesT | SelectedModelNameT): string => {

  const metadata = getValuesMetadataItemFromNullModel({
    name: nullName
  });

  const itemNode = getItemNodeFromNullModel({
    name: nullName
  })

  if (!metadata.hasOwnProperty("_itemNumber")) {
    return itemNode["name"].split("_")[0];
  };

  return metadata["_itemNumber"] as string;
}