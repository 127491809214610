// images
import Framed from "./../../assets/images/OtherParamsImages/Framed_v2.png"
import Framless from "./../../assets/images/OtherParamsImages/Framless_v2.png"

export enum FRAMED_OPTIONS_VALUES {
  FRAMED = "FD",
  FRAMELESS = "FL",
}
export type FramedOptionsValuesT = "FD" | "FL";
export interface RadioOptionI {
  id: number,
  value: string;
  label: string;
  img: string;
}

export const FramedOptions: RadioOptionI[] = [
  {
    id: Math.floor(100000 + Math.random() * 900000),
    value: FRAMED_OPTIONS_VALUES.FRAMED,
    label: "Framed",
    img: Framed,
  },
  {
    id: Math.floor(100000 + Math.random() * 900000),
    value: FRAMED_OPTIONS_VALUES.FRAMELESS,
    label: "Frameless",
    img: Framless,
  },
];