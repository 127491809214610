import s from "./ImgOptions.module.scss";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getActivePresetRoom, getMeasurement, getPresetsRoomsList } from "../../../store/selectors/settingsUISelectors";
import { setActivePreset, setInitialWalls } from "../../../store/slices/settingsUI/settingsUISlice";
import { buildWalls2DFromTemplate } from "../../../functionsConfigurator/roomBuilder/buildWallsFromTemplate";
import { useSpaces } from "@threekit-tools/treble/dist";
import { ArrWallTemplateT } from "../../../utils/constants/presetsRoom";

export const ImgOptions = () => {
  const measurementValue = useAppSelector(getMeasurement);
  const dispatch = useAppDispatch();
  const presetsRoomsList = useAppSelector(getPresetsRoomsList);
  const activePresetRoom = useAppSelector(getActivePresetRoom);
  const dataRoomBuilder = useSpaces({
    config: {
      unit: measurementValue,
      zoomDefault: 20,
      // translateDefault: [8, 8],
      zoomSensitivity: 0.8,
    },
    attributes: {
      walls: "Walls",
      windows: "Windows",
      doors: "Doors",
      openings: "Openings",
    },
    //@ts-ignore
    validators: [],
  });

  const handleClick = (templateArr: ArrWallTemplateT, templateId: string) => {
    dataRoomBuilder.reset();
    dispatch(setInitialWalls(templateArr));
    dispatch(setActivePreset(templateId));
    buildWalls2DFromTemplate(templateArr);
  };

  return (
    <div className={s.imgOptionsWrap}>
      <div className={s.imgOptionsList}>
        {presetsRoomsList.map((template, index) => {
          const isActive = template["id"] === activePresetRoom;
          return (
            <div key={template["id"]} className={`${s.imgOption} ${isActive ? s.active : ''}`}>
              <img
                src={template.icon}
                onClick={() => handleClick(template["data"], template["id"])}
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
